import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

const data = [
  { id: 0, value: 31.55, label: 'เมืองมุกดาหาร' },
  { id: 1, value: 12.35, label: 'นิคมคำสร้อย' },
  { id: 2, value: 15.08, label: 'ดอนตาล' },
  { id: 3, value: 15.05, label: 'ดงหลวง' },
  { id: 4, value: 11.82, label: 'คำชะอี' },
  { id: 5, value: 10.41, label: 'หว้านใหญ่' },
  { id: 6, value: 10.29, label: 'หนองสูง' },
];

const size = {
  width: 900,
  height: 300,
};

export default function PieChartErrorData() {
  return (
    <PieChart
      series={[
        {
          data,
          innerRadius: 30,
          outerRadius: 120,
          paddingAngle: 3,
          cornerRadius: 5,
          startAngle: 0,
          endAngle: 360,
          cx: 150,
          cy: 150,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
    },
  ]}
  {...size}
    />
  );
}
