import React, { useRef } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";

import { CloseIcn } from '../../../untils/icons';
import { ButtonTeal } from '../../../Theme';

const dataOption = [
    { option_claim: ``, label: "กรุณาเลือกรูปแบบ", display: true },
    { option_claim: 1, label: "รูปแบบที่ 1" },
    { option_claim: 2, label: "รูปแบบที่ 2" },
]

export default function DialogEdit({
    open,
    handleClose,
    handleUpdate,
    setName,
    name,
    setOption,
    option,
    locationId,
}) {

    const formRef = useRef();

    const checkValidation = (id) => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleUpdate(id);
        }
        // console.log(formRef.current.reportValidity());
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            className="bg-dialog"
        >
            <Box className='bg-gradiant'>
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">
                    แก้ไขข้อมูล
                </DialogTitle>
                <DialogContent className="flex-center" >
                    <form ref={formRef} className="flex-center" style={{ width: "100%" }}>
                        <DialogContentText mt={2} className="container-bg" component={'span'}>
                            <Grid container columns={12} spacing={2} >
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อสถานพยาบาล"
                                        name="name"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <FormControl fullWidth={true}>
                                        <InputLabel shrink={true}>เลือกรูปแบบ</InputLabel>
                                        <Select
                                            name="option"
                                            label="เลือกรูปแบบ"
                                            value={option}
                                            displayEmpty
                                            required
                                            onChange={(e) => setOption(e.target.value)}
                                        >
                                            {dataOption &&
                                                dataOption.map((el, i) => (
                                                    <MenuItem key={i + 1} value={el.option_claim} disabled={el.display}>
                                                        {el.label}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form >
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={() => {
                            checkValidation(locationId);
                        }}
                    >
                        แก้ไขข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
