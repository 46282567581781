import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import SettingsCard from "./components/SettingsCard.js";
import BackdropLoad from "../../components/Backdrop/BackdropLoad.js";
import { GET, GETOTP, OTPCONFIG, OTPSAVE, POST } from "../../service.js";
import Swal from "sweetalert2";

function SettingsOtp() {

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [hcode, setHcode] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getOtp = async () => {
        try {
            let res = await GET(GETOTP);
            if (res.success) {
                setHcode(res.result[0].hcode);
                setUsername(res.result[0].username);
                setPassword(res.result[0].password);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleVerify = async () => {

        setMessage("");

        try {
            setLoadingBtn(true);
            let res = await POST(OTPCONFIG, { username, password, hcode });
            if (res.success) {
                // console.log(res.result);
                setMessage(res.result.message);
                setStatus(res.result.status);
                setLoadingBtn(false);
            } else {
                setLoadingBtn(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoadingBtn(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let res = await POST(OTPSAVE, { username, password, hcode });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลเรียบร้อย`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        getOtp();
    }, []);

    return (
        <>
            <BackdropLoad loading={loading} />
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <SettingsCard
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownPassword={handleMouseDownPassword}
                    handleVerify={handleVerify}
                    handleSubmit={handleSubmit}
                    setUsername={setUsername}
                    setPassword={setPassword}
                    setHcode={setHcode}
                    username={username}
                    password={password}
                    hcode={hcode}
                    showPassword={showPassword}
                    loadingBtn={loadingBtn}
                    message={message}
                    status={status}
                />
            </Container>
        </>
    );
}

export default SettingsOtp;
