import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";

import {
    ClaimIcon,
    PersonIcon,
} from '../../../../untils/icons';

import GridTableDetail from './GridTableDetail';

import {
    Item,
} from '../../../../Theme';

import { tofieds } from '../../../../untils/shortcut';

function GridDetail(props) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} mt={1} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ClaimIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายละเอียดข้อมูล
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={5} className="flex-start">
                        <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <PersonIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">CID</Typography>
                                        <Typography color="var(--teal-A800)">{props.detail.cid}</Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <PersonIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">ชื่อ - นามสกุล</Typography>
                                        <Typography color="var(--teal-A800)">{props.detail.prefix} {props.detail.name} {props.detail.lastname}</Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <Typography fontWeight="bold" fontSize={24} color="var(--teal-A800)">฿</Typography>
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">ค่าบริการรวม</Typography>
                                        <Typography color="var(--teal-A800)">{tofieds(props.detail.service)}</Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={5}>
                        <GridTableDetail header={props.header} data={props.data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default GridDetail;