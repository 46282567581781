import React from 'react'
import moment from 'moment';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";

import {
    EditNoteDrawerIcon,
    CalendarIcon,
    LocationIcon,
    Confirm,
    Cancel,
} from '../../../untils/icons';

import GridTableDetail from './GridTableDetail';

import {
    Item,
    ButtonTeal,
    ButtonCancel,
} from '../../../Theme'

import {
    fixAmount,
} from '../../../untils/shortcut'
import { transactionStatus } from '../../../untils/static';

function GridDetail(props) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} mt={1} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายละเอียดการเบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    {props.detail.status === 1 &&
                        <Grid item xs={12} sm={12} md={12} className="flex-end" my={1}>
                            <Stack direction="row" spacing={2}>
                                <ButtonTeal onClick={props.submitApproveAlert}>
                                    <Confirm fontSize='small' />&nbsp;
                                    <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                        ยืนยันรายการ
                                    </Typography>
                                </ButtonTeal>
                                <ButtonCancel onClick={props.submitRejectAlert}>
                                    <Cancel fontSize='small' />&nbsp;
                                    <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                        ยกเลิกรายการ
                                    </Typography>
                                </ButtonCancel>
                            </Stack>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} className="flex-start" my={1}>
                        <Stack spacing={2}>
                            <Typography color="var(--teal-A800)" >
                                เลขคำสั่ง : {props.detail.order_code}
                            </Typography>
                            <Typography color="var(--teal-A800)" sx={{ display: { xs: 'none', sm: 'block' } }} >
                                รายการเวชภัณฑ์เบิกจ่าย (รวม {fixAmount(props.detail.amount ? props.detail.amount : 0)} รายการ)
                            </Typography>
                            <Typography color="var(--teal-A800)" sx={{ display: { xs: 'block', sm: 'none' } }}>
                                รายการเบิกจ่าย รวม {fixAmount(props.detail.amount ? props.detail.amount : 0)} รายการ
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={5} className="flex-start">
                        <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <CalendarIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">วันที่ทำรายการ</Typography>
                                        <Typography color="var(--teal-A800)">
                                            {moment(props.detail.date_created).format("DD/MM/YYYY HH:mm:ss")}
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <LocationIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">ปลายทาง</Typography>
                                        <Typography color="var(--teal-A800)">
                                            {props.detail.location_name}
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <CalendarIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">สถานะรายการ</Typography>
                                        <Typography color="var(--teal-A800)">
                                            {transactionStatus[props.detail.status].label}
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={5}>
                        <GridTableDetail header={props.header} data={props.data} setData={props.setData} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default GridDetail;

