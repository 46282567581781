import React, { useState, useEffect } from 'react';
import { POST, GETROLEBYID, UPDATEROLE } from "../../service";
import Swal from "sweetalert2";
import {
    Container,
} from "@mui/material";

//calendar
import BackdropLoad from '../../components/Backdrop/BackdropLoad';
import GridTextField from './components/GridTextField';
import { useLocation } from 'react-router-dom';

function EditUser() {
    const locationpath = useLocation();
    const { detail } = locationpath.state
    // const params = useParams();
    const [dataScreen, setDataScreen] = useState([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');

    const getRole = async () => {
        try {
            setLoading(true)
            let res = await POST(GETROLEBYID, { role_id: detail })
            if (res.success) {
                let data = []
                let data_role = res.result;
                let data_screen = res.result.screen;
                setName(data_role.role_name);
                data_screen.map((e) =>
                    data.push({
                        ...e,
                        check: e.status,
                    })
                )
                setDataScreen(data)
                setLoading(false)
            } else {
                setLoading(false)
                alert(res.result);
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const handleUpdateUser = async () => {
        // console.log(detail, name, dataScreen);
        try {
            setLoading(true)
            let data = [];
            data.push({
                role_name: name
            })
            let res = await POST(UPDATEROLE, { role_id: detail, data: data[0], screen: dataScreen.map(({ check, screen_name, screen_path, type, ...rest }) => rest) });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `แก้ไขบัญชีผู้ใช้งานสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Role");
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    text: `แก้ไขบัญชีผู้ใช้งานไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }


        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };


    const handleChangeCheckbox = (event) => {
        let id = event.target.value;

        if (event.target.name === "ทั้งหมด") {
            // let data = []
            dataScreen.map((e) =>
                setDataScreen(prevDataScreen => prevDataScreen.map(item =>
                    Number(item.screen_id) === Number(e.screen_id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
                ))
            )
            // setDataScreen(data)
        } else {
            setDataScreen(prevDataScreen => prevDataScreen.map(item =>
                Number(item.screen_id) === Number(id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
            ))
        }

    };

    useEffect(() => {
        getRole();
        // getLocation();
        // getHospital();
        // getUser();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridTextField
                action="update"
                dataScreen={dataScreen}
                handleUpdateUser={handleUpdateUser}
                handleChangeCheckbox={handleChangeCheckbox}
                name={name}
                setName={(e) => setName(e)}
                checkAll={dataScreen.findIndex(x => Number(x.status) === 0)}
            />
        </Container>
    )
}

export default EditUser;