import React from 'react'

import {
    Container,
} from "@mui/material";

import ListHistory from './components/ListHistory';

function History() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <ListHistory />
        </Container>
    )
}

export default History;