
import React, { Component } from 'react'

import {
    Container,
} from "@mui/material";

import ListReportLocation from './components/ListReportApprove';
import { headerTable, textFiled } from '../../../untils/static';

class ReportApprove extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        // console.log(e.target.name, e.target.value);
    };

    render() {
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <ListReportLocation
                    header={headerTable.report_approve}
                    data={rows}
                    handleChange={this.handleChange}
                    state={this.state}
                    select={textFiled.location}
                />
            </Container>
        );
    }
}

export default ReportApprove;

function createData(loca_id, location_name, order, date) {
    return { loca_id, location_name, order, date };
}

const rows = [
    createData(1, 'โรงพยาบาล A', '00-00000', 'วว/ดด/ปปปป',),
    createData(2, 'โรงพยาบาล B', '00-00001', 'วว/ดด/ปปปป'),
];