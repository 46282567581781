import React, { useState } from 'react'
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../Theme"

import {
    DocumentIcon,
    MoreIcon,
} from '../../../untils/icons'

export default function GridTableList(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {props.header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <Typography fontWeight='bold' fontSize='14px'>{column.label}</Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <StyledTableCell align="center">{row.date}</StyledTableCell>
                                        <StyledTableCell align="center">{row.type}</StyledTableCell>
                                        <StyledTableCell align="center">{row.destination}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <DocumentIcon />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <MoreIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Order/${row.id}`)} />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
            />
        </Paper>
    );
}
