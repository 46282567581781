import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";

import {
    EditNoteDrawerIcon,
    CalendarIcon, TypeIcon
} from '../../../untils/icons';

import GridTableDetail from './GridTableDetail';

import {
    Item,
} from '../../../Theme';
import moment from 'moment';

function GridDetail(props) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} mt={1} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายละเอียดการเบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={5} className="flex-start">
                        <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <CalendarIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">วันที่ทำรายการ</Typography>
                                        <Typography color="var(--teal-A800)">{moment(props.detail.date_created).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <TypeIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">เลขคำสั่ง</Typography>
                                        <Typography color="var(--teal-A800)">{props.detail.order_code ? props.detail.order_code : "-"}</Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                            {/* <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <LocationIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">ปลายทาง</Typography>
                                        <Typography color="var(--teal-A800)">ห้องยา</Typography>
                                    </Grid>
                                </Stack>
                            </Item> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-start" mt={5}>
                        <Typography color="var(--teal-A800)" sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                            รายการเวชภัณฑ์เบิกจ่าย รวม {props.data.length > 0 ? props.data.length : 0} รายการ
                        </Typography>
                        <Typography color="var(--teal-A800)" sx={{ display: { xs: 'block', sm: 'none' }, cursor: "pointer" }}>
                            รายการเบิกจ่าย รวม 0 รายการ
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={5}>
                        {props?.data &&
                            <GridTableDetail header={props.header} data={props.data} />}
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default GridDetail;