
import React from 'react'

import {
    Container,
} from "@mui/material";

import ListReportLocation from './components/ListReportLocation';

function ReportLocation() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <ListReportLocation />
        </Container>
    )
}

export default ReportLocation;