import React, { useState, useMemo, useEffect } from 'react';
import { POST, SEARCH, ADDTRANSACTION } from "../../service";
import {
    Grid,
    Box,
    Paper,
    Typography,
    TextField,
    Stack,
    Autocomplete,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { debounce } from '@mui/material/utils';
import Swal from "sweetalert2";

import {
    EditNoteDrawerIcon,
} from '../../untils/icons';

import {
    ButtonTeal,
} from '../../Theme';

import BackdropLoad from '../../components/Backdrop/BackdropLoad';
import GridTableAdd from './components/GridTableAdd';
import { headerTable } from '../../untils/static';
import { getToken } from '../../untils/shortcut';

function AddRequisition() {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [search, setSearch] = useState('');
    const [searchList, setSearchList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const profile = getToken(localStorage.getItem("profile"));

    const handlePushBack = () => {
        navigate(-1)
    }

    const submitAlert = () => {
        if (name === '' || lastname === '' || data.length === 0) {
            Swal.fire({
                icon: 'warning',
                // text: `${res.message}`,
                text: data.length === 0 ? `กรุณาเพิ่มรายการอย่างน้อย 1 รายการ` : `กรุณากรอกชื่อผู้ทำการเบิก`,
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(false);
                }
            })
            return;
        } else if (data.some(item =>
            item.amount === ''
        ) || data.some(item =>
            item.amount === 0
        )
        ) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `กรุณากรอกจำนวน`,
                confirmButtonText: 'ตกลง',
            })
            return;
        }
        const payload =
        {
            name: name + " " + lastname,
            user_id: profile.user_id,
            location_id: profile.location_id,
            data: data.map(({
                cost,
                date_created,
                date_updated,
                drug_name,
                drug_name_thai,
                drug_type,
                location_id,
                pack,
                sell,
                vendor_id, ...rest }) => rest)

        }
        Swal.fire({
            title: 'คุณต้องการบันทึกรายการหรือไม่?',
            text: "ถ้ายืนยันรายการแล้วคุณไม่สามารถแก้ไขได้",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ยืนยันรายการ',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmitTransaction(payload);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    text: 'ยกเลิกรายการแล้ว',
                    confirmButtonText: 'ตกลง',
                })
            }
        })
    }

    const handleSubmitTransaction = async (payload) => {
        // console.log(payload)
        try {
            // console.log({ payload })
            setLoading(true)
            let res = await POST(ADDTRANSACTION, payload);
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `สร้างรายการเบิกเวชภัณฑ์สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/RequisitionLocation");
                    }
                })
                navigate(-1)
            } else {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    text: `ทำรายการไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    useEffect(() => {
        setName(profile.name)
        setLastname(profile.lastname)
        const handleBeforeUnload = (event) => {
            const message = 'Are you sure you want to leave? Your changes may not be saved.';
            event.returnValue = message;
            return message;
        };

        const handlePopstate = () => {
            // Display a warning when the user navigates back
            const confirmation = window.confirm('Are you sure you want to go back? Your changes may not be saved.');
            if (!confirmation) {
                window.history.pushState(null, '', window.location.href);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []); // Empty dependency array ensures the effect runs only once during mount

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    // setLoading(true)
                    let res = await POST(SEARCH, { search: request });
                    if (res.success) {
                        setSearchList(res.result)
                        // setLoading(false);
                    } else {
                        // setLoading(false);
                        Swal.fire({
                            icon: 'warning',
                            // text: `${res.message}`,
                            text: `ค้นหาไม่สำเร็จ`,
                            confirmButtonText: 'ตกลง',
                        })
                    }
                } catch (error) {
                    // setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        text: `${error}`,
                        confirmButtonText: 'ตกลง',
                    })
                }
            }, 1500),
        [],
    );

    useEffect(() => {
        // let active = true;

        if (inputValue === '') {
            setSearchList(search ? [search] : []);
            return undefined;
        }

        fetch(inputValue);

        // return () => {
        //     active = false;
        // };
    }, [search, inputValue, fetch]);

    return (

        <Box display="flex" justifyContent="center">
            <BackdropLoad loading={loading} />
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายการเวชภัณฑ์ที่เบิกได้
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={2}>
                        <TextField
                            required
                            fullWidth
                            label="ชื่อผู้เบิก"
                            name="name"
                            placeholder="กรุณากรอกชื่อผู้เบิก"
                            variant="standard"
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} my={2}>
                        <TextField
                            required
                            fullWidth
                            label="นามสกุลผู้เบิก"
                            name="lastname"
                            placeholder="กรุณากรอกนามสกุลผู้เบิก"
                            variant="standard"
                            value={lastname}
                            onChange={(e) => setLastname(e.currentTarget.value)}
                        />
                    </Grid>
                    <Grid item xs={12} className="flex-start">
                        <Autocomplete
                            id="google-map-demo"
                            fullWidth
                            // disableCloseOnSelect
                            // sx={{ width: 300 }}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.drug_name
                            }
                            filterOptions={(x) => x}
                            options={searchList}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={search}
                            noOptionsText="No locations"
                            onChange={(event, newValue) => {
                                setSearch(newValue);
                                // console.log("onChange");
                                if (!newValue || data.some(e => e.drug_id === newValue.drug_id)) {
                                    return;
                                }
                                setData([...data, { ...newValue, remain: 0, amount: 0 }]);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Search" />}
                        />
                    </Grid>
                    <Grid item xs={12} mt={5} >
                        <GridTableAdd header={headerTable.requisition_add_loca} data={data} setData={setData} />
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-start" >
                        <ButtonTeal onClick={handlePushBack}>
                            <Typography sx={{ cursor: "pointer" }} >
                                ยกเลิก
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                    <Grid item xs={8} sm={6} md={6} my={5} className="flex-end" >
                        <Typography sx={{ cursor: "pointer", mr: 2 }} >
                            ต้องการเบิก {data.length} รายการ
                        </Typography>
                        <ButtonTeal onClick={submitAlert}>
                            <Typography sx={{ cursor: "pointer" }} >
                                บันทึกรายการเวชภัณฑ์
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default AddRequisition;