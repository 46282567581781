import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import ReportCard from "./ReportDiagnose/ReportCard";
import { POST, REPORTDIAGNOSE, REPORTDIAGNOSEMAP } from "../../service";
import Swal from "sweetalert2";
import dayjs from "dayjs";

function ReportDiagnose() {
    const [data, setData] = useState([]);
    const [ageData, setAgeData] = useState([]);
    const [topData, setTopData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [sexData, setSexData] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [selectHname, setSelectHname] = useState('ทั้งหมด');
    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("month"));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));

    const getReportDiagnose = async () => {
        try {
            setLoading(true)
            let res = await POST(REPORTDIAGNOSE, { hname: selectHname, dateStart, dateEnd })
            // console.log(res);
            if (res.success) {
                setData(res.result)
                setAgeData(res.result.ageDiagData)
                setTopData(res.result.diagDisease)
                setYearData(res.result.diagPerYear)
                setSexData(res.result.sexCountType)
                setDataLocation(res.result.forSelect)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            setLoading(false)
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    const getReportDiagnoseMap = async () => {
        setSubdistrictObj([]);
        try {
            setLoading(true)
            let res = await POST(REPORTDIAGNOSEMAP, { hname: selectHname, dateStart, dateEnd })
            console.log(res.result);
            if (res.success) {
                setSubdistrictObj(res.result.features)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            setLoading(false)
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    useEffect(() => {
        getReportDiagnose();
        getReportDiagnoseMap();
    }, [dateStart, dateEnd, selectHname]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ReportCard
                data={data}
                ageData={ageData}
                topData={topData}
                yearData={yearData}
                sexData={sexData}
                dataLocation={dataLocation}
                subdistrictObj={subdistrictObj}
                date_start={dateStart}
                date_end={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
                setSelectHname={setSelectHname}
                selectHname={selectHname}
            />
        </Container>
    );
}

export default ReportDiagnose;
