import React, { useState } from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    FormControl,
    TextField,
    Stack,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';

import {
    EditNoteDrawerIcon,
} from '../../untils/icons';

import {
    ButtonTeal,
} from '../../Theme'

import GridTableReciveHospital from './components/GridTableReciveHospital';
import { headerTable } from '../../untils/static';

function ReciveHospital() {

    const navigate = useNavigate();

    const [type, setType] = useState('');
    const [numberSelect, setNumberSelect] = useState();

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const handlePushBack = () => {
        navigate(-1)
    }

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                            <InputLabel id="demo-simple-select-standard-label">เลือกประเภทการเบิกจ่าย</InputLabel>
                            <Select
                                value={type}
                                onChange={handleChange}
                                label="type"
                                sx={{ my: 1 }}
                                size='medium'
                                disabled
                            >
                                <MenuItem value={1}>เบิกปกติ (เบิกไปห้องจ่ายยา)</MenuItem>
                            </Select>
                            <TextField
                                id="standard-basic"
                                variant="standard"
                                label="ชื่อผู้เบิก"
                                sx={{ mt: 1, mb: 5 }}
                                disabled
                            />
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="ค้นหารายการ"
                                type="search"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={5} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header" >
                                รายละเอียดการขอเบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={5} >
                        <GridTableReciveHospital setNumber={(e) => setNumberSelect(e)} header={headerTable.requisition_add_hos} data={rows} />
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-start" >
                        <ButtonTeal onClick={handlePushBack}>
                            <Typography sx={{ cursor: "pointer" }} >
                                ยกเลิก
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                    <Grid item xs={8} sm={6} md={6} my={5} className="flex-end" >
                        <Typography sx={{ cursor: "pointer", mr: 2 }} >
                            ต้องการเบิก {numberSelect ? numberSelect : 0} รายการ
                        </Typography>
                        <ButtonTeal>
                            <Typography sx={{ cursor: "pointer" }} >
                                ยืนยันรายการ
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ReciveHospital;

function createData(id, drug_name, type, unit, remain, amount, amount_request) {
    return { id, drug_name, type, unit, remain, amount, amount_request };
}

const rows = [
    createData(1, 'Amitriptyline 10 mg', 'เวชภัณฑ์ยา', 'ขวด', 3, 0),
    createData(2, 'Alcohol 70% 450 ml', 'เวชภัณฑ์ยา', 'ขวด', 3, 0),
    createData(3, 'ถุงดำ', 'อื่นๆ', 'ถุง', 3, 0),
];