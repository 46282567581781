import React from "react";

import { Container, Box, Paper, Grid, Stack, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { Dashboard } from "../../../untils/icons";
import BarChartVertical from "./BarChartVertical";
import LineChartStatement from "./LineChartStatement";

function ChartStatement(props) {
     return (
          <Container maxWidth="xl" sx={{ position: "relative" }}>
               <Box display="flex" justifyContent="center">
                    <Paper className="paper-bg" elevation={0}>
                         <Grid container columns={12}>
                              <Grid item xs={12} className="flex-start">
                                   <Stack direction="row" spacing={2}>
                                        <Dashboard fontSize="medium" />
                                        <Typography className="main-header">กราฟการเงิน</Typography>
                                   </Stack>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} mt={3}>
                                   <FormControl fullWidth>
                                        <InputLabel shrink>เลือกปีงบประมาณ</InputLabel>
                                        <Select
                                             name="year"
                                             value={props.year}
                                             label="เลือกปีงบประมาณ"
                                             required
                                             onChange={(e) => props.setYear(e.target.value)}
                                             displayEmpty
                                        >
                                             <MenuItem value="" disabled>
                                                  เลือกปีงบประมาณ
                                             </MenuItem>
                                             {props.yearsData.map((el) => (
                                                  <MenuItem key={el.value} value={el.value}>
                                                       {Number(el.value) + 543}
                                                  </MenuItem>
                                             ))}
                                        </Select>
                                   </FormControl>
                              </Grid>
                              <Grid item xs={12} my={3}>
                                   <LineChartStatement information={props.data} />
                              </Grid>
                              <Grid item xs={12} my={3}>
                                   <BarChartVertical information={props.data} />
                              </Grid>
                         </Grid>
                    </Paper>
               </Box>
          </Container>
     );
}

export default ChartStatement;
