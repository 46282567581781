import React, { useState, useEffect } from 'react'
import { POST, GETTRANSACTIONBYID, APPROVETRANSITION } from "../../service";
import { useLocation } from 'react-router-dom';
import {
    Container,
} from "@mui/material";
import BackdropLoad from '../../components/Backdrop/BackdropLoad';
import GridDetail from './ListTransactionApprove/GridDetail';
import { headerTable } from '../../untils/static';
import Swal from "sweetalert2";

function DetailApprove() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const id_path = useLocation()
    const { detail } = id_path.state;

    const getTransactionById = async () => {
        setLoading(true)
        try {
            let res = await POST(GETTRANSACTIONBYID, { order_id: detail.order_id });
            if (res.success) {
                setData(res.result)
                setLoading(false)
            } else {
                setLoading(false)
            }

        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    const submitApproveAlert = () => {
        // console.log("data", data)
        if (data.some(item => item.amount_approve === '') || data.some(item => item.amount_approve === 0)) {
            Swal.fire({
                icon: 'warning',
                text: `กรุณากรอกจำนวน`,
                confirmButtonText: 'ตกลง',
            })
            return;
        } else if (data.some(item => item.lot_no === '') || data.some(item => item.lot_no === null)) {
            Swal.fire({
                icon: 'warning',
                text: `กรุณากรอก Lot No.`,
                confirmButtonText: 'ตกลง',
            })
            return;
        } else if (data.some(item => item.expire === '') || data.some(item => item.expire === null)) {
            Swal.fire({
                icon: 'warning',
                text: `กรุณากรอกวันหมดอายุ`,
                confirmButtonText: 'ตกลง',
            })
            return;
        }

        const payload =
        {
            order_id: detail.order_id,
            status: 2,
            data: data.map(({
                stock_id,
                receive_hos_id,
                drug_code,
                drug_id,
                amount,
                unit,
                transaction_type,
                stock_type,
                status,
                order_id,
                date_created,
                date_updated,
                remain,
                drug_name,
                drug_name_thai,
                drug_type,
                pack,
                cost,
                sell,
                vendor_id,
                location_id, ...rest }) => rest)
        }

        Swal.fire({
            title: 'คุณต้องการยืนยันรายการหรือไม่?',
            text: "ถ้ายืนยันรายการแล้วคุณไม่สามารถแก้ไขได้",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ยืนยันรายการ',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmitApprove(payload);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    text: 'ยกเลิกรายการแล้ว',
                    confirmButtonText: 'ตกลง',
                })
            }
        })
    }

    const handleSubmitApprove = async (payload) => {
        // console.log(payload)

        try {
            setLoading(true)
            let res = await POST(APPROVETRANSITION, payload);
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `ยืนยันรายการเบิกเวชภัณฑ์สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/ApproveOrder");
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    text: `ยืนยันรายการไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    const submitRejectAlert = () => {

        const payload =
        {
            order_id: detail.order_id,
            status: 3,
            data: data.map(({
                stock_id,
                receive_hos_id,
                drug_code,
                drug_id,
                amount,
                unit,
                transaction_type,
                stock_type,
                status,
                order_id,
                date_created,
                date_updated,
                remain,
                drug_name,
                drug_name_thai,
                drug_type,
                pack,
                cost,
                sell,
                vendor_id,
                amount_approve,
                expire,
                lot_no,
                location_id, ...rest }) => rest)
        }

        Swal.fire({
            title: 'คุณต้องการยกเลิกรายการหรือไม่?',
            // text: "ถ้ายกเลิกรายการแล้วคุณไม่สามารถแก้ไขได้",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmitReject(payload);
            }
        })
    }

    const handleSubmitReject = async (payload) => {
        // console.log(payload);
        try {
            setLoading(true)
            let res = await POST(APPROVETRANSITION, payload);
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `ยกเลิกรายการเบิกเวชภัณฑ์สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/ApproveOrder");
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    text: `ยกเลิกรายการไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         const message = 'Are you sure you want to leave? Your changes may not be saved.';
    //         event.returnValue = message;
    //         return message;
    //     };

    //     const handlePopstate = () => {
    //         // Display a warning when the user navigates back
    //         const confirmation = window.confirm('Are you sure you want to go back? Your changes may not be saved.');
    //         if (!confirmation) {
    //             window.history.pushState(null, '', window.location.href);
    //         }
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     window.addEventListener('popstate', handlePopstate);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //         window.removeEventListener('popstate', handlePopstate);
    //     };
    // }, []); // Empty dependency array ensures the effect runs only once during mount

    useEffect(() => {
        getTransactionById();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridDetail
                header={headerTable.approved_detail}
                data={data}
                setData={setData}
                detail={detail}
                submitApproveAlert={submitApproveAlert}
                submitRejectAlert={submitRejectAlert}
            />
        </Container>
    );

}

export default DetailApprove;