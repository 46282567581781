import React, { useState } from "react";
import { Grid, Box, Typography, Card, Container, CardContent, Stack, TextField } from "@mui/material";
import VerifyImage from "../../assets/images/VerifyIdentity.jpeg";

import { useParams } from "react-router-dom";
import { ButtonTeal } from "../../Theme";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { POST, VERIFY } from "../../service";
import Swal from "sweetalert2";

function VerifyIdentity() {
     const params = useParams();
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("");
     const [loading, setLoading] = useState(false);

     const handleVerifyIdentity = async () => {
          // console.log(params.id);
          try {
               setLoading(true);
               let res = await POST(VERIFY, { email, uid: params.uid, password });
               if (res.success) {
                    setLoading(false);
                    Swal.fire({
                         icon: "success",
                         text: res.result,
                         confirmButtonText: "ตกลง",
                    }).then((result) => {
                         if (result.isConfirmed) {
                              window.location.replace("/User");
                         }
                    });
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: res.message,
                         confirmButtonText: "ตกลง",
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
          // navigate("/Login")
     };

     return (
          <>
               <Container sx={{ position: "relative" }}>
                    <BackdropLoad loading={loading} />
                    <Box className="flex-center" height="90vh">
                         <Grid container columns={12} className="flex-center">
                              <Grid item my={5} xs={12} className="flex-center">
                                   <Card>
                                        <CardContent
                                             sx={{
                                                  p: 5,
                                                  border: "var(--dark-teal) solid 15px",
                                                  width: "55vw",
                                                  height: "60vh",
                                                  minWidth: "300px",
                                                  display: "flex",
                                             }}
                                        >
                                             <Stack width="100%" className="flex-center" spacing={2}>
                                                  <img src={VerifyImage} style={{ width: "80px", height: "80px" }} alt="verify"/>
                                                  <Typography className="text-subtitle">ยืนยันตัวตนของท่าน</Typography>
                                                  <TextField
                                                       required
                                                       fullWidth
                                                       label="E-mail"
                                                       name="email"
                                                       placeholder="E-mail"
                                                       variant="outlined"
                                                       value={email}
                                                       // error={errorEmail}
                                                       onChange={(e) => {
                                                            // console.log(e);
                                                            setEmail(e.target.value);
                                                       }}
                                                       InputLabelProps={{
                                                            shrink: true,
                                                       }}
                                                  />
                                                  <TextField
                                                       required
                                                       fullWidth
                                                       label="รหัสผ่าน"
                                                       name="password"
                                                       placeholder="รหัสผ่าน"
                                                       variant="outlined"
                                                       value={password}
                                                       // error={errorEmail}
                                                       type="password"
                                                       onChange={(e) => {
                                                            // console.log(e);
                                                            setPassword(e.target.value);
                                                       }}
                                                       InputLabelProps={{
                                                            shrink: true,
                                                       }}
                                                  />
                                                  <ButtonTeal size="large" fullWidth onClick={handleVerifyIdentity}>
                                                       ยืนยัน
                                                  </ButtonTeal>
                                             </Stack>
                                        </CardContent>
                                   </Card>
                              </Grid>
                         </Grid>
                    </Box>
               </Container>
               <footer>
                    <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
                         <path
                              transform="translate(0, -20)"
                              d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700"
                              fill="var(--teal-100)"
                              opacity="0.8"
                         />
                         <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="var(--teal-A700)" />
                    </svg>
               </footer>
          </>
     );
}

export default VerifyIdentity;
