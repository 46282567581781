
import React from 'react'

import {
    Container,
} from "@mui/material";

import DetailDrug from './components/DetailDrug';

function ReportDetailDrug() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <DetailDrug />
        </Container>
    )
}

export default ReportDetailDrug;