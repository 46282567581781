import React from 'react'

import {
    Container,
} from "@mui/material";

import ListVendor from './ListVender/ListVendor';

function Vendor() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <ListVendor />
        </Container>
    )
}

export default Vendor;