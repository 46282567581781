import '../src/css/color.css';
import '../src/css/global.css';
import '../src/css/map.css';
import '../src/css/legend.css';
import '../src/css/dialog.css'
import '../src/css/dashboard.css'
import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Theme from './Theme.jsx';
import RouterMain from './pages/RouterMain';
import { v4 as uuid } from "uuid";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function App() {
  const unique_id = uuid();
  // console.log(unique_id)

  useEffect(() => {
    // console.log({ local: localStorage.getItem('uuid') });
    if (localStorage.getItem('uuid'))
      return;
    // console.log({ try: unique_id });
    if (unique_id)
      localStorage.setItem("uuid", unique_id);
  }, []);

  return (
    <BrowserRouter>
      <Theme>
        <CssBaseline />
        <RouterMain />
      </Theme>
    </BrowserRouter>
  );
}

export default App;
