import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";

import {
    EditNoteDrawerIcon,
} from '../../../untils/icons';

import GridTableList from './GridTableList';

function ListRequisitionHospital(props) {


    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header" >
                                เบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5} className="flex-center" >
                        <GridTableList header={props.header} data={props.data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListRequisitionHospital;

