import React, { useState } from "react";
import { headerTable } from "../../../untils/static";
import dayjs from "dayjs";
import HistoryCard from "./components/HistoryCard.js";

function HistoryClaim() {
    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("month"));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    // const [data, setData] = useState([]);
    const data = [];

    return (
        <>
            <HistoryCard
                header={headerTable.claim_history}
                data={data}
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
            />
        </>
    );
}

export default HistoryClaim;
