import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField,
} from "@mui/material";

import {
    ListIcon,
} from '../../../../untils/icons';

import GridTableList from './GridTableList';
import { headerTable } from '../../../../untils/static';

function ListReportDrug() {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ListIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายงานสรุปรายการเวชภัณฑ์ (แยกตามเวชภัณฑ์)
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการเวชภัณฑ์"
                            type="search"
                            variant="outlined"
                            size='medium'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5} className="flex-center" >
                        <GridTableList header={headerTable.report_drug} data={rows} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListReportDrug;

function createData(drug_id, drug_name, type, amount) {
    return { drug_id, drug_name, type, amount };
}

const rows = [
    createData(1, 'CEMOL CENTRAL 500 mg', ' เวชภัณฑ์ยา', 5),
    createData(2, 'CEMOL CENTRAL ', ' เวชภัณฑ์ยา', 15),
];