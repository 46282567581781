import React, { useState, useEffect } from "react";

import { Container } from "@mui/material";
import { headerTable } from "../../../untils/static";
import GridDetail from "./components/GridDetail";
import { useParams } from "react-router-dom";
import { GETCLAIMDETAIL, POST } from "../../../service";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
function DetailClaim16() {
     const params = useParams();
     const [data, setData] = useState({
          data_adp: [],
          data_odx: [],
     });
     const [loading, setLoading] = useState(false);

     const getClaimDetail = async () => {
          try {
               setLoading(true);
               let res = await POST(GETCLAIMDETAIL, JSON.parse(params.detail));
               if (res.success) {
                    setData({ ...res.result });
                    setLoading(false);
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         showConfirmButton: true,
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     useEffect(() => {
          getClaimDetail();
     }, []);

     return (
          <Container maxWidth="xl" sx={{ position: "relative" }}>
               <BackdropLoad loading={loading} />
               <GridDetail header={headerTable.claim_detail} headerOdx={headerTable.headerOdx} data={data} detail={JSON.parse(params.detail)} />
          </Container>
     );
}

export default DetailClaim16;
