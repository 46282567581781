import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField,
} from "@mui/material";

import {
    VendorIcon,
} from '../../../untils/icons';

import { headerTable } from '../../../untils/static';
import GridTableList from './GridTableList';

function ListVendor() {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <VendorIcon fontSize='medium' />
                            <Typography className="main-header" >
                                รายการบริษัทยา
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size='medium'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList header={headerTable.vendor} data={rows} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListVendor;


function createData(vendor_id, vendor_name, vendor_location) {
    return { vendor_id, vendor_name, vendor_location };
}

const rows = [
    createData(1, 'บริษัท โนวาร์ตีส (ประเทศไทย) จำกัด', 'ชั้น 25 อาคารภิรัชทาวน์เวอร์แอ๊ดเอ็มควอเทียร์ ถนนสุขุมวิท แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพฯ 10110'),
];