import React from 'react';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";

import { StyledTableCell } from "../../../Theme";

export default function GridTableList({
    header,
    data,
    handleChangePage,
    handleChangeRowsPerPage,
    count,
    rowsPerPage,
    page,
}) {

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="left">{row.drugname ? row.drugname : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.drugcode ? row.drugcode : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.drugtype}</StyledTableCell>
                                        <StyledTableCell align="right">{row.unitpacking ? row.unitpacking : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="right">{row.daystockremain ? `${row.daystockremain} ${(row.unit)}` : "N/A"} </StyledTableCell>
                                        <StyledTableCell align="right">{row.cost}</StyledTableCell>
                                        {/* <StyledTableCell align="right">{row.date_in}</StyledTableCell> */}
                                        {/* <StyledTableCell align="right">{row.expire}</StyledTableCell> */}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}

