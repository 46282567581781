import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartVertical({ information }) {
     //  console.log("information", information);
     const options = {
          indexAxis: "x",
          elements: {
               bar: {
                    borderWidth: 2,
               },
          },
          responsive: true,
          plugins: {
               datalabels: false,
          },
          legend: {
               display: false,
          },
     };

     const labels = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

     const data = {
          labels,
          datasets: [
               {
                    label: "OPD ทั้งหมด",
                    data: [120, 190, 130],
                    borderColor: "rgba(54, 162, 235, 1)",
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
               },
               {
                    label: "Audit ผ่าน",
                    data: [120, 19, 3],
                    borderColor: "rgba(75, 192, 192, 1)",
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
               },
               {
                    label: "Audit ไม่ผ่าน",
                    data: [12, 190, 30],
                    borderColor: "rgba(255, 99, 132, 1)",
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
               },
          ],
     };
     return (
          <Grid container columns={12}>
               <Grid item xs={12} className="census-info-chart-h">
                    <Bar data={data} options={options} />
               </Grid>
          </Grid>
     );
}
