import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, Grid, Typography } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart({ information }) {

    const data = {
        labels: ['Audit ผ่าน', 'Audit ไม่ผ่าน'],
        datasets: [
            {
                label: 'จำนวน',
                data: [information.pass, information.notpass],
                backgroundColor: [
                    'rgba(46, 125, 50, 0.5)',
                    'rgba(211, 47, 47, 0.5)',

                ],
                borderColor: [
                    'rgba(46, 125, 50, 1)',
                    'rgba(211, 47, 47, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} >
                <Box className="census-info">
                    <Typography>
                        จำนวนการเข้ารับบริการ
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} className="flex-center" style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '450px', width: '100%' }}>
                {(information.pass !== 0 && information.notpass !== 0) ?
                    <Doughnut data={data} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                }
            </Grid>
        </Grid>
    )
}
