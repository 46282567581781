import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
    ManageAccountsDrawerIcon,
    AddItemIcon,
} from '../../../untils/icons';

import {
    ButtonTeal,
} from '../../../Theme'

import GridTable from './GridTable';

function ManageUserCard({
    header_table,
    data,
    deleteAlert,
    setSearch,
    setRoleId,
    search,
    roleId,
    dataRole,
}) {

    const navigate = useNavigate();

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={2}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ManageAccountsDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                จัดการผู้ใช้งาน
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-end" >
                        <ButtonTeal onClick={handleRoute(`Add`)}>
                            <AddItemIcon fontSize='small' />&nbsp;
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                สร้างบัญชีผู้ใช้งาน
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                    <Grid item xs={4} >
                        <FormControl fullWidth>
                            <InputLabel >เลือกบทบาท</InputLabel>
                            <Select
                                name="role"
                                value={roleId}
                                label="เลือกบทบาท"
                                required
                                onChange={(e) => setRoleId(e.target.value)}
                            >
                                {dataRole &&
                                    dataRole.map((el) => (
                                        <MenuItem key={el.role_id} value={el.role_id} disabled={el.disabled}>
                                            {el.role_name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8} >
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size='medium'
                            onChange={(e) => { setSearch(e.target.value) }}
                            value={search}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={2} className="flex-center" >
                        <GridTable header={header_table} data={data} deleteAlert={deleteAlert} dataRole={dataRole}/>
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ManageUserCard;