import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    TextField,
    Stack,
    Container,
} from "@mui/material";

import {
    ManageAccountsDrawerIcon,
} from '../../../untils/icons';

import {
    ButtonTeal,
} from '../../../Theme'

import { select_data } from '../../../untils/static';

function GridTextField({
    action,
    handleUpdateDrug,
    handleAddDrug,
    handleChangeText,
    // dataType,
    dataVendor,
    dataLocation,

    type,
    drugCode,
    drugName,
    drugNameTh,
    cost,
    sell,
    unit,
    vendorId,
    location,
    pack,
    setType,
    setDrugCode,
    setDrugName,
    setDrugNameTh,
    setCost,
    setSell,
    setUnit,
    setVendorId,
    setLocation,
    setPack,
}) {

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <ManageAccountsDrawerIcon fontSize='medium' />
                                <Typography className="main-header" >
                                    {action === "insert" ? "สร้างรายการเวชภัณฑ์" : "แก้ไขรายการเวชภัณฑ์"}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
            <Container maxWidth="sm" >
                <Grid container columns={12} >
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start" >
                            <Typography>ประเภทเวชภัณฑ์ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} mb={2}>
                            <FormControl fullWidth>
                                <InputLabel >ประเภทเวชภัณฑ์</InputLabel>
                                <Select
                                    name="list_type"
                                    value={type}
                                    label="เลือกประเภทเวชภัณฑ์"
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {select_data.list_type &&
                                        select_data.list_type.map((el) => (
                                            <MenuItem key={el.value} value={el.value} disabled={el.disabled}>
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>หมายเลขเวชภัณฑ์ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="หมายเลขเวชภัณฑ์"
                                name="drug_code"
                                placeholder="กรุณากรอกหมายเลขเวชภัณฑ์"
                                variant="outlined"
                                value={drugCode}
                                onChange={(e) => setDrugCode(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ชื่อเวชภัณฑ์ (EN) :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ชื่อเวชภัณฑ์ (EN)"
                                name="drug_name"
                                placeholder="กรุณากรอกชื่อเวชภัณฑ์เป็นภาษาอังกฤษ"
                                variant="outlined"
                                value={drugName}
                                onChange={(e) => setDrugName(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ชื่อเวชภัณฑ์ (TH) :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ชื่อเวชภัณฑ์ (TH)"
                                name="drug_name_thai"
                                placeholder="กรุณากรอกชื่อเวชภัณฑ์เป็นภาษาไทย"
                                variant="outlined"
                                value={drugNameTh}
                                onChange={(e) => setDrugNameTh(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ขนาดบรรจุ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ขนาดบรรจุ"
                                name="pack"
                                placeholder="กรุณากรอกขนาดเวชภัณฑ์"
                                variant="outlined"
                                value={pack}
                                onChange={(e) => setPack(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ราคาซื้อ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ราคาซื้อ"
                                name="cost"
                                placeholder="กรุณากรอกราคาซื้อ (บาท)"
                                variant="outlined"
                                type="text"
                                value={cost}
                                onChange={(e) => setCost(handleChangeText(e))}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ราคาจำหน่าย :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ราคาจำหน่าย"
                                name="sell"
                                placeholder="กรุณากรอกราคาจำหน่าย (บาท)"
                                variant="outlined"
                                value={sell}
                                type="text"
                                onChange={(e) => setSell(handleChangeText(e))}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>หน่วยนับ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="หน่วยนับ"
                                name="unit"
                                placeholder="กรุณากรอกหน่วยนับของเวชภัณฑ์"
                                variant="outlined"
                                value={unit}
                                onChange={(e) => setUnit(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start" >
                            <Typography>รพ.สต. :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} mb={2}>
                            <FormControl fullWidth>
                                <InputLabel >เลือก รพ.สต.</InputLabel>
                                <Select
                                    name="location"
                                    value={location}
                                    label="เลือก รพ.สต."
                                    required
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                    {dataLocation &&
                                        dataLocation.map((el) => (
                                            <MenuItem key={el.value} value={el.value} disabled={el.disabled}>
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start" >
                            <Typography>บริษัทยา :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} mb={2}>
                            <FormControl fullWidth>
                                <InputLabel >เลือกบริษัทยา</InputLabel>
                                <Select
                                    name="vendorId"
                                    value={vendorId}
                                    label="เลือกบริษัทยา"
                                    required
                                    onChange={(e) => setVendorId(e.target.value)}
                                >
                                    {dataVendor &&
                                        dataVendor.map((el) => (
                                            <MenuItem key={el.value} value={el.value} disabled={el.disabled}>
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item my={5} xs={12}>
                        {action === "insert" ?
                            <ButtonTeal size='large' fullWidth onClick={handleAddDrug}>
                                บันทึกข้อมูล
                            </ButtonTeal> :
                            <ButtonTeal size='large' fullWidth onClick={handleUpdateDrug}>
                                แก้ไขข้อมูล
                            </ButtonTeal>
                        }
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}

export default GridTextField;


