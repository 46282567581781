import React from 'react'

import {
    Container,
} from "@mui/material";

import DetailOrder from './components/DetailOrder';
import { textFiled } from '../../../untils/static';

function ReportDetailOrder() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <DetailOrder banner={textFiled.banner_order} />
        </Container>
    )
}

export default ReportDetailOrder;