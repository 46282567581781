import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export default function InpatientDashboard() {
    const options = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        stacked: false,
        plugins: {
            datalabels: {
                    display: function (context) {
                        return context.chart.isDatasetVisible(context.datasetIndex);
                    },
                    borderRadius: 4,
                    align: "end",
                    anchor: "end",
                    color: "black",
                    backgroundColor: "#BEBEBD",
                    formatter: function(value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
            },
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'ผู้ป่วยใน',
            },
        },
        scales: {
            y: {
                type: "linear",
                display: true,
                position: "left",
            },
        },
    };

    const labels = ["Oct 66", "Nov 66", "Dec 66", "Jan 67", "Feb 67", "Mar 67", "Apr 67"];

    const data = {
        labels,
        datasets: [
            {
                label: "IPD",
                data: ["4727", "4131", "4151", "4350", "4069", "4400", "4374"],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };

    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                %ข้อมูลที่ผิดปกติภาพรวมรายแฟ้ม
                </Typography>
            </Grid> */}
            <Grid item xs={12}>
                <Line data={data} options={options} style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '500px', width: '100%' }}/>
            </Grid>
        </Grid>
    );
}
