import { Box, Grid, Typography } from "@mui/material";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import React from "react";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function HorizonChart({ information }) {

    // const options = {
    //     type:'horizontal',
    //     indexAxis: 'y',
    //     title: {
    //         display: true,
    //         text: "Chart.js Bar Chart - Stacked",
    //     },
    //     tooltips: {
    //         intersect: false,
    //         callbacks: {
    //             label: (c) => {
    //                 const value = Number(c.value);
    //                 const positiveOnly = value < 0 ? -value : value;
    //                 let retStr = "";
    //                 if (c.datasetIndex === 0) {
    //                     retStr += `Male: ${positiveOnly.toString()}`;
    //                 } else {
    //                     retStr += `Female: ${positiveOnly.toString()}`;
    //                 }
    //                 return retStr;
    //             },
    //         },
    //     },
    //     responsive: true,
    //     legend: {
    //         position: "bottom",
    //     },
    //     scales: {
    //         xAxes: [
    //             {
    //                 stacked: false,
    //                 ticks: {
    //                     beginAtZero: true,
    //                     callback: (v) => {
    //                         return v < 0 ? -v : v;
    //                     },
    //                 },
    //             },
    //         ],
    //         yAxes: [
    //             {
    //                 stacked: true,
    //                 ticks: {
    //                     beginAtZero: true,
    //                 },
    //                 position: "left",
    //             }
    //         ],
    //     },
    // };

    const options = {
        plugins: {
            datalabels: false,
            tooltip: {
                intersect: true,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || '';
                        var value = context.formattedValue;
                        var positiveOnly = value < 0 ? -value : value;
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += positiveOnly
                        }
                        return label;
                    },
                },
            },
            legend: {
                position: "top",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: false,
                ticks: {
                    beginAtZero: true,
                    callback: (v) => {
                        return v < 0 ? -v : v;
                    },
                },
            },
            y: {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
                position: "left",
            },
        },
        indexAxis: 'y'
    }

    const labels = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];

    const data = {
        labels,
        datasets: [
            {
                label: "Male",
                stack: "Stack 0",
                borderColor: 'rgb(55, 101, 176)',
                backgroundColor: 'rgba(55, 101, 176, 0.5)',
                data: [10, 20, 30, 40, 50, 60],
            },
            {
                label: "Female",
                stack: "Stack 0",
                borderColor: 'rgb(212, 17, 17)',
                backgroundColor: 'rgba(212, 17, 17, 0.5)',
                data: [10, 15, 30, 60, 50].map((k) => -k),
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} >
                <Box className="census-info-dashboard">
                    <Typography>
                        จำนวนประชากรจำแนกเพศ
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ minHeight: 300, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Bar data={data} options={options} />
            </Grid>
        </Grid>
    );
}
