
import React, { useEffect, useMemo, useState } from 'react';

import {
    Container, debounce,
} from "@mui/material";

import ListDevice from './ListDevice/ListDevice';
import { GET, GETDEVICE, POST, SEARCHDEVICE, UPDATEDEVICE } from '../../service';
import Swal from 'sweetalert2';
import BackdropLoad from '../../components/Backdrop/BackdropLoad';

function Device() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('')

    const GetAllDevice = async () => {
        try {
            setLoading(true);
            let res = await GET(GETDEVICE)
            if (res.success) {
                setData(res.result)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            setLoading(false)
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    }
    const handleUpdateDevice = async (device_id, user_id, status) => {
        try {
            setLoading(true);
            const payload = {
                id_device: device_id,
                data: {
                    user_id: user_id,
                    status: status
                }
            }
            let res = await POST(UPDATEDEVICE, payload)
            if (res.success) {
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
            window.location.reload();
        } catch (error) {
            setLoading(false)
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    // setLoading(true);
                    const payload = {
                        search: request
                    }
                    let res = await POST(SEARCHDEVICE, payload)
                    if (res.success) {
                        setData(res.result)
                        // setLoading(false)
                    } else {
                        // setLoading(false)
                        Swal.fire({
                            icon: 'warning',
                            text: `${res.message}`,
                            showConfirmButton: true,
                        })
                    }
                } catch (error) {
                    // setLoading(false)
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 400),
        [],
    );

    useEffect(() => {

        if (search.length < 3) {
            GetAllDevice()
            return undefined;
        }
        else if (!search) {
            GetAllDevice()
            return undefined;

        }

        fetch(search);

    }, [search, fetch]);

    // const searchDevice = async () => {
    //     try {
    //         // setLoading(true);
    //         const payload = {
    //             search: search
    //         }
    //         let res = await POST(SEARCHDEVICE, payload)
    //         if (res.success) {
    //             setData(res.result)
    //             // setLoading(false)
    //         } else {
    //             // setLoading(false)
    //             Swal.fire({
    //     icon: 'warning',
    //     text: `${res.message}`,
    //     showConfirmButton: true,
    // })
    //         }
    //     } catch (error) {
    //         // setLoading(false)
    //         Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
    //     }
    // }
    // useEffect(() => {
    //     if (search.length < 2)
    //         searchDevice()
    //     else if (!search)
    //         GetAllDevice()

    // }, [search]);

    // useEffect(() => {
    //     GetAllDevice()
    // }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListDevice
                data={data}
                search={search}
                setSearch={setSearch}
                handleUpdateDevice={handleUpdateDevice} />
        </Container>
    )
}

export default Device;