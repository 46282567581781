import React, { useState, useEffect } from 'react';
import { GET, POST, GETSCREEN, ADDROLE } from "../../service";
import Swal from "sweetalert2";
import {
    Container,
} from "@mui/material";
import BackdropLoad from '../../components/Backdrop/BackdropLoad';
import GridTextField from './components/GridTextField';

function AddUser() {
    const [dataScreen, setDataScreen] = useState([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');

    const getScreen = async () => {
        try {
            setLoading(true);
            let res = await GET(GETSCREEN)
            if (res.success) {
                let data = []
                res.result.map((e) =>
                    data.push({
                        ...e,
                        check: false,
                        status: 0,
                    })
                )
                setDataScreen(data)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            setLoading(false)
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };



    const handleAddUser = async () => {
        // setScreen(dataScreen.filter((e) => e.check === true));
        console.log(name, dataScreen);
        if (name) {
            try {
                setLoading(true)
                let res = await POST(ADDROLE, {
                    data_role: {
                        role_name: name
                    },
                    role_screen: dataScreen.map(({ check, screen_name, screen_path, type, ...rest }) => rest)
                });
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        icon: 'success',
                        text: `สร้างบทบาทสำเร็จ`,
                        confirmButtonText: 'ตกลง',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.replace("/Role");
                        }
                    })
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        text: `สร้างบทบาทไม่สำเร็จ`,
                        confirmButtonText: 'ตกลง',
                    })
                }
            } catch (error) {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    text: `${error}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } else {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `กรุณาตรวจสอบข้อมูลให้ถูกต้อง และครบถ้วน`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    const handleChangeCheckbox = (event) => {
        let id = event.target.value;

        if (event.target.name === "ทั้งหมด") {
            // let data = []
            dataScreen.map((e) =>
                setDataScreen(prevDataScreen => prevDataScreen.map(item =>
                    Number(item.screen_id) === Number(e.screen_id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
                ))
            )
            // setDataScreen(data)
        } else {
            setDataScreen(prevDataScreen => prevDataScreen.map(item =>
                Number(item.screen_id) === Number(id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
            ))
        }

    };

    useEffect(() => {
        // getRole();
        getScreen();
        // getLocation();
        // getHospital();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridTextField
                action="insert"
                dataScreen={dataScreen}
                handleAddUser={handleAddUser}
                handleChangeCheckbox={handleChangeCheckbox}
                setName={(e) => setName(e)}
                checkAll={dataScreen.findIndex(x => Number(x.status) === 0)}
            />
        </Container>
    )
}

export default AddUser;