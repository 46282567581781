import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import React from "react";

import { Statement } from "../../../untils/icons";

import { headerTable } from "../../../untils/static";
import GridTableList from "./GridTableList";
import { ButtonConfirm } from "../../../Theme.jsx";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

function StatementList(props) {
     return (
          <Box display="flex" justifyContent="center">
               <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                         <Grid item xs={12} className="flex-start">
                              <Stack direction="row" spacing={2}>
                                   <Statement fontSize="medium" />
                                   <Typography className="main-header">รายงานการเงิน</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={12} md={3} mt={3}>
                              <FormControl fullWidth>
                                   <InputLabel shrink>เลือกปีงบประมาณ</InputLabel>
                                   <Select
                                        name="year"
                                        value={props.year}
                                        label="เลือกปีงบประมาณ"
                                        required
                                        onChange={(e) => props.setYear(e.target.value)}
                                        displayEmpty
                                   >
                                        <MenuItem value="" disabled>
                                             เลือกปีงบประมาณ
                                        </MenuItem>
                                        {props.yearsData.map((el) => (
                                             <MenuItem key={el.value} value={el.value}>
                                                  {Number(el.value) + 543}
                                             </MenuItem>
                                        ))}
                                   </Select>
                              </FormControl>
                         </Grid>
                         <Grid item xs={12} sm={12} md={3} mt={3}>
                              <FormControl fullWidth style={{ height: "-webkit-fill-available" }}>
                                   <ButtonConfirm onClick={props.updateStatement} sx={{ ml: 5, height: "100%" }}>
                                        <CreditScoreIcon fontSize="small" />
                                        &nbsp;
                                        <Typography sx={{ cursor: "pointer" }}>อัปเดตการเงิน</Typography>
                                   </ButtonConfirm>
                              </FormControl>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} my={2}>
                              <GridTableList header={headerTable.statement_header} data={props.data} year={props.year} />
                         </Grid>
                    </Grid>
               </Paper>
          </Box>
     );
}

export default StatementList;
