import React, { useState, useEffect, useMemo } from 'react';
import {
    GET,
    POST,
    GETDRUGALL,
    // DELETEDRUG,
    SEARCH
} from "../../service";
import Swal from "sweetalert2";

import {
    Container, debounce,
} from "@mui/material";

import ListDrug from './components/ListDrug';
import { headerTable } from '../../untils/static';
import BackdropLoad from '../../components/Backdrop/BackdropLoad';

function Drug() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');

    const getGrug = async () => {
        try {
            setLoading(true)
            let res = await GET(GETDRUGALL)
            if (res.success) {
                setData(res.result)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    // const deleteUser = async (drug_id) => {
    //     try {
    //         let res = await POST(DELETEDRUG, { drug_id });
    //         if (res.success) {
    //             Swal.fire({
    //                 icon: 'success',
    //                 // text: `${res.result.message}`,
    //                 text: `ลบบัญชีผู้ใช้งานสำเร็จ`,
    //                 confirmButtonText: 'ตกลง',
    //             }).then((result) => {
    //                 if (result.isConfirmed) {
    //                     window.location.reload();
    //                 }
    //             })
    //         } else {
    //             Swal.fire({
    //                 icon: 'warning',
    //                 // text: `${res.message}`,
    //                 text: `ลบบัญชีผู้ใช้งานไม่สำเร็จ`,
    //                 confirmButtonText: 'ตกลง',
    //             })
    //         }
    //     } catch (error) {
    //         alert("error", error);
    //     }
    // };

    // const deleteAlert = (drug_id) => {
    //     Swal.fire({
    //         title: 'คุณต้องการลบหรือไม่?',
    //         text: "ถ้าลบแล้วคุณไม่สามารถกู้คืนรายการได้",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'ลบ',
    //         confirmButtonColor: "green",
    //         cancelButtonText: 'ไม่ลบ',
    //         reverseButtons: true
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             deleteUser(drug_id);
    //         } else if (
    //             result.dismiss === Swal.DismissReason.cancel
    //         ) {
    //             Swal.fire({
    //                 text: 'ยกเลิกรายการแล้ว',
    //                 confirmButtonText: 'ตกลง',
    //             })
    //         }
    //     })
    // }

    const searchDrug = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    // setLoading(true);
                    const payload = {
                        search: request
                    }
                    let res = await POST(SEARCH, payload)
                    if (res.success) {
                        setData(res.result)
                        // setLoading(false)
                    } else {
                        // setLoading(false)
                        Swal.fire({
                            icon: 'warning',
                            text: `${res.message}`,
                            showConfirmButton: true,
                        })
                    }
                } catch (error) {
                    // setLoading(false)
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500),
        [],
    );

    // const searchDrugType = useMemo(
    //     () =>
    //         debounce(async (request) => {
    //             try {
    //                 // setLoading(true);
    //                 const payload = {
    //                     search: request
    //                 }
    //                 let res = await POST(SEARCHDRUGTYPE, payload)
    //                 if (res.success) {
    //                     setData(res.result)
    //                     // setLoading(false)
    //                 } else {
    //                     // setLoading(false)
    //                     Swal.fire({
    //     icon: 'warning',
    //     text: `${res.message}`,
    //     showConfirmButton: true,
    // })
    //                 }
    //             } catch (error) {
    //                 // setLoading(false)
    //                 Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
    //             }
    //         }, 1500),
    //     [],
    // );

    useEffect(() => {
        if (search.length < 2) {
            getGrug()
            return;
        }
        else if (!search) {
            getGrug()
            return;
        }
        searchDrug(search);
    }, [search, searchDrug]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListDrug
                data={data}
                header_table={headerTable.drug_header}
                // deleteAlert={deleteAlert}
                search={search}
                setSearch={setSearch}
            />
        </Container>
    )
}

export default Drug;