import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
    ManageAccountsDrawerIcon,
    AddItemIcon,
} from '../../../untils/icons';

import {
    ButtonTeal,
} from '../../../Theme';

import GridTable from './GridTable';

function ManageRoleCard({ header_table, data, setData, deleteAlert }) {

    const navigate = useNavigate();

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ManageAccountsDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                จัดการบทบาท
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-end" >
                        <ButtonTeal onClick={handleRoute(`Add`)}>
                            <AddItemIcon fontSize='small' />&nbsp;
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                เพิ่มบทบาทผู้ใช้งาน
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5} className="flex-center" >
                        <GridTable header={header_table} data={data} setData={setData} deleteAlert={deleteAlert} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ManageRoleCard;