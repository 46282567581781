import React, { useState } from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@mui/material";

import {
    ButtonTeal,
} from '../../../../Theme'
import GridTableDetail from './GridTableDetail';
import { headerTable } from '../../../../untils/static';

function DetailDrug() {

    const [state, setState] = useState({
        location: '',
        status: '',
    });

    const handleChangeLocation = (event) => {
        setState({
            ...state,
            location: event.target.value,
        });
    };

    const handleChangeStatus = (event) => {
        setState({
            ...state,
            status: event.target.value,
        });
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack spacing={2}>
                            <Typography className="main-header">
                                รายละเอียดรายการ
                            </Typography>
                            <Typography fontWeight="bold">
                                ชื่อเวชภัณฑ์ : CEMOL CENTRAL 500 mg
                            </Typography>
                            <Typography >
                                จำนวนรวมเวชภัณฑ์ 0 รายการ
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-start" mt={3} >
                        <Grid container columns={12} spacing={2} className='flex-start' width={{ xs: "100%", sm: "80%", md: "50%" }}>
                            <Grid item xs={12} sm={2} md={2} display={{ xs: "none", sm: "block" }}>
                                <Typography >
                                    ค้นหา :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel >เลือกสถานพยาบาล</InputLabel>
                                    <Select
                                        id="lacation"
                                        value={state.location}
                                        label="เลือกสถานพยาบาล"
                                        onChange={handleChangeLocation}
                                    >
                                        {list_lacation.map((el, i) =>
                                            <MenuItem key={i + 1} value={el.value}>{el.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel >สถานะ</InputLabel>
                                    <Select
                                        id="status"
                                        value={state.status}
                                        label="สถานะ"
                                        onChange={handleChangeStatus}
                                    >
                                        {list_status.map((el, i) =>
                                            <MenuItem key={i + 1} value={el.value}>{el.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <ButtonTeal fullWidth>
                                    ค้นหา
                                </ButtonTeal>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5}  >
                        <GridTableDetail header={headerTable.report_drug_detail} data={rows} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default DetailDrug;

function createData(loca_id, location_name, amount, date, date_recive, status) {
    return { loca_id, location_name, amount, date, date_recive, status };
}

const rows = [
    createData(1, 'โรงพยาบาล A', 5, 'DD/MM/YYYY', 'DD/MM/YYYY', 'Pending'),
    createData(2, 'โรงพยาบาล B', 15, 'DD/MM/YYYY', 'DD/MM/YYYY', 'Success'),
];

const list_lacation = [
    {
        name: 'LacationA',
        label: 'Lacation A',
        value: 'LacationA'
    },
    {
        name: 'LacationB',
        label: 'Lacation B',
        value: 'LacationB'
    },
    {
        name: 'LacationC',
        label: 'Lacation C',
        value: 'LacationC'
    },
    {
        name: 'LacationD',
        label: 'Lacation D',
        value: 'LacationD'
    }
];

const list_status = [
    {
        name: 'pending',
        label: 'รอดำเนินการ',
        value: 'pending'
    },
    {
        name: 'success',
        label: 'เสร็จสิ้น',
        value: 'success'
    },
    {
        name: 'all',
        label: 'ทั้งหมด',
        value: 'all'
    },
];