import React, { useEffect, useState } from "react";

import { Grid, Box, Paper, Typography, Stack, MenuItem, Select, FormControl, InputLabel, TextField } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { ClaimIcon, SendIcn } from "../../../../untils/icons";

import { ButtonConfirm, ButtonTeal } from "../../../../Theme";
import GridTable from "./GridTable";
import dayjs from "dayjs";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

function ClaimCard({
     dateStart,
     dateEnd,
     setDateEnd,
     setDateStart,
     header,
     data,
     handleChangeCheckbox,
     checkAll,
     handleSendClaim,
     setStatus,
     status,
     search,
     setSearch,
     type,
     setType,
     dataType,
     statusSend,
     setStatusSend,
     dateUpdate,
})
{
     /* console.log(dateUpdate); */
     // require('dayjs/locale/th')
     const [cleared, setCleared] = useState(false);
     const len = data.filter((e) => e.status === 1);

     var buddhistEra = require("dayjs/plugin/buddhistEra");
     dayjs.extend(buddhistEra);
     require("dayjs/locale/th");

     useEffect(() => {
          if (cleared) {
               const timeout = setTimeout(() => {
                    setCleared(false);
               }, 1500);

               return () => clearTimeout(timeout);
          }
          return () => { };
     }, [cleared]);

     return (
          <Box display="flex" justifyContent="center">
               <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                         <Grid item xs={12} mt={1} mb={3} className="flex-start">
                              <Stack direction="row" spacing={2}>
                                   <ClaimIcon fontSize="medium" />
                                   <Typography
                                        className="main-header"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://eclaim.nhso.go.th/Client/login")}
                                   >
                                        ส่งออกข้อมูล 16 แฟ้ม
                                   </Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} className="flex-end">
                              <Typography >ข้อมูล ณ วันที่ {dayjs(dateUpdate).locale("th").format("D MMMM BBBB เวลา HH:mm น.")}</Typography> {/* use API in this line here */}
                         </Grid>
                         <Grid item xs={12} className="flex-end">
                              <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                              <ButtonConfirm onClick={() => handleSendClaim("authen")} sx={{ mr: 2 }}>
                                   <CreditScoreIcon fontSize="small" />
                                   &nbsp;
                                   <Typography sx={{ cursor: "pointer" }}>ตรวจสอบตัวตน</Typography>
                              </ButtonConfirm>
                              <ButtonTeal onClick={handleSendClaim}>
                                   <SendIcn fontSize="small" />
                                   &nbsp;
                                   <Typography sx={{ cursor: "pointer" }}>ส่งออกข้อมูล</Typography>
                              </ButtonTeal>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} my={2}>
                              <Grid container columns={12} spacing={3} className="flex-start">
                                   <Grid item xs={6} sm={6} md={3}>
                                        <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                             <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                                  <Typography mt={2} color="var(--teal-A800)">
                                                       จนถึง :
                                                  </Typography>
                                             </Box>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                                       <DatePicker
                                                            // fullWidth
                                                            value={dateStart}
                                                            slotProps={{
                                                                 field: { clearable: true, onClear: () => setCleared(true) },
                                                            }}
                                                            label="วันที่เริ่มต้น"
                                                            format="DD/MM/YYYY"
                                                            onChange={(e) => setDateStart(e)}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={6} sm={6} md={3}>
                                        <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                             <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                                  <Typography mt={2} color="var(--teal-A800)">
                                                       จนถึง :
                                                  </Typography>
                                             </Box>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                                       <DatePicker
                                                            fullWidth
                                                            value={dateEnd}
                                                            slotProps={{
                                                                 field: { clearable: true, onClear: () => setCleared(true) },
                                                            }}
                                                            label="วันที่สิ้นสุด"
                                                            format="DD/MM/YYYY"
                                                            onChange={(e) => setDateEnd(e)}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกประเภทเคลม</InputLabel>
                                             <Select
                                                  name="เลือกประเภทเคลม"
                                                  value={type}
                                                  label="เลือกประเภทเคลม"
                                                  // displayEmpty
                                                  required
                                                  onChange={(e) => setType(e.target.value)}
                                             >
                                                  <MenuItem value="" disabled>
                                                       เลือกประเภทเคลม
                                                  </MenuItem>
                                                  {dataType &&
                                                       dataType.map((el, i) => (
                                                            <MenuItem key={i + 1} value={el.id} disabled={el.disabled}>
                                                                 {el.name}
                                                            </MenuItem>
                                                       ))}
                                             </Select>
                                        </FormControl>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกสถานะ Pre Audit</InputLabel>
                                             <Select
                                                  name=""
                                                  value={status}
                                                  label="เลือกสถานะเคลม"
                                                  // displayEmpty
                                                  required
                                                  onChange={(e) => setStatus(e.target.value)}
                                             >
                                                  {dataPreAudit &&
                                                       dataPreAudit.map((el, i) => (
                                                            <MenuItem key={i + 1} value={el.value} disabled={el.disabled}>
                                                                 {el.label}
                                                            </MenuItem>
                                                       ))}
                                             </Select>
                                        </FormControl>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={2}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกสถานะการส่งเคลม</InputLabel>
                                             <Select
                                                  name="เลือกสถานะการส่งเคลม"
                                                  value={statusSend}
                                                  label="เลือกสถานะการส่งเคลม"
                                                  // displayEmpty
                                                  required
                                                  onChange={(e) => setStatusSend(e.target.value)}
                                             >
                                                  {dataStatusPreAudit &&
                                                       dataStatusPreAudit.map((el, i) => (
                                                            <MenuItem key={i + 1} value={el.value} disabled={el.disabled}>
                                                                 {el.label}
                                                            </MenuItem>
                                                       ))}
                                             </Select>
                                        </FormControl>
                                   </Grid>
                              </Grid>
                         </Grid>
                         <Grid item xs={12}>
                              <TextField
                                   fullWidth
                                   id="search"
                                   label="ค้นหารายการ"
                                   type="search"
                                   variant="outlined"
                                   size="medium"
                                   onChange={(e) => setSearch(e.target.value)}
                                   value={search}
                              />
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} mb={5}>
                              <GridTable
                                   header={header}
                                   data={data}
                                   handleChangeCheckbox={handleChangeCheckbox}
                                   checkAll={checkAll}
                                   statusSend={statusSend}
                                   type={type}
                              />
                         </Grid>
                         <Grid item xs={12} className="flex-end">
                              <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                              <ButtonTeal onClick={handleSendClaim}>
                                   <SendIcn fontSize="small" />
                                   &nbsp;
                                   <Typography sx={{ cursor: "pointer" }}>ส่งออกข้อมูล</Typography>
                              </ButtonTeal>
                         </Grid>
                    </Grid>
               </Paper>
          </Box>
     );
}

export default ClaimCard;

// const dataClaim = [
//      { label: "เลือกประเภทสิทธิ์", value: "", disabled: true },
//      { label: "สิทธ์องค์กรการปกครองส่วนท้องถิ่น (อปท.)", value: 1 },
//      { label: "สิทธิ์บัตรทอง", value: 2 },
// ];

const dataPreAudit = [
     { label: "เลือกสถานะเคลม", value: "", disabled: true },
     { label: "ทั้งหมด", value: 3 },
     { label: "ผ่าน", value: 1 },
     { label: "ไม่ผ่าน", value: 2 },
];

const dataStatusPreAudit = [
     { label: "เลือกสถานะการส่งเคลม", value: "", disabled: true },
     { label: "ทั้งหมด", value: 99 },
     { label: "รอส่ง", value: 0 },
     // { label: "ส่งสำเร็จ", value: 1 },
     { label: "กำลังตรวจสอบจาก สปสช.", value: 1 },
     { label: "ผ่านการตรวจสอบจาก สปสช.", value: 2 },
     { label: "ไม่ผ่านการตรวจสอบจาก สปสช.", value: 3 },
];
