// const localhost = "http://192.168.31.70:3333";
// const localhost = "http://103.138.179.148";
const localhost = process.env.REACT_APP_SERVICE;
// const localhost = "https://nh.projectsoft.co.th";

const path = localhost;
// const path = window.location.origin.includes("localhost") ? localhost : window.location.origin;
export const _ip = path;
export const ip = _ip + "/api";

export const GET = (path) => {
     const token = localStorage.getItem("uuid");
     return new Promise((resolve, reject) => {
          fetch(ip + path, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    Authorization: token ? token : "token",
               },
               credentials: "include",
          })
               .then((response) => response.json())
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

export const POST = (path, obj, formData) => {
     const token = localStorage.getItem("uuid");

     // let lang = "th";
     // console.log("path", path);
     // console.log("ip", ip);
     return new Promise((resolve, reject) => {
          fetch(ip + path, {
               method: "POST",
               headers: formData
                    ? {}
                    : {
                           "Content-Type": "application/json",
                           Authorization: token ? token : "token",
                      },
               body: formData ? obj : JSON.stringify({ ...obj }),
               credentials: "include",
          })
               .then((response) => response.json())
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

//LOGIN
export const LOGIN = "/auth/login";
export const LOGOUT = "/auth/logout";

export const VERIFY = "/line/verify_identity";

//USER
export const GETUSERALL = "/user/get_user_all";
export const GETUSERBYID = "/user/get_user_id";
export const DELETEUSER = "/user/delete_user";
export const ADDUSER = "/user/create_user";
export const UPDATEUSER = "/user/update_user";
export const UPDATESTATUSUSER = "/user/update_user_by";
export const GETROLE = "/user/get_role_all";
export const GETLOCATION = "/user/get_location_all";
export const GETHOSPITAL = "/user/get_hospital_all";
export const GETVENDORALL = "/user/get_vendor_all";
export const GETSCREEN = "/user/get_screen_all";
export const SEARCHHOSPITAL = "/user/search_hospital";
export const SEARCHLOCATION = "/user/search_location";
export const GETDASHBOARD = "/user/get_dashboard";
export const GETDASHBOARDBYID = "/user/get_dashboard_by";
export const SEARCHUSER = "/user/search_user";

//ROLE
export const GETMANAGEROLE = "/role/get_role";
export const GETROLEBYID = "/role/get_role_id";
export const ADDROLE = "/role/create_role";
export const UPDATEROLE = "/role/update_role";
export const DELETEROLE = "/role/update_data_role";

//GRUG
export const GETDRUGALL = "/drug/get_drug_all";
export const DELETEDRUG = "/drug/delete_drug";
export const ADDDRUG = "/drug/create_drug";
export const UPDATEDRUG = "/drug/update_drug";
export const GETDRUGBYID = "/drug/get_drug_id";
export const SEARCH = "/drug/search_drug";
export const SEARCHDRUGTYPE = "/drug/search_drug_type";
export const GETDRUGSTOCK = "/drug/get_cdrug_all";
export const GETSEARCHDRUGSTOCK = "/drug/search_cdrug";

//TRANSACTION
export const ADDTRANSACTION = "/transaction/create_transaction";
export const GETTRANSACTIONALL = "/transaction/get_order_all";
export const GETTRANSACTIONBYID = "/transaction/get_order_by";
export const APPROVETRANSITION = "/transaction/approve_order_by";

//DEVICE
export const GETDEVICE = "/device/get_device_all";
export const UPDATEDEVICE = "/device/update_device";
export const SEARCHDEVICE = "/device/search_device";

//Stock
export const GETSTOCK = "/stock/get_stock_all";
export const SEARCHSTOCK = "/stock/search_stock";

//Claim
export const GETCLAIM = "/opd/get_opd_all";
export const GETCLAIMDETAIL = "/opd/get_opd_by";
export const GETCLAIMVACCINE = "/opd/get_vin_all";
export const SENDCLAIM1 = "/claim/eclaim_option_1";
export const SENDCLAIM2 = "/claim/eclaim_option_2";
export const SENDCLAIMVACCINE = "/claim/m_claim_by";
export const GETTOKENECLAIM = "/token/get_token_eclaim";
export const GETTOKENMCLAIM = "/token/get_token_mclaim";
export const UPDATELOCATIONBY = "/user/update_location_by";
export const GETLOCATIONBY = "/user/get_location_by";
export const GETCLAIMTYPE = "/claim/get_claim_type";
export const GETBIRTHCONTROL = "/opd/get_birth_control_all";
export const GETBIRTHCONTROLBY = "/opd/get_birth_control_by";
export const ADDBIRTHCONTROL = "/opd/create_birth_control";
export const UPDATEBIRTHCONTROL = "/opd/update_birth_control";
export const GETHERB = "/opd/get_herb_all";
export const GETHERBBY = "/opd/get_herb_by";
export const ADDHERB = "/opd/create_herb";
export const UPDATEHERB = "/opd/update_herb";
export const GETHERBMASTER = "/opd/get_herb_master";
export const GETBIRTHCONTROLMASTER = "/opd/get_birth_master";
export const GETPREGNANT = "/opd/get_pregnant_all";
export const GETPREGNANTBY = "/opd/get_pregnant_by";
export const ADDPREGNANT = "/opd/create_pregnant";
export const GETPREGNANTMASTER = "/opd/get_pregnant_master";
export const UPDATEPREGNANT = "/opd/update_pregnant";
export const LASTDTUPDATE = "/opd/get_last_dt_update"

export const DELETEBIRTHCONTROL = "";
export const DELETEHERB = "/drug/delete_herb";
export const DELETECONTRACEPTIVE = "/drug/delete_contraceptive_pill";
export const DELETEPREGNANT = "/drug/delete_pregnant_drug";

//Dashboard
export const GETDASHBOARDDATA = "/dashboard/dashboard16";
export const GETTAMBON = "/dashboard/tambon";
export const GETOPD = "/dashboard/opd";
export const GETSTATEMENT = "/claim/get_statement_eclaim";
export const GETCHARTSTATEMENT = "/claim/get_chart_eclaim";
export const DASHBOARDPAYMENT = "/dashboard/payment";

//ManageUrl
export const MANAGEURL = "/pageurl/screen";
export const ADD_MANAGEURL = "/pageurl/add_screen";
export const UPDATE_MANAGEURL = "/pageurl/update_screen";

//ManageClaim
export const GETMANAGECLAIM = "/manageclaim/get_claim_type";
export const EDITMANAGECLAIM = "/manageclaim/edit_claim_type";
export const EDITSORTMANAGECLAIM = "/manageclaim/edit_sort_claim_type";

//OTP
export const OTPCONFIG = "/otp/config";
export const OTPSAVE = "/otp/save";
export const GETOTP = "/otp/get_otp";
export const OTPMASTER = "/otp/";
export const OTPVERIFY = "/otp/verify";
export const RESENDOTP = "/otp/resend";

//ReportDeath
export const REPORTDEATH = "/dashboard/death_report";
export const GETMAPREPORTDEATH = "/dashboard/death_report_map";

//ReportDiagnose
export const REPORTDIAGNOSE = "/dashboard/diagnose_report";
export const REPORTDIAGNOSEMAP = "/dashboard/diagnose_report_map";