
import React, { useState } from 'react';

import {
    Container,
} from "@mui/material";

import ClaimCard from './components/ClaimCard';

import { headerTable } from '../../../untils/static';
import dayjs from 'dayjs';
// import Swal from 'sweetalert2';
// import BackdropLoad from '../../components/Backdrop/BackdropLoad';

function Claim43() {
    // const [loading, setLoading] = useState(false);
    const [data, setData] = useState([
        { check: false, status: 0, claim_id: 1, cid: "xxxxxxxxx1", pid: "xxx-xxxx", prefix: "นางสาว", name: "รัศมี", lastname: "กระจ่างจันทร์", age: 24, date: "2023-12-25T09:20:13.000Z", diagnose: "ขณะที่ปวดศีรษะมักมีอาการคลื่นไส้ หรืออาเจียนร่วมด้วย มักปวดตุบๆ เป็นระยะๆ แต่มีบางคราวที่ปวดแบบตื้อๆ", service: 2500 },
        { check: false, status: 0, claim_id: 2, cid: "xxxxxxxxx2", pid: "xxx-xxxx", prefix: "นาย", name: "สว่าง", lastname: "จันนท์ส่องแสง", age: 34, date: "2023-12-25T09:20:13.000Z", diagnose: "-", service: 3500 }
    ]);

    const [dateStart, setDateStart] = useState(dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));

    // const location = useLocation()
    // const { detail } = location.state;

    const handleChangeCheckbox = (event) => {

        let id = event.target.value;

        if (event.target.name === "ทั้งหมด") {
            // let data = []
            data.map((e) =>
                setData(prevData => prevData.map(item =>
                    Number(item.claim_id) === Number(e.claim_id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
                ))
            )
            // setData(data)
        } else {
            setData(prevData => prevData.map(item =>
                Number(item.claim_id) === Number(id) ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 } : item
            ))
        }

    };


    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            {/* <BackdropLoad loading={loading} /> */}
            <ClaimCard
                header={headerTable.claim}
                data={data}
                handleChangeCheckbox={handleChangeCheckbox}
                checkAll={data.findIndex(x => x.status === 0)}
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
            // detail={detail}
            />
        </Container>
    )
}

export default Claim43;