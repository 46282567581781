import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, Grid, Typography } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BarChartDashboard({ information }) {

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: false,
            legend: {
                display: 'top'
            },
            // title: {
            //     display: true,
            //     text: 'Chart.js Horizontal Bar Chart',
            // },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    pinch: {
                        enabled: true
                    },
                    wheel: {
                        enabled: true
                    },
                    mode: 'xy',  // Enable both x and y zooming
                }
            }
        },

    };

    const labels = information.map((e) => e.hname !== "" ? e.hname.replace("โรงพยาบาลส่งเสริมสุขภาพตำบล", 'รพ. สต.') : "ไม่ระบุ");

    const data = {
        labels,
        datasets: [
            {
                label: 'จำนวนครั้ง',
                data: information.map((e) => e.count),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} >
                <Box className="census-info">
                    <Typography>
                        จำนวนการเข้ารับบริการแยกตามหน่วยบริการ (ครั้ง)
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} className="flex-center" style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px', width: '100%' }}>
                {information.length > 0 ?
                    <Bar data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                }
            </Grid>
        </Grid>
    );
}
