import React from 'react'
import CustomDrawer from './CustomDrawer';
import { Outlet } from 'react-router-dom';
import Guard from '../components/Gurad/Guard';
function LayoutMain() {

  return (
    <Guard>
      <CustomDrawer children={<Outlet />} />
    </Guard>
  );
}

export default LayoutMain;
