import React, { useState } from 'react'
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    Chip,
} from "@mui/material";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../Theme"

import {
    EditDataIcon,
    DeleteIcon,
} from '../../../untils/icons'
import { formatPhone } from '../../../untils/shortcut';
import { roleStatus } from '../../../untils/static';

export default function GridTable({ header, data, deleteAlert, dataRole }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path, detail) => {
        return () => {
            navigate(path, { state: { detail: detail } });
        };
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column, i) => (
                                <StyledTableCell
                                    key={i + 1}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <Typography fontWeight='bold' fontSize='14px'>{column.label}</Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i + 1}>
                                        <StyledTableCell align="center">{moment(row.date_created).format('L')}</StyledTableCell>
                                        <StyledTableCell align="left">{row.name} {row.lastname}</StyledTableCell>
                                        <StyledTableCell align="left">{dataRole.map((e) => e.role_id === row.role_id && e.role_name)}</StyledTableCell>
                                        <StyledTableCell align="center">{formatPhone(row.phone)}</StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.location_name ? row.location_name : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Chip sx={{ width: '70px', background: `${roleStatus[row.status].color}`, color: "var(--white)" }} label={roleStatus[row.status].label} />
                                        </StyledTableCell>

                                        {row.status === 0 ?
                                            <>
                                                <StyledTableCell align="center">
                                                    <EditDataIcon />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DeleteIcon />
                                                </StyledTableCell>
                                            </>
                                            :
                                            <>
                                                <StyledTableCell align="center">
                                                    <EditDataIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Edit`, row)} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteAlert(row.user_id)} />
                                                </StyledTableCell>
                                            </>

                                        }

                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}
