import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import React, { useRef } from 'react';

import { ButtonTeal } from '../../../Theme';
import { CloseIcn } from '../../../untils/icons';

export default function DialogEdit({
    open,
    handleClose,
    name,
    setName,
    handleUpdate,
}) {
    
    const formRef = useRef();

    const checkValidation = () => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleUpdate();
        }
        // console.log(formRef.current.reportValidity());
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            className="bg-dialog"
        >
            <Box className='bg-gradiant'>
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">
                    แก้ไขประเภทการเคลม
                </DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center form-dialog">
                        <DialogContentText mt={2} className='container-bg' component={'span'}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ประเภทการเคลม"
                                        name="code"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form >
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={() => {
                            checkValidation();
                        }}
                    >
                        แก้ไขข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
