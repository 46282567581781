import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography, FormControl,
    TextField,
    Stack,
    Container,
    FormControlLabel, FormGroup,
    Checkbox
} from "@mui/material";

import {
    ManageAccountsDrawerIcon,
} from '../../../untils/icons';

import {
    ButtonTeal,
} from '../../../Theme';

function GridTextField({
    action,
    dataScreen,
    handleAddUser,
    handleUpdateUser,
    handleChangeCheckbox,
    name,
    setName,
    checkAll,
}) {

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <ManageAccountsDrawerIcon fontSize='medium' />
                                <Typography className="main-header" >
                                    {action === "insert" ? "สร้างบทบาทผู้ใช้งาน" : "แก้ไขบทบาทผู้ใช้งาน"}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
            <Container maxWidth="sm" >
                <Grid container columns={12} >
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ชื่อ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ชื่อ"
                                name="name"
                                placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12} sm={4} md={4} className="flex-start" >
                            <Typography>เลือกแถบแสดงเมนู :</Typography>
                        </Grid>
                        <Grid container columns={12} ml={5} my={2} spacing={1}>
                            <Grid item xs={12} sm={12} md={12} >
                                <FormControlLabel
                                    label="ทั้งหมด"
                                    control={
                                        <Checkbox
                                            checked={checkAll === -1 ? true : false}
                                            name="ทั้งหมด"
                                            onChange={(e) => handleChangeCheckbox(e)}
                                        />
                                    }
                                />
                            </Grid>
                            {type_screen.map((element) =>
                                <>
                                    <Grid item xs={12} mt={1} ml={"-5%"}>
                                        <Typography >●&nbsp;&nbsp;{element.label}</Typography>
                                    </Grid>
                                    {dataScreen.filter((e) => e.type === element.value).map((ele, index) =>
                                        <Grid item xs={12} sm={6} md={6} key={index + 1}>
                                            <FormControl component="fieldset" variant="standard">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        key={index + 1}
                                                        control={
                                                            <Checkbox onChange={(e) => handleChangeCheckbox(e)} checked={ele.check} value={ele.screen_id} name={ele.screen_name} />
                                                        }
                                                        label={ele.screen_name}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item my={5} xs={12}>
                        {action === "insert" ?
                            <ButtonTeal size='large' fullWidth onClick={handleAddUser}>
                                บันทึกข้อมูล
                            </ButtonTeal> :
                            <ButtonTeal size='large' fullWidth onClick={handleUpdateUser}>
                                แก้ไขข้อมูล
                            </ButtonTeal>
                        }
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}

const type_screen = [
    { label: "หน้าหลัก", value: 0 },
    { label: "ระบบคลังยา", value: 1 },
    { label: "ระบบสนันสนุนการเคลม", value: 2 },
    { label: "ระบบ Dashboard", value: 3 },
]

export default GridTextField;


