import React, { useState } from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../../Theme"
import { ActionIcon } from '../../../../untils/icons';

export default function GridTableDetail({ header, data }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="left">{row.order}</StyledTableCell>
                                        <StyledTableCell align="right">{row.amount}</StyledTableCell>
                                        <StyledTableCell align="center">{row.date}</StyledTableCell>
                                        <StyledTableCell align="center">{row.date_recive}</StyledTableCell>
                                        <StyledTableCell align="center">{row.status}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <ActionIcon fontSize="large" sx={{ cursor: "pointer" }} onClick={handleRoute(`order/${row.order_id}`)} />
                                        </StyledTableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
            />
        </Paper>
    );
}

