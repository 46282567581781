import React, { useState } from "react";
import {
     Typography,
     Paper,
     Table,
     TableBody,
     TableContainer,
     TableHead,
     TablePagination,
     TableRow,
     Grid,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../../Theme";
import { MoreIcon } from "../../../../untils/icons";

export default function GridTable({ header, data }) {
     const [page, setPage] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10);
     const navigate = useNavigate();

     const handleChangePage = (event, newPage) => {
          setPage(newPage);
     };

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     const handleRoute = (path, detail) => {
          // console.log('dateStart', dateStart,dateEnd)
          return () => {
               navigate(path, { state: { ...detail } });
          };
     };

     return (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                         <TableHead>
                              <TableRow>
                                   {header.map((column, i) => (
                                        <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                             <Typography fontWeight="bold" fontSize="14px">
                                                  {column.label}
                                             </Typography>
                                        </StyledTableCell>
                                   ))}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                   return (
                                        <TableRow key={i + 1}>
                                             <StyledTableCell align="left">{row.HN ? row.HN : "N/A"}</StyledTableCell>
                                             <StyledTableCell align="left">{row.TITLE}</StyledTableCell>
                                             <StyledTableCell align="left">
                                                  {row.NAMEPAT ? row.NAMEPAT.replace(`,${row.TITLE}`, "") : "N/A"}
                                             </StyledTableCell>
                                             <StyledTableCell align="right">
                                                  {Number(moment().year()) - Number(moment(row.DOB).year())}
                                             </StyledTableCell>
                                             <StyledTableCell align="center">
                                                  {row.DATEOPD ? moment(row.DATEOPD).format("DD/MM/YYYY") : "N/A"}
                                             </StyledTableCell>
                                             <StyledTableCell align="center">{row.SEQ ? row.SEQ : "N/A"}</StyledTableCell>
                                             <StyledTableCell align="center">
                                                  {row.PERMITNO ? <MoreIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Detail`, row)} /> : null}
                                             </StyledTableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               {data.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                         <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
               ) : (
                    <TablePagination
                         rowsPerPageOptions={[10, 25, 100]}
                         component="div"
                         count={data.length}
                         rowsPerPage={rowsPerPage}
                         page={page}
                         onPageChange={handleChangePage}
                         onRowsPerPageChange={handleChangeRowsPerPage}
                         labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
               )}
          </Paper>
     );
}
