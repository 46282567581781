
import React from 'react'

import {
    Container,
} from "@mui/material";

import ListReportDrug from './components/ListReportDrug';

function ReportDrug() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <ListReportDrug />
        </Container>
    )
}

export default ReportDrug;