import React, { useState } from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
} from "@mui/material";

import {
    ButtonTeal,
} from '../../../../Theme'
import GridTableDetail from './GridTableDetail';
import { headerTable } from '../../../../untils/static';

function DetailList() {

    const [state, setState] = useState({
        order: '',
        status: '',
    });

    const handleChangeStatus = (event) => {
        setState({
            ...state,
            status: event.target.value,
        });
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack spacing={2}>
                            <Typography className="main-header">
                                รายละเอียดรายการ
                            </Typography>
                            <Typography fontWeight="bold">
                                ชื่อสถานพยาบาล : โรงพยาบาล 000-1
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-start" mt={3} >
                        <Grid container columns={12} spacing={2} className='flex-start' width={{ xs: "100%", sm: "80%", md: "50%" }}>
                            <Grid item xs={12} sm={2} md={2} display={{ xs: "none", sm: "block" }}>
                                <Typography >
                                    ค้นหา :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <TextField
                                    fullWidth
                                    id="order"
                                    label="ค้นหาเลขคำสั่ง"
                                    type="search"
                                    variant="outlined"
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel >สถานะ</InputLabel>
                                    <Select
                                        id="status"
                                        value={state.status}
                                        label="สถานะ"
                                        onChange={handleChangeStatus}
                                    >
                                        {list_status.map((el, i) =>
                                            <MenuItem key={i + 1} value={el.value}>{el.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <ButtonTeal fullWidth>
                                    ค้นหา
                                </ButtonTeal>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5}  >
                        <GridTableDetail header={headerTable.report_location_detail} data={rows} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default DetailList;

function createData(order_id, order, amount, date, date_recive, status) {
    return { order_id, order, amount, date, date_recive, status };
}

const rows = [
    createData(1, '00-00000', 5, 'DD/MM/YYYY', '-', 'Pending'),
    createData(2, '00-00001', 5, 'DD/MM/YYYY', 'DD/MM/YYYY', 'Success'),
];

const list_status = [
    {
        name: 'pending',
        label: 'รอดำเนินการ',
        value: 'pending'
    },
    {
        name: 'success',
        label: 'เสร็จสิ้น',
        value: 'success'
    },
    {
        name: 'all',
        label: 'ทั้งหมด',
        value: 'all'
    },
];