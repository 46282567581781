import React, { useEffect, useState } from 'react';

import {
    Container,
} from "@mui/material";

import Swal from 'sweetalert2';
import { EDITMANAGECLAIM, EDITSORTMANAGECLAIM, GET, GETMANAGECLAIM, POST } from '../../service';
import DialogEdit from './components/DialogEdit';
import ManageClaimCard from './components/ManageClaimCard';

function ManageClaim() {

    const [openEdit, setOpenEdit] = useState(false);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState('');
    const [data, setData] = useState([]);

    const getManageClaim = async () => {
        try {
            setLoading(true);
            let res = await GET(GETMANAGECLAIM);
            if (res.success) {
                // console.log(res.result);
                setData(res.result);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const updateManageClaim = async (status_id, status) => {

        let obj = data.filter((e) => e.id === id);

        const payload = {
            name,
            status: status ? Number(status) : Number(obj[0].status)
        };

        try {
            let res = await POST(EDITMANAGECLAIM, { id: id ? id : status_id, data: payload });
            if (res.success) {
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        getManageClaim();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const updateSortManageClaim = async () => {

        try {
            let res = await POST(EDITSORTMANAGECLAIM, { data });
            if (res.success) {
                Swal.fire({
                    icon: "success",
                    text: `บันทึกข้อมูลลำดับสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        getManageClaim();
                    }
                });
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangeSwitch = (event) => {

        let id = event.target.id;
        let check = event.target.checked;

        setData(prevData => prevData.map(item =>
            Number(item.id) === Number(id) ? { ...item, status: check } : item
        ))

        updateManageClaim(id, check);

    };

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(data);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        handleNewSort(tempData);
    };

    const handleNewSort = (data) => {

        let newObj = [];

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            element['sort'] = index + 1;
            newObj.push(element);
        }

        setData(newObj)
    };

    const handleClickOpenEdit = (data) => {
        setId(data.id);
        setName(data.name);
        setOpenEdit(true);
    };

    useEffect(() => {
        getManageClaim();
    }, [])

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <ManageClaimCard
                data={data}
                handleChangeSwitch={handleChangeSwitch}
                handleDragEnd={handleDragEnd}
                handleClickOpenEdit={handleClickOpenEdit}
                updateSortManageClaim={updateSortManageClaim}
            />
            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                setName={setName}
                name={name}
                handleUpdate={updateManageClaim}
            />
        </Container>
    )
}

export default ManageClaim;