import { Grid } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { fixAmount } from "../../../untils/shortcut";
const latlong =
     window.location.origin.includes("kk")
          ? [16.43192755724492, 102.82856609781523]
          : window.location.origin.includes("mdh")
               ? [16.59738946623057, 104.51104253757008]
               : window.location.origin.includes("spb")
                    ? [14.651579065119135, 99.93989999520554]
                    : [16.43192755724492, 102.82856609781523]
function LeafletMapComponent({
     subdistrictObj,
}) {

     const [map, setMap] = useState(null);

     const popupFeature = (e) => {
          let layer = e.target;
          const { count_diag, tam_th } = e.target.feature.properties;

          if (count_diag > 0) {
               const popupOptions = {
                    minWidth: 100,
                    maxWidth: 250,
                    className: "popup-classname",
               };

               layer.bindTooltip(() => {
                    return `<b>${tam_th} <br> ${fixAmount(count_diag)}</b>`;
               }, popupOptions).openTooltip();
          }
     };

     const onEachFeature = (feature, layer) => {
          layer.on({
               mouseover: popupFeature,
          });
     };

     const mapPolygonColorToDensity = (d) => {
          return d > 10000
               ? "#800026"
               : d > 5000
                    ? "#BD0026"
                    : d > 2000
                         ? "#E31A1C"
                         : d > 1000
                              ? "#FC4E2A"
                              : d > 500
                                   ? "#FD8D3C"
                                   : d > 200
                                        ? "#FEB24C"
                                        : d > 100
                                             ? "#FED976"
                                             : d >= 1
                                                  ? "#FFEDA0"
                                                  : "#000";
     };
     const style = (feature) => {
          return {
               fillColor: mapPolygonColorToDensity(feature.properties.count_diag),
               weight: 1,
               opacity: 1,
               color: "white",
               dashArray: "2",
               fillOpacity: 0.5,
          };
     };

     return (
          <>
               <Grid container columns={12}>
                    <Grid item xs={12}>
                         <MapContainer
                              scrollWheelZoom={true}
                              center={latlong}
                              zoom={8}
                              className="mapContainer-xs-6"
                              ref={setMap}
                         >
                              <TileLayer
                                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                   maxZoom={19}
                                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              />
                              {subdistrictObj.length > 0 && (
                                   <GeoJSON
                                        data={subdistrictObj}
                                        style={style}
                                        onEachFeature={onEachFeature}
                                   />
                              )}
                         </MapContainer>
                    </Grid>
               </Grid>
          </>
     );
}

export default LeafletMapComponent;