import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";

import {
    ButtonTeal,
} from '../../../../Theme'

import {
    ListIcon,
} from '../../../../untils/icons';

import GridTableList from './GridTableList';
import GridTextField from '../../../../components/GridTextField';

function ListReportLocation(props) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ListIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายการขอเบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-start" mt={3} >
                        <Grid container columns={12} spacing={2} className='flex-start' width={{ xs: "100%", sm: "80%", md: "50%" }}>
                            <Grid item xs={12} sm={2} md={2} mr={-4} display={{ xs: "none", sm: "block" }}>
                                <Typography >
                                    ค้นหา :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} md={4}>
                                <GridTextField
                                    fullWidth
                                    data={props.select}
                                    handleChange={props.handleChange}
                                    state={props.state}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <ButtonTeal fullWidth>
                                    ค้นหา
                                </ButtonTeal>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5} className="flex-center" >
                        <GridTableList header={props.header} data={props.data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListReportLocation;

