import React, { useState } from 'react';
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    TextField,
} from "@mui/material";
import { StyledTableCell } from "../../../Theme";

import {
    Del,
} from '../../../untils/icons';

export default function GridTableAdd({ data, setData, header }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column, i) => (
                                <StyledTableCell
                                    key={i + 1}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <Typography fontWeight='bold' fontSize='14px'>{column.label}</Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i + 1}>
                                        <StyledTableCell align="left">{row.drug_name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.drug_type}</StyledTableCell>
                                        <StyledTableCell align="left">{row.unit}</StyledTableCell>
                                        <StyledTableCell align="right">{row.remain}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <TextField
                                                id="standard-basic"
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                size="small"
                                                defaultValue={row.amount}
                                                onChange={(val) => {
                                                    // console.log({ val, rval: val.target.value });
                                                    setData(prevData => prevData.map(item =>
                                                        item.drug_id === row.drug_id ? { ...item, amount: val.target.value } : item
                                                    ))
                                                }}
                                                type='number'
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{Number(row.remain) - Number(row.amount)}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {/* <Del sx={{ cursor: "pointer" }} onClick={() => handleDelete(row.drug_id)} /> */}
                                            <Del sx={{ cursor: "pointer" }} onClick={() => {
                                                setData(prevData => prevData.filter(item =>
                                                    item.drug_id !== row.drug_id
                                                ))
                                            }} />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}
