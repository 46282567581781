import { Grid } from "@mui/material";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartVertical({ information }) {
     const options = {
          indexAxis: "x",
          elements: {
               bar: {
                    borderWidth: 2,
               },
          },
          responsive: true,
          plugins: {
               datalabels: false,
          },
          legend: {
               display: false,
          },
     };

     const labels = ["ต.ค.", "พ.ย.", "ธ.ค.", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย."];

     const data = {
          labels,
          datasets: [
               {
                    label: "จำนวนเงิน",
                    // data: information.data,
                    data: [10000000, 10000000, 10000, 1000, 10000000, 10000001, 100],
                    borderColor: "rgb(0, 140, 238)",
                    backgroundColor: "rgba(0, 140, 238, 0.5)",
               },
          ],
     };
     return (
          <Grid container columns={12}>
               <Grid item xs={12} className="census-info-chart-h">
                    {/* {information.length > 0 ?
                    // <Bar data={data} options={options} />
                    <Bar data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                } */}
                    <Bar data={data} options={options} />
               </Grid>
          </Grid>
     );
}
