import React from 'react';

import {
    Container,
} from "@mui/material";
import { headerTable } from '../../../untils/static';
import GridDetail from './components/GridDetail';
import { useLocation } from 'react-router-dom';

function DetailClaim43() {

    // const [data, setData] = useState([])
    const location = useLocation()
    const { detail } = location.state;

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <GridDetail
                header={headerTable.claim_detail}
                data={data}
                detail={detail}
            />
        </Container>
    );

}

const data = [
    { number: 1, detail_service: "ตรวจโรคทั่วไป", service: 500 },
    { number: 2, detail_service: "ตรวจเฉพาะทางด้านระบบประสาท", service: 1500 },
    { number: 3, detail_service: "ibuprofen", service: 250 },
    { number: 4, detail_service: "non-steroidal antiinflammatory drugs (NSAIDs)", service: 250 },
]

export default DetailClaim43;
