import { Box, Grid, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart({ information }) {

    const options = {
        plugins: {
            // legend: 'left',
            outlabels: {
                text: '%l %p',
                color: 'white',
                stretch: 35,
                font: {
                    resizable: true,
                    minSize: 12,
                    maxSize: 18,
                },
            },
        },
    };

    const data = {
        labels: information.map((e) => e.sub_funds),
        datasets: [
            {
                label: 'จำนวนเงิน',
                data: information.map((e) => e.sum),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} >
                <Box className="census-info">
                    <Typography>
                        รายงานการโอนเงินแยกตามกองทุนย่อย
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} className="flex-center" style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px', width: '100%' }}>
                {information.length > 0 ?
                    <Doughnut data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                }
            </Grid>
        </Grid>
    )
}


