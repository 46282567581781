import React, { useState } from 'react';
import {
    Container,
} from "@mui/material";
import GridAdd from './components/GridAdd';
import { EditorState } from 'draft-js';

function AddNews() {
    // const [data, setData] = useState([]);
    const data = [];
    const [selectedImage, setSelectedImage] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [file, setFile] = useState({})

    const uploadImage = async (e) => {
        // const reader = new FileReader();
        // reader.onload = () => {
        //     if (reader.readyState === 2) {
        //         selectedImage.profile_image = reader.result;
        //         setSelectedImage(selectedImage);
        //     }
        // };
        // reader.readAsDataURL(e.target.files[0]);
        // setFile(e.target.files[0]);

        setSelectedImage(URL.createObjectURL(e.target.files[0]));

    };

    const handleChangeEditor = (editorState) => {
        setEditorState(editorState)
        //JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    };

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <GridAdd
                data={data}
                selectedImage={selectedImage}
                uploadImage={uploadImage}
                handleChangeEditor={handleChangeEditor}
                editorState={editorState}
            />
        </Container>
    );

}

export default AddNews;