import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField,
} from "@mui/material";

import {
    HomeDrawerIcon,
} from '../../../untils/icons';

import { headerTable } from '../../../untils/static';
import GridTableList from './GridTableList';

function ListStock({
    data,
    handleChangePage,
    handleChangeRowsPerPage,
    count,
    rowsPerPage,
    page,
    setSearch,
    search,
}) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <HomeDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายการเวชภัณฑ์คงคลัง
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size='medium'
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList
                            header={headerTable.stock_header}
                            data={data}
                            setSearch={setSearch}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListStock;
