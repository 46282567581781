import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BarChartVertical({ information }) {
     //  console.log("information", information);
     const options = {
          indexAxis: "x",
          elements: {
               bar: {
                    borderWidth: 2,
               },
          },
          responsive: true,
          // plugins: [ChartDataLabels],
          plugins: {
               // datalabels: {
               //     display: function (context) {
               //         return context.chart.isDatasetVisible(context.datasetIndex);
               //     },
               //     // backgroundColor: function (context) {
               //     //     return context.dataset.backgroundColor;
               //     // },
               //     borderRadius: 4,
               //     // font: {
               //     //     weight: 'bold'
               //     // },
               //     align: "end",
               //     anchor: "end",
               //     color: "black",
               //     backgroundColor: "rgb(248,247,247,0.5)",
               // }
               datalabels: false,
          },
          legend: {
               display: false,
          },
     };

     const labels = ["ต.ค.", "พ.ย.", "ธ.ค.", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย."];

     const data = {
          labels,
          datasets: [
               {
                    label: "รายได้ สปสช.",
                    data: information.data2,
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
               },
               {
                    label: "เงินโอนเข้าบัญชี",
                    data: information.data,
                    borderColor: "rgb(0, 140, 238)",
                    backgroundColor: "rgba(0, 140, 238, 0.5)",
               },
          ],
     };
     return (
          <Grid container columns={12}>
               {/* <Grid item xs={12} >
                <Box className="census-info">
                    <Typography>
                        จำนวนเงินโอนเข้าประจำเดือน
                    </Typography>
                </Box>
            </Grid> */}
               <Grid item xs={12} className="census-info-chart-h">
                    {/* {information.length > 0 ?
                    // <Bar data={data} options={options} />
                    <Bar data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                } */}
                    <Bar data={data} options={options} />
               </Grid>
          </Grid>
     );
}
