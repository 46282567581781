import React, { useState, useEffect } from 'react'
import { GET, POST, ADDDRUG, GETLOCATION, GETVENDORALL } from "../../service";
import Swal from "sweetalert2";

import {
    Container,
} from "@mui/material";

import GridTextField from './components/GridTextField';
import BackdropLoad from '../../components/Backdrop/BackdropLoad';

function AddDrug() {

    // const [dataType, setDataType] = useState([]);
    const [dataVendor, setDataVendor] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [type, setType] = useState('');
    const [drugCode, setDrugCode] = useState('');
    const [drugName, setDrugName] = useState('');
    const [drugNameTh, setDrugNameTh] = useState('');
    const [cost, setCost] = useState('');
    const [sell, setSell] = useState('');
    const [unit, setUnit] = useState('');
    const [location, setLocation] = useState('');
    const [vendorId, setVendorId] = useState('');
    const [pack, setPack] = useState('');
    const [loading, setLoading] = useState(false);

    const getLocation = async () => {
        try {
            setLoading(true);
            let res = await GET(GETLOCATION)
            if (res.success) {
                let location = [];
                location.push({
                    label: 'เลือก รพ.สต. ',
                    value: '',
                    disabled: true,
                })
                for (let index = 0; index < res.result.length; index++) {
                    const element = res.result[index];
                    location.push({
                        label: element.location_name,
                        value: element.location_id,
                    })
                }
                setDataLocation(location)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })            }
        } catch (error) {
            setLoading(false)
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const getVendor = async () => {
        try {
            setLoading(true);
            let res = await GET(GETVENDORALL)
            if (res.success) {
                let vendor = [];
                vendor.push({
                    label: 'เลือกบริษัทยา',
                    value: '',
                    disabled: true,
                })
                for (let index = 0; index < res.result.length; index++) {
                    const element = res.result[index];
                    vendor.push({
                        label: element.vendor_name,
                        value: element.vendor_id,
                    })
                }
                setDataVendor(vendor)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })            }
        } catch (error) {
            setLoading(false)
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const handleAddDrug = async () => {
        // console.log(drugCode , drugName , drugNameTh , cost , sell , unit , vendorId);
        if (drugCode && drugName && drugNameTh && cost && sell && unit && vendorId && type) {
            try {
                setLoading(true)
                let res = await POST(ADDDRUG, { drug_code: drugCode, drug_name: drugName, drug_name_thai: drugNameTh, cost, sell, unit, vendor_id: vendorId, location_id: location ? location : null, pack, drug_type: type });
                if (res.success) {
                    setLoading(false);
                    Swal.fire({
                        icon: 'success',
                        text: `สร้างรายการเวชภัณฑ์สำเร็จ`,
                        confirmButtonText: 'ตกลง',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.replace("/Drug");
                        }
                    })
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        // text: `${res.message}`,
                        text: `สร้างรายการเวชภัณฑ์ไม่สำเร็จ`,
                        confirmButtonText: 'ตกลง',
                    })
                }
            } catch (error) {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    text: `${error}`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } else {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `กรุณาตรวจสอบข้อมูลให้ครบถ้วน`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    const handleChangeText = (e) => {
        let value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
        // console.log(value);
        return value;
    };

    useEffect(() => {
        getLocation();
        getVendor();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridTextField
                action="insert"
                handleAddDrug={handleAddDrug}
                handleChangeText={handleChangeText}
                // dataType={dataType}
                dataVendor={dataVendor}
                dataLocation={dataLocation}
                setType={(e) => setType(e)}
                cost={cost}
                sell={sell}
                setDrugCode={(e) => setDrugCode(e)}
                setDrugName={(e) => setDrugName(e)}
                setDrugNameTh={(e) => setDrugNameTh(e)}
                setCost={(e) => setCost(e)}
                setSell={(e) => setSell(e)}
                setUnit={(e) => setUnit(e)}
                setVendorId={(e) => setVendorId(e)}
                setLocation={(e) => setLocation(e)}
                setPack={(e) => setPack(e)}
            />
        </Container>
    )
}

export default AddDrug;