import { Box, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { BtnMap } from "../../../Theme";
import { fixAmount } from "../../../untils/shortcut";

const latlong = window.location.origin.includes("kk")
     ? [16.43192755724492, 102.82856609781523]
     : window.location.origin.includes("mdh")
     ? [16.59738946623057, 104.51104253757008]
     : window.location.origin.includes("spb")
     ? [14.651579065119135, 99.93989999520554]
     : [14.651579065119135, 99.93989999520554];
function LeafletMapComponent({ subdistrictObj, setSelectTambon }) {
     // console.log("subdistrictObj", subdistrictObj);
     const [map, setMap] = useState(null);
     const [onselect, setOnselect] = useState({});

     const highlightFeature = (e) => {
          // console.log(e.target.getBounds().getCenter());
          let layer = e.target;
          const { tam_th, count } = e.target.feature.properties;
          setOnselect({
               Tumbon: tam_th,
               Population: count,
          });

          if (count > 0) {
               setSelectTambon(tam_th);
          }

          layer.setStyle({
               weight: 5,
               color: "#666",
               dashArray: "",
               fillOpacity: 0.7,
          });
          layer.bringToFront();

          map.fitBounds(e.target.getBounds());
     };

     const resetHighlight = (e => {
       setOnselect({});
       e.target.setStyle(style(e.target.feature));
     })

     const zoomToFeature = (e => {
       const { tam_th, count } = e.target.feature.properties;
       setOnselect({
         Tumbon: tam_th,
         Population: count
       });
       map.fitBounds(e.target.getBounds());
     })

     const handleAll = () => {
          setSelectTambon("");
          setOnselect({
               Tumbon: null,
               Population: null,
          });
     };

     const onEachFeature = (feature, layer) => {
          layer.on({
               // mouseover: highlightFeature,
               // mouseout: resetHighlight,
               click: highlightFeature,
          });
     };

     const mapPolygonColorToDensity = (d) => {
          return d > 10000
               ? "#800026"
               : d > 5000
               ? "#BD0026"
               : d > 2000
               ? "#E31A1C"
               : d > 1000
               ? "#FC4E2A"
               : d > 500
               ? "#FD8D3C"
               : d > 200
               ? "#FEB24C"
               : d > 100
               ? "#FED976"
               : d > 1
               ? "#FFEDA0"
               : "#000";
     };

     const style = (feature) => {
          return {
               fillColor: mapPolygonColorToDensity(feature.properties.count),
               weight: 1,
               opacity: 1,
               color: "white",
               dashArray: "2",
               fillOpacity: 0.5,
          };
     };

      useEffect(() => {
           console.log("first");
           if (subdistrictObj.length > 0) {
                if (map !== null) {
                     map.locate({ setView: true });
                     map.on("locationfound", function (e) {
                          map.flyTo(e.latlng, 9);
                     });
                }
           }
      }, [subdistrictObj]);

     return (
          <>
               <Grid container columns={12}>
                    <Grid item xs={12}>
                         <Box className="census-info" width="100%">
                              {onselect.Tumbon ? (
                                   <Stack spacing={1} className="flex-center" width="100%">
                                        <Typography>ตำบล : {onselect.Tumbon}</Typography>
                                        <Typography>การเข้ารับบริการ : {fixAmount(onselect.Population)} คน</Typography>
                                   </Stack>
                              ) : (
                                   <Stack spacing={1} className="flex-center" width="100%">
                                        <Typography>จำนวนประชากรที่เสียชีวิต ในแต่ละพื้นที่รายตำบล (คน)</Typography>
                                   </Stack>
                              )}
                              <Box className="flex-end info-button">
                                   <BtnMap variant="outlined" onClick={handleAll}>
                                        อำเภอ
                                   </BtnMap>
                              </Box>
                         </Box>
                    </Grid>
                    <Grid item xs={12}>
                         <MapContainer
                              scrollWheelZoom={true}
                              center={latlong} // Set your initial map center coordinates
                              zoom={9} // Set your initial zoom level
                              className="mapContainer"
                              ref={setMap}
                         >
                              <TileLayer
                                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                   maxZoom={19}
                                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              />
                              {subdistrictObj.length > 0 && <GeoJSON data={subdistrictObj} style={style} onEachFeature={onEachFeature} />}
                         </MapContainer>
                    </Grid>
               </Grid>
          </>
     );
}

export default LeafletMapComponent;
