import React, { useState } from 'react';
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../Theme";

import {
    EditDataIcon,
    DeleteIcon,
    MoreIcon
} from '../../../untils/icons';
import { roleStatus } from '../../../untils/static';

export default function GridTable({ header, data, setData, deleteAlert }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path, role_id) => {
        return () => {
            navigate(path, { state: { detail: role_id } });
        };
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column, i) => (
                                <StyledTableCell
                                    key={i + 1}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <Typography fontWeight='bold' fontSize='14px'>{column.label}</Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i + 1}>
                                        {/* <StyledTableCell align="center">{moment(row.date_created).format('L')}</StyledTableCell> */}
                                        <StyledTableCell align="left">{row.role_name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.screen_name.map((item, index) => {
                                            return (
                                                row.expand === 0 ? index < 2 &&
                                                    <Chip key={index} label={item.screen_name} color={item.status === 1 ? 'success' : 'default'} sx={{ margin: '3px', }} />
                                                    :
                                                    <Chip key={index} label={item.screen_name} color={item.status === 1 ? 'success' : 'default'} sx={{ margin: '3px', }} />

                                            )
                                        })}
                                            {
                                                row.screen_name.length > 0 &&
                                                <Chip deleteIcon={<MoreIcon />} onClick={() => {
                                                    setData(prevData => prevData.map(item =>
                                                        item.role_id === row.role_id ? { ...item, expand: row.expand === 0 ? 1 : 0 } : item
                                                    ))
                                                }}
                                                    onDelete={() => {
                                                        setData(prevData => prevData.map(item =>
                                                            item.role_id === row.role_id ? { ...item, expand: row.expand === 0 ? 1 : 0 } : item
                                                        ))
                                                    }}
                                                    label={row.expand === 0 ? 'more' : 'less'} />
                                            }


                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Chip sx={{ width: '70px', background: `${roleStatus[row.status].color}`, color: "var(--white)" }} label={roleStatus[row.status].label} />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <EditDataIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Edit`, row.role_id)} />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteAlert(row.role_id)} />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}
