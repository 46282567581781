import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { menuSideBar } from '../../untils/static';

function Guard({ children }) {

    const router = useNavigate();
    const [isAuth, setIsAuth] = useState(false);
    const menu = JSON.parse(localStorage.getItem('menu'));

    useEffect(() => {
        const init = () => {
            const screen = JSON.parse(localStorage.getItem('screen'));

            const path = window.location.pathname;
            const strs = path.split("/");
            if (screen) {
                if (screen.some((s) => s.screen_path === `/${strs[1]}`)) {
                    setIsAuth(true)
                    return;
                }
                if (`/${strs[1]}` === "/Homepage") {
                    setIsAuth(true)
                    return;
                }
                router('/401');
            } else {
                router('/Login');
            }
        }
        init();
    }, [])

    useEffect(() => {
        if (!menu) {
            window.localStorage.removeItem("menu");
            window.localStorage.setItem("menu", JSON.stringify(menuSideBar.menu_home));
            window.localStorage.removeItem("system");
            window.localStorage.setItem("system", "หน้าหลัก");
        }
    }, []);

    return (
        <>
            {isAuth &&
                <>
                    {children}
                </>
            }
        </>
    )
}

export default Guard;