import { Grid } from "@mui/material";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import React from "react";
import { Bar } from 'react-chartjs-2';
import { abbreviateNumber } from "../../../untils/shortcut";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BarChartYear({ yearData }) {

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                formatter: function (value) {
                    return abbreviateNumber(value);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                // backgroundColor: "#BEBEBD",
            },
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "white",
                    beginAtZero: true,
                },
                grid: {
                    display: false, // Hide x grid
                },
                max: 5000,
            },
        }
    };

    const labels = yearData.map((e) => e.year!== "" ? e.year.replace("โรงพยาบาลส่งเสริมสุขภาพตำบล", 'รพ. สต.') : "ไม่ระบุ");

    const data = {
        labels,
        datasets: [
            {
                label: 'จำนวน',
                data: yearData.map((e) => e.count),
                // backgroundColor: "#48A6BF",
                // borderColor: "#48A6BF",
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center mapContainer-xs-6">
                {/* {information.length > 0 ?
                    <Bar data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                } */}
                <Bar data={data} options={options} height='auto'/>
            </Grid>
        </Grid>
    );
}
