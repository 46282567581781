import React, { useState } from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Grid,
    TextField,
} from "@mui/material";
import moment from "moment";
import { StyledTableCell } from "../../../Theme"

export default function GridTableDetail({ header, data, setData }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="left">{row.drug_name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.pack}</StyledTableCell>
                                        <StyledTableCell align="left">{row.drug_type}</StyledTableCell>
                                        <StyledTableCell align="right">{row.amount}</StyledTableCell>
                                        <StyledTableCell align="left">{row.unit}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <TextField
                                                disabled={row.status !== 1 && true}
                                                id="standard-basic"
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                size="small"
                                                defaultValue={row.lot_no}
                                                onChange={(val) => {
                                                    setData(prevData => prevData.map(item =>
                                                        item.stock_transaction_id === row.stock_transaction_id ? { ...item, lot_no: val.target.value } : item
                                                    ))
                                                }}
                                                type='text'
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <TextField
                                                disabled={row.status !== 1 && true}
                                                id="standard-basic"
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                size="small"
                                                defaultValue={row.amount_approve}
                                                onChange={(val) => {
                                                    // console.log({ val, rval: val.target.value });
                                                    setData(prevData => prevData.map(item =>
                                                        item.stock_transaction_id === row.stock_transaction_id ? { ...item, amount_approve: val.target.value } : item
                                                    ))
                                                }}
                                                type='number'
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <TextField
                                                disabled={row.status !== 1 && true}
                                                id="standard-basic"
                                                variant="standard"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                size="small"
                                                defaultValue={moment(row.expire).format("YYYY-MM-DD")}
                                                // value={row.expire}
                                                onChange={(val) => {
                                                    // console.log({ val, rval: val.target.value });
                                                    setData(prevData => prevData.map(item =>
                                                        item.stock_transaction_id === row.stock_transaction_id ? { ...item, expire: val.target.value } : item
                                                    ))
                                                }}
                                                type='date'
                                            />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}

