import React, { useState } from 'react'
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
    Checkbox,
    FormControlLabel,
    FormControl,
    FormGroup,
} from "@mui/material";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../../Theme"
import { MoreIcon } from '../../../../untils/icons';
import { tofieds } from '../../../../untils/shortcut';
export default function GridTable({ header, data, handleChangeCheckbox, checkAll }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path, detail) => {
        return () => {
            navigate(path, { state: { detail: detail } });
        };
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                align="left"
                            >
                                <FormControlLabel
                                    label="ทั้งหมด"
                                    control={
                                        <Checkbox
                                            checked={checkAll === -1 ? true : false}
                                            name="ทั้งหมด"
                                            onChange={(e) => handleChangeCheckbox(e)}
                                        />
                                    }
                                />
                            </StyledTableCell>
                            {header.map((column, i) => (
                                <StyledTableCell
                                    key={i + 1}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <Typography fontWeight='bold' fontSize='14px'>{column.label}</Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i + 1}>
                                        <StyledTableCell
                                            align="left"
                                        >
                                            <FormControl component="fieldset" variant="standard">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        // key={index + 1}
                                                        control={
                                                            <Checkbox onChange={(e) => handleChangeCheckbox(e)} checked={row.check} value={row.claim_id} name={row.cid} />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.cid}</StyledTableCell>
                                        <StyledTableCell align="center">{row.pid}</StyledTableCell>
                                        <StyledTableCell align="left">{row.prefix}</StyledTableCell>
                                        <StyledTableCell align="left">{row.name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.lastname}</StyledTableCell>
                                        <StyledTableCell align="right">{row.age}</StyledTableCell>
                                        <StyledTableCell align="center">{moment(row.date).format('L')}</StyledTableCell>
                                        <StyledTableCell align="left">{row.diagnose}</StyledTableCell>
                                        <StyledTableCell align="right">{tofieds(row.service)}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <MoreIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Detail`, row)} />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}
