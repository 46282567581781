import React, { useState, useEffect } from 'react'
import { GET, POST, UPDATEDRUG, GETLOCATION, GETVENDORALL, GETDRUGBYID } from "../../service";
import Swal from "sweetalert2";

import {
    Container,
} from "@mui/material";
import BackdropLoad from '../../components/Backdrop/BackdropLoad';
import GridTextField from './components/GridTextField';
import { useLocation } from 'react-router-dom';

function EditDrug() {

    // const [dataType, setDataType] = useState([]);
    const [dataVendor, setDataVendor] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [type, setType] = useState('');
    const [drugCode, setDrugCode] = useState('');
    const [drugName, setDrugName] = useState('');
    const [drugNameTh, setDrugNameTh] = useState('');
    const [cost, setCost] = useState('');
    const [sell, setSell] = useState('');
    const [unit, setUnit] = useState('');
    const [location, setLocation] = useState('');
    const [vendorId, setVendorId] = useState('');
    const [pack, setPack] = useState('');
    const [loading, setLoading] = useState(false);

    const id_path = useLocation()
    const { detail } = id_path.state

    const getLocation = async () => {
        try {
            setLoading(true);
            let res = await GET(GETLOCATION)
            if (res.success) {
                let location = [];
                location.push({
                    label: 'เลือก รพ.สต. ',
                    value: '',
                    disabled: true,
                })
                for (let index = 0; index < res.result.length; index++) {
                    const element = res.result[index];
                    location.push({
                        label: element.location_name,
                        value: element.location_id,
                    })
                }
                setDataLocation(location)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })            }
        } catch (error) {
            setLoading(false)
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const getVendor = async () => {
        try {
            setLoading(true);
            let res = await GET(GETVENDORALL)
            if (res.success) {
                let vendor = [];
                vendor.push({
                    label: 'เลือกบริษัทยา',
                    value: '',
                    disabled: true,
                })
                for (let index = 0; index < res.result.length; index++) {
                    const element = res.result[index];
                    vendor.push({
                        label: element.vendor_name,
                        value: element.vendor_id,
                    })
                }
                setDataVendor(vendor)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })            }
        } catch (error) {
            setLoading(false)
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const getDrug = async () => {
        try {
            setLoading(true)
            let res = await POST(GETDRUGBYID, { drug_id: detail.drug_id })
            if (res.success) {
                let data_drug = res.result[0];
                setDrugCode(data_drug.drug_code);
                setDrugName(data_drug.drug_name);
                setDrugNameTh(data_drug.drug_name_thai);
                setCost(data_drug.cost);
                setSell(data_drug.sell);
                setUnit(data_drug.unit);
                setLocation(data_drug.location_id);
                setVendorId(data_drug.vendor_id);
                setPack(data_drug.pack);
                setType(data_drug.drug_type);
                setLoading(false)
            } else {
                setLoading(false)
                alert(res.result);
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const handleUpdateDrug = async () => {
        // console.log(drugCode, drugName, pack, location, drugNameTh, cost, sell, unit, vendorId);
        try {
            setLoading(true)

            let data = [];
            data.push({
                drug_code: drugCode, drug_name: drugName, drug_name_thai: drugNameTh, cost, sell, unit, vendor_id: vendorId, location_id: location ? location : null, pack, drug_type: type
            })

            let res = await POST(UPDATEDRUG, { drug_id: detail.drug_id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    // text: `${res.result}`,
                    text: `แก้ไขรายการเวชภัณฑ์สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/Drug");
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    // text: `${res.message}`,
                    text: `แก้ไขรายการเวชภัณฑ์ไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }

    };

    const handleChangeText = (e) => {
        let value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
        // console.log(value);
        return value;
    };

    useEffect(() => {
        getLocation();
        getVendor();
        getDrug();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridTextField
                action="update"
                handleUpdateDrug={handleUpdateDrug}
                handleChangeText={handleChangeText}
                // dataType={dataType}
                dataVendor={dataVendor}
                dataLocation={dataLocation}
                setType={(e) => setType(e)}
                type={type}

                drugCode={drugCode}
                drugName={drugName}
                drugNameTh={drugNameTh}
                cost={cost}
                sell={sell}
                unit={unit}
                vendorId={vendorId}
                location={location}
                pack={pack}

                setDrugCode={(e) => setDrugCode(e)}
                setDrugName={(e) => setDrugName(e)}
                setDrugNameTh={(e) => setDrugNameTh(e)}
                setCost={(e) => setCost(e)}
                setSell={(e) => setSell(e)}
                setUnit={(e) => setUnit(e)}
                setVendorId={(e) => setVendorId(e)}
                setLocation={(e) => setLocation(e)}
                setPack={(e) => setPack(e)}
            />
        </Container>
    )
}

export default EditDrug;