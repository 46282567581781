import React, { Component } from 'react'

import {
    Container,
} from "@mui/material";

import GridDetail from './components/GridDetail';
import { headerTable } from '../../../untils/static';

class DetailReportApprove extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <GridDetail
                    header={headerTable.report_approve_detail}
                    data={rows}
                />
            </Container>
        );
    }
}

export default DetailReportApprove;

function createData(id, drug_name, pack, type, amount, unit, lot_no) {
    return { id, drug_name, pack, type, amount, unit, lot_no };
}

const rows = [
    createData(1, 'Amitriptyline 10 mg', 'เวชภัณฑ์ยา', '10 mg', 3, 'แผง', '00-00000'),
    createData(2, 'Alcohol 70% 450 ml', 'เวชภัณฑ์ยา', '450 ml', 3, 'ขวด', '00-00000'),

];