import React from 'react'

import {
    Container,
    Box,
    Paper,
    Grid,
    Stack,
    Typography,
} from "@mui/material";

import { Dashboard } from '../../untils/icons';

function DashboardPAO() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                        <Grid item xs={8} sm={6} md={6} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize='medium' />
                                <Typography className="main-header">
                                    Dashboard อบจ.
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
        </Container>
    )
}

export default DashboardPAO;