import React from 'react'

import {
    Container,
} from "@mui/material";

import GridDetail from './components/GridDetail';
import { textFiled } from '../../untils/static';

function DetailHistory() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <GridDetail banner={textFiled.banner_history} />
        </Container>
    )
}

export default DetailHistory;