import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Grid } from "@mui/material";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function PyramidDashboard() {
    const options = {
        indexAxis: 'y',
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'ปิรามิดประชากร ปี 2567',
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let value = context.raw;
                        return `${context.dataset.label}: ${Math.abs(value)}%`;
                    }
                },
            },
            datalabels: {
                display: false,
                color: 'black',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                min: -5,
                max: 5,
                ticks: {
                    callback: function(value) {
                        return `${Math.abs(value)}%`;
                    }
                }
            },
            y: {
                reverse: true,
            },
        },
    };

    const labels = ["0-4 ปี", "5-9 ปี", "10-14 ปี", "15-19 ปี", "20-24 ปี", "25-29 ปี", "30-34 ปี", "35-39 ปี", "40-44 ปี", "45-49 ปี", "50-54 ปี", "55-59 ปี", "60-64 ปี", "65-69 ปี", "70-74 ปี", "75-79 ปี", "80-84 ปี", "85-89 ปี", "90-94 ปี", "95-99 ปี", "100 ปีขึ้นไป"];

    const data = {
        labels,
        datasets: [
            {
                label: "Male",
                data: [-2, -3, -4, -4, -3, -3, -3, -3, -3, -3, -4, -4, -4, -3, -2, -2, -1, -1, -0.5, -0.2, -0.1],
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                borderColor: "rgb(54, 162, 235)",
                borderWidth: 1,
            },
            {
                label: "Female",
                data: [2, 3, 4, 4, 3, 3, 3, 3, 3, 3, 4, 4, 4, 3, 2, 2, 1, 1, 0.5, 0.2, 0.1],
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 1,
            },
        ],
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <Bar data={data} options={options} />
            </Grid>
        </Grid>
    );
}
