import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, Grid, Typography } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BarChart({ information }) {

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: false,
            legend: {
                position: 'top',
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    pinch: {
                        enabled: true
                    },
                    wheel: {
                        enabled: true
                    },
                    mode: 'xy',  // Enable both x and y zooming
                }
            }
        },
    };

    const labels = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];

    const data = {
        labels,
        datasets: [
            {
                label: 'จำนวน',
                data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} >
                <Box className="census-info-dashboard">
                    <Typography>
                        สาเหตุการเสียชีวิต 10 อันดับแรก
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ minHeight: 300, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Bar data={data} options={options} />
            </Grid>
        </Grid>
    );
}
