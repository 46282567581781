import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField
} from "@mui/material";
// import { useNavigate } from "react-router-dom";

import {
    ListIcon,
    DownloadFileIcon,
} from '../../../untils/icons';

import {
    ButtonTeal,
} from '../../../Theme';

import GridTableList from './GridTableList';
import CsvDownload from "react-csv-downloader";

function ListDrug({ data, header_table, search, setSearch }) {

    // const navigate = useNavigate();

    // const handleRoute = (path) => {
    //     return () => {
    //         navigate(path);
    //     };
    // };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ListIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายการเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={4} sm={6} md={6} className="flex-end" >
                        <ButtonTeal onClick={handleRoute(`Add`)}>
                            <AddItemIcon fontSize='small' />&nbsp;
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                สร้างรายการเวชภัณฑ์
                            </Typography>
                        </ButtonTeal>
                    </Grid> */}
                    <Grid item xs={12} className="flex-start" mt={3} >
                        <Grid container columns={12} spacing={2} className='flex-start'>
                            <Grid item xs={12} sm={1.6} md={1} display={{ xs: "none", sm: "block" }}>
                                <Typography >
                                    ค้นหา :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3.9} md={2.45}>
                                <TextField
                                    fullWidth
                                    id="search"
                                    label="ค้นหารายการ"
                                    type="search"
                                    variant="outlined"
                                    size='medium'
                                    onChange={(e) => { setSearch(e.target.value) }}
                                    value={search}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={5} md={5}>
                                <FormControl fullWidth size='medium'>
                                    <InputLabel >เลือกประเภทเวชภัณฑ์</InputLabel>
                                    <Select
                                        id="drug_type"
                                        // value={state.status}
                                        label="เวชภัณฑ์"
                                    // onChange={handleChangeStatus}
                                    >
                                        {list_type.map((el, i) =>
                                            <MenuItem key={i + 1} value={el.value}>{el.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid> */}

                            <Grid item xs={12} sm={6.5} md={8.55} className="flex-end" >
                                <CsvDownload
                                    data={data}
                                    filename={`รายการเวชภัณฑ์_` + new Date().toLocaleString()}
                                    extension='.csv'
                                    columns={header_table}
                                    datas={data}
                                >
                                    <ButtonTeal>
                                        <DownloadFileIcon fontSize='small' />&nbsp;
                                        <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                            ดาวน์โหลดเอกสาร
                                        </Typography>
                                    </ButtonTeal>
                                </CsvDownload>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={3} className="flex-center" >
                        <GridTableList header={header_table} data={data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListDrug;