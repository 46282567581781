import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from 'react';

import { StyledTableCell } from "../../../Theme";
import { tofieds } from '../../../untils/shortcut';

export default function GridTable({ header, data }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="left">{row.hname !== '' ? row.hname : "ไม่ระบุ"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.tambonname}</StyledTableCell>
                                        <StyledTableCell align="right">{tofieds(row.sum)}</StyledTableCell>
                                    </TableRow>
                                );
                            })}
                        {data.length > 0 &&
                            <TableRow style={{ backgroundColor: "#eeeeee" }}>
                                <StyledTableCell colSpan={2} align="left" style={{ fontWeight: "bold" }}>รวม</StyledTableCell>
                                {/* <StyledTableCell align="left" style={{ fontWeight: "bold" }}></StyledTableCell> */}
                                <StyledTableCell align="right" style={{ fontWeight: "bold" }}>{tofieds(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum), 0))}</StyledTableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}

