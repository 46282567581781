import { Container } from "@mui/material";
import React, { useState } from "react";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import GridTable from "./DashboardHDA/GridTable";

function DashboardHDA() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridTable />
        </Container>
    );
}

export default DashboardHDA;