import React, { useState, useEffect } from "react";
import {
     Box,
     Card,
     CardContent,
     FormControl,
     Grid,
     IconButton,
     InputAdornment,
     InputLabel,
     OutlinedInput,
     Paper,
     Stack,
     TextField,
     Typography,
} from "@mui/material";
import { POST, UPDATELOCATIONBY, GETTOKENECLAIM, GETTOKENMCLAIM, GETLOCATIONBY } from "../../../service";
import Swal from "sweetalert2";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad.js";
import { ButtonTealSubmit, BtnLoading } from "../../../Theme.jsx";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getToken } from "../../../untils/shortcut.js";

function ClaimCode() {
     const profile = getToken(localStorage.getItem("profile"));

     const [loading, setLoading] = useState(false);
     const [emailEclaim, setEmailEclaim] = useState("");
     const [passwordEclaim, setPasswordEclaim] = useState("");
     const [emailMoph, setEmailMoph] = useState("");
     const [passwordMoph, setPasswordMoph] = useState("");
     const [tokenEClaim, setTokenEClaim] = useState("");
     const [tokenMoph, setTokenMoph] = useState("");
     const [msgEclaim, setMsgEclaim] = useState("");
     const [msgMoph, setMsgMoph] = useState("");
     const [statusEclaim, setStatusEclaim] = useState("");
     const [statusMoph, setStatusMoph] = useState("");
     const [showPasswordEClaim, setShowPasswordEClaim] = useState(false);
     const [showPasswordMoph, setShowPasswordMoph] = useState(false);
     const [loadingBtnEclaim, setLoadingBtnEclaim] = useState(false);
     const [loadingBtnMoph, setLoadingBtnMoph] = useState(false);

     const handleClickShowPasswordEClaim = () => setShowPasswordEClaim((show) => !show);

     const handleMouseDownPasswordEClaim = (event) => {
          event.preventDefault();
     };

     const handleClickShowPasswordMoph = () => setShowPasswordMoph((show) => !show);

     const handleMouseDownPasswordMoph = (event) => {
          event.preventDefault();
     };

     const getDataClaim = async () => {
          try {
               setLoading(true);
               let res = await POST(GETLOCATIONBY, { location_id: profile.location_id });
               if (res.success) {
                    setEmailEclaim(res.result.e_username);
                    setPasswordEclaim(res.result.e_password);
                    setTokenEClaim(res.result.e_token);
                    setEmailMoph(res.result.m_username);
                    setPasswordMoph(res.result.m_password);
                    setTokenMoph(res.result.m_token);
                    setLoading(false);
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         confirmButtonText: "ตกลง",
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const getTokenEClaim = async () => {
          setMsgEclaim("");
          setStatusEclaim("");
          // setTokenEClaim('');
          try {
               // setLoading(true);
               setLoadingBtnEclaim(true);
               let res = await POST(GETTOKENECLAIM, { username: emailEclaim, password: passwordEclaim });
               if (res.success) {
                    setMsgEclaim(res.result.message);
                    setStatusEclaim(res.result.status);
                    if (res.result.status === true) {
                         setTokenEClaim(res.result.token);
                    }
                    // setLoading(false);
                    setLoadingBtnEclaim(false);
               } else {
                    // setLoading(false);
                    setLoadingBtnEclaim(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         confirmButtonText: "ตกลง",
                    });
               }
          } catch (error) {
               // setLoading(false);
               setLoadingBtnEclaim(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const getTokenMClaim = async () => {
          setMsgMoph("");
          setStatusMoph("");
          // setTokenMoph('');
          try {
               // setLoading(true);
               setLoadingBtnMoph(true);
               let res = await POST(GETTOKENMCLAIM, { username: emailMoph, password: passwordMoph });
               if (res.success) {
                    setMsgMoph(res.result.message);
                    setStatusMoph(res.result.status);
                    if (res.result.status === true) {
                         setTokenMoph(res.result.token);
                    }
                    // setLoading(false);
                    setLoadingBtnMoph(false);
               } else {
                    // setLoading(false);
                    setLoadingBtnMoph(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         confirmButtonText: "ตกลง",
                    });
               }
          } catch (error) {
               // setLoading(false);
               setLoadingBtnMoph(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const handleSubmit = async () => {
          try {
               setLoading(true);
               let data = [];
               data.push({
                    e_username: emailEclaim,
                    e_password: passwordEclaim,
                    e_token: tokenEClaim,
                    m_username: emailMoph,
                    m_password: passwordMoph,
                    m_token: tokenMoph,
               });
               let res = await POST(UPDATELOCATIONBY, { location_id: profile.location_id, data: data[0] });
               if (res.success) {
                    setLoading(false);
                    Swal.fire({
                         icon: "success",
                         text: `บันทึกข้อมูลเรียบร้อย`,
                         confirmButtonText: "ตกลง",
                    }).then((result) => {
                         if (result.isConfirmed) {
                              window.location.reload();
                         }
                    });
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         confirmButtonText: "ตกลง",
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     useEffect(() => {
          getDataClaim();
     }, []);

     return (
          <>
               {/* <Container maxWidth="xl" sx={{ position: "relative" }}> */}
               <BackdropLoad loading={loading} />
               <Box display="flex" justifyContent="center">
                    <Paper className="paper-bg" elevation={0}>
                         <Grid container columns={12} spacing={3}>
                              <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                                   <Card className="box-card-vaccine">
                                        <CardContent className="box-card-vaccine">
                                             <Stack spacing={2} width="100%">
                                                  <Typography
                                                       style={{ textDecoration: "underline", cursor: "pointer" }}
                                                       onClick={() => window.open("https://eclaim.nhso.go.th/Client/login")}
                                                       className="main-header"
                                                  >
                                                       E claim
                                                  </Typography>
                                                  <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                                       <Grid item my={2} xs={12} sm={12} md={12}>
                                                            <TextField
                                                                 fullWidth
                                                                 label="ชื่อผู้ใช้งาน"
                                                                 name="email"
                                                                 type="text"
                                                                 value={emailEclaim}
                                                                 variant="outlined"
                                                                 onChange={(e) => setEmailEclaim(e.currentTarget.value)}
                                                            />
                                                       </Grid>
                                                       <Grid item my={2} xs={12} sm={12} md={12}>
                                                            <FormControl fullWidth variant="outlined">
                                                                 <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                                 <OutlinedInput
                                                                      fullWidth
                                                                      name="password"
                                                                      value={passwordEclaim}
                                                                      variant="outlined"
                                                                      onChange={(e) => setPasswordEclaim(e.currentTarget.value)}
                                                                      type={showPasswordEClaim ? "text" : "password"}
                                                                      endAdornment={
                                                                           <InputAdornment position="end">
                                                                                <IconButton
                                                                                     aria-label="toggle password visibility"
                                                                                     onClick={handleClickShowPasswordEClaim}
                                                                                     onMouseDown={handleMouseDownPasswordEClaim}
                                                                                     edge="end"
                                                                                >
                                                                                     {showPasswordEClaim ? <VisibilityOff /> : <Visibility />}
                                                                                </IconButton>
                                                                           </InputAdornment>
                                                                      }
                                                                      label="Password"
                                                                 />
                                                            </FormControl>
                                                       </Grid>
                                                  </Stack>
                                                  <Grid container columns={12} className="flex-start">
                                                       <Grid item my={2} xs={4} sm={2} md={2.5}>
                                                            <BtnLoading
                                                                 onClick={getTokenEClaim}
                                                                 fullWidth
                                                                 loading={loadingBtnEclaim}
                                                                 disabled={loadingBtnMoph}
                                                            >
                                                                 <span>
                                                                      ตรวจสอบ
                                                                      <br />
                                                                      รหัสใช้งาน
                                                                 </span>
                                                            </BtnLoading>
                                                       </Grid>
                                                       {msgEclaim && (
                                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                                 <Typography>:</Typography>
                                                            </Grid>
                                                       )}
                                                       <Grid item my={2} xs={7} sm={9} md={8.5}>
                                                            <Typography color={statusEclaim === true ? "green" : "red"}>{msgEclaim}</Typography>
                                                       </Grid>
                                                  </Grid>
                                             </Stack>
                                        </CardContent>
                                   </Card>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                                   <Card className="box-card-vaccine">
                                        <CardContent className="box-card-vaccine">
                                             <Stack spacing={2} width="100%">
                                                  <Typography
                                                       className="main-header"
                                                       style={{ textDecoration: "underline", cursor: "pointer" }}
                                                       onClick={() => window.open("https://cvp1.moph.go.th/dashboard/")}
                                                  >
                                                       Moph claim
                                                  </Typography>
                                                  <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                                       <Grid item my={2} xs={12} sm={12} md={12}>
                                                            <TextField
                                                                 fullWidth
                                                                 label="ชื่อผู้ใช้งาน"
                                                                 name="email"
                                                                 type="text"
                                                                 value={emailMoph}
                                                                 variant="outlined"
                                                                 onChange={(e) => setEmailMoph(e.currentTarget.value)}
                                                            />
                                                       </Grid>
                                                       <Grid item my={2} xs={12} sm={12} md={12}>
                                                            <FormControl fullWidth variant="outlined">
                                                                 <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                                 <OutlinedInput
                                                                      fullWidth
                                                                      name="password"
                                                                      value={passwordMoph}
                                                                      variant="outlined"
                                                                      onChange={(e) => setPasswordMoph(e.currentTarget.value)}
                                                                      type={showPasswordMoph ? "text" : "password"}
                                                                      endAdornment={
                                                                           <InputAdornment position="end">
                                                                                <IconButton
                                                                                     aria-label="toggle password visibility"
                                                                                     onClick={handleClickShowPasswordMoph}
                                                                                     onMouseDown={handleMouseDownPasswordMoph}
                                                                                     edge="end"
                                                                                >
                                                                                     {showPasswordMoph ? <VisibilityOff /> : <Visibility />}
                                                                                </IconButton>
                                                                           </InputAdornment>
                                                                      }
                                                                      label="Password"
                                                                 />
                                                            </FormControl>
                                                       </Grid>
                                                  </Stack>
                                                  <Grid container columns={12} className="flex-start">
                                                       <Grid item my={2} xs={4} sm={2} md={2.5}>
                                                            <BtnLoading
                                                                 onClick={getTokenMClaim}
                                                                 fullWidth
                                                                 loading={loadingBtnMoph}
                                                                 disabled={loadingBtnEclaim}
                                                            >
                                                                 <span>
                                                                      ตรวจสอบ
                                                                      <br />
                                                                      รหัสใช้งาน
                                                                 </span>
                                                            </BtnLoading>
                                                       </Grid>
                                                       {msgMoph && (
                                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                                 <Typography>:</Typography>
                                                            </Grid>
                                                       )}
                                                       <Grid item my={2} xs={7} sm={9} md={8.5}>
                                                            <Typography color={statusMoph === true ? "green" : "red"}>{msgMoph}</Typography>
                                                       </Grid>
                                                  </Grid>
                                             </Stack>
                                        </CardContent>
                                   </Card>
                              </Grid>
                              <Grid item my={2} xs={12} className="flex-end">
                                   <ButtonTealSubmit onClick={handleSubmit} disabled={loadingBtnEclaim || loadingBtnMoph}>
                                        บันทึกข้อมูล
                                   </ButtonTealSubmit>
                              </Grid>
                         </Grid>
                    </Paper>
               </Box>
               {/* </Container > */}
          </>
     );
}

export default ClaimCode;
