import React from 'react'
import {
    Grid,
    Select,
    FormControl,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

import { CssSelect } from '../Theme';
import { select_data } from '../untils/static';

function GridTextField(props) {
    return (
        <Grid container columns={12} spacing={3}>
            {props.data.map((e, i) =>
                <Grid key={i + 1} item xs={12} className="flex-start">
                    {e.label &&
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>{e.label}</Typography>
                        </Grid>
                    }
                    {e.type === "select" ?
                        <FormControl variant="standard" fullWidth>
                            <Select
                                disabled={e.disabled ? e.disabled : false}
                                id={e.name || ""}
                                name={e.name || ""}
                                displayEmpty
                                required
                                input={<CssSelect />}
                                value={props.state[e.name] || ""}
                                onChange={props.handleChange}
                            >
                                {e.data_select &&
                                    select_data[e.data_select].map((el) => (
                                        <MenuItem key={el.value} value={el.value} hidden={el.hidden ? e.hidden : false} disabled={el.disabled}>
                                            {el.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        :
                        e.type === "date" ?
                            <FormControl variant="standard" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateField']}>
                                        <DateField
                                            // label="Controlled field"
                                            value={props.state[e.name] || ""}
                                            onChange={props.handleDateChange}
                                            format="DD-MM-YYYY"
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                            :
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    disabled={e.disabled ? e.disabled : false}
                                    placeholder={e.placeholder || ""}
                                    id={e.name || ""}
                                    name={e.name || ""}
                                    required={e.required === true ? e.required : false}
                                    value={props.state[e.name] || ""}
                                    onChange={props.handleChange}
                                    variant={e.variant || "outlined"}
                                />
                            </FormControl>
                    }
                </Grid>
            )}
        </Grid>
    )
}

export default GridTextField;