import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField,
} from "@mui/material";

import {
    AddItemIcon,
    NewsIcn,
} from '../../../untils/icons';

import { headerTable } from '../../../untils/static';
import GridTableList from './GridTableList';
import { ButtonTeal } from '../../../Theme';
import { useNavigate } from 'react-router-dom';

function NewsList(props) {

    const navigate = useNavigate();

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <NewsIcn fontSize='medium' />
                            <Typography className="main-header">
                                รายการข่าวสาร
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-start" mt={3} >
                        <Grid container columns={12} spacing={2} className='flex-start'>
                            <Grid item xs={12} sm={1.6} md={1} display={{ xs: "none", sm: "block" }}>
                                <Typography >
                                    ค้นหา :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3.9} md={2.45}>
                                <TextField
                                    fullWidth
                                    id="search"
                                    label="ค้นหารายการ"
                                    type="search"
                                    variant="outlined"
                                    size='medium'
                                // onChange={(e) => { setSearch(e.target.value) }}
                                // value={search}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6.5} md={8.55} className="flex-end" >
                                <ButtonTeal onClick={handleRoute("/AddNews")}>
                                    <AddItemIcon fontSize='small' />&nbsp;
                                    <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                        เพิ่มข่าวสาร
                                    </Typography>
                                </ButtonTeal>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList header={headerTable.news_header} data={props.data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default NewsList;
