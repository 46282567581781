import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField,
} from "@mui/material";

import {
    HospitalIcon,
} from '../../../untils/icons';

import { headerTable } from '../../../untils/static';
import GridTableList from './GridTableList';

function ListHospital(props) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <HospitalIcon fontSize='medium' />
                            <Typography className="main-header">
                                โรงพยาบาลศูนย์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size='medium'
                            value={props.search}
                            onChange={(e) => {
                                props.setSearch(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList header={headerTable.hospital} data={props.data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListHospital;