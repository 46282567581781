import { Container, Box, Paper, Grid, Stack, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import BarChartDashboard from "./BarChartDashboard";
import PieChartDashboard from "./PieChartDashBoard";
import BarChartYear from "./BarChartYear";
import LeafletMapComponent from "./LeafletMapComponent";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";

function ReportCard({
    death,
    pDeath,
    yDeath,
    subdistrictObj,
    setLocation,
    location,
    hname,
    setHName,
    dataLocation,
    date_start,
    date_end,
    setDateEnd,
    setDateStart,
}) {
    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={2}>
                        <Grid item xs={12}>
                            <Box className="census-info-header">
                                <Stack className="flex-center" spacing={1}>
                                    <Typography className="text-info-header">รายงานการเสียชีวิตจากข้อมูลหน่วยบริการในจังหวัด</Typography>
                                    <Typography className="text-info-sub">หมายเหตุ : ข้อมูลจริงบนระบบทดสอบยังไม่สามารถใช้เพื่อการเผยแพร่</Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} my={2}>
                            <Grid container columns={9} spacing={3} className="flex-start">
                                <Grid item xs={6} sm={6} md={5}>
                                    <Stack width="180%" direction={{ xs: "row", sm: "row", md: "row" }} spacing={1}>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="black">
                                                ตั้งแต่:
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(date_start)}>
                                                <DatePicker
                                                    // fullWidth
                                                    value={date_start}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่เริ่มต้น"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateStart(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="black">
                                                จนถึง:
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(date_end)}>
                                                <DatePicker
                                                    fullWidth
                                                    value={date_end}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่สิ้นสุด"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateEnd(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel id="hname-select-label">รพ.สต.</InputLabel>
                                            <Select
                                                labelId="hname-select-label"
                                                id="hname-select"
                                                value={hname}
                                                label="hname"
                                                onChange={(e) => setHName(e.target.value)}
                                            >
                                                <MenuItem value="" disabled>
                                                    เลือกรพ.สต.
                                                </MenuItem>
                                                <MenuItem value="ทั้งหมด">ทั้งหมด</MenuItem>
                                                {dataLocation &&
                                                    dataLocation.map((el, i) => (
                                                        <MenuItem key={i + 1} value={el.hname} disabled={el.disabled}>
                                                            {el.hname}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel id="location-select-label">หน่วยบริการ</InputLabel>
                                            <Select
                                                labelId="location-select-label"
                                                id="location-select"
                                                value={location}
                                                label="location"
                                                onChange={(e) => setLocation(e.target.value)}
                                            >
                                                <MenuItem value="" disabled>
                                                    เลือกหน่วยบริการ
                                                </MenuItem>
                                                <MenuItem value="ทั้งหมด">ทั้งหมด</MenuItem>
                                                {pDeath &&
                                                    pDeath.map((el, i) => (
                                                        <MenuItem key={i + 1} value={el.location} disabled={el.disabled}>
                                                            {el.location}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                {/* <Grid item xs={12} mt={1}>
                                        <Box className="census-info-header" width="100%">
                                            <Stack spacing={3} className="flex-center" width="100%">
                                                <Typography className="text-info-header">กรุณาเลือก รพ.สต. และหน่วยบริการ</Typography>
                                            </Stack>
                                            <Box width={300} bgcolor="white" borderRadius={1} mr={1}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="hname-select-label">รพ.สต.</InputLabel>
                                                    <Select
                                                        labelId="hname-select-label"
                                                        id="hname-select"
                                                        value={hname}
                                                        label="hname"
                                                        onChange={(e) => setHName(e.target.value)}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            เลือกตำบล
                                                        </MenuItem>
                                                        <MenuItem value="ทั้งหมด">ทั้งหมด</MenuItem>
                                                        {dataLocation &&
                                                            dataLocation.map((el, i) => (
                                                                <MenuItem key={i + 1} value={el.hname} disabled={el.disabled}>
                                                                    {el.hname}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box width={500} bgcolor="white" borderRadius={1}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="location-select-label">หน่วยบริการ</InputLabel>
                                                    <Select
                                                        labelId="location-select-label"
                                                        id="location-select"
                                                        value={location}
                                                        label="location"
                                                        onChange={(e) => setLocation(e.target.value)}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            เลือกหน่วยบริการ
                                                        </MenuItem>
                                                        <MenuItem value="ทั้งหมด">ทั้งหมด</MenuItem>
                                                        {pDeath &&
                                                            pDeath.map((el, i) => (
                                                                <MenuItem key={i + 1} value={el.location} disabled={el.disabled}>
                                                                    {el.location}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Grid> */}
                            </Grid>
                        </Grid>

                        {/* Chart */}

                        <Grid item xs={12} sm={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">5 อันดับการเสียชีวิตสูงสุด</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartDashboard death={death} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนประชากรที่เสียชีวิต แยกตามหน่วยบริการ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <PieChartDashboard pDeath={pDeath} />
                            </Box>
                        </Grid>

                        {/* MAP */}
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนประชากรที่เสียชีวิตในแต่ละพื้นรายตำบล(คน)</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <LeafletMapComponent
                                    subdistrictObj={subdistrictObj}
                                    setHName={setHName}
                                    setLocation={setLocation}
                                    hname={hname}
                                    location={location}
                                    pDeath={pDeath}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนประชากรที่เสียชีวิตในรอบ 10ปี</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartYear yDeath={yDeath} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}
export default ReportCard;
