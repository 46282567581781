import React from 'react';
import {
    Container,
} from "@mui/material";
import NewsList from './components/NewsList';

function News() {
    // const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(false);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <NewsList
                // data={data}
                data={detail}
            />
        </Container>
    );

}
const detail = [
    { news_list: "นพ.ชลน่าน ศรีแก้ว รมว.สาธารณสุข กล่าวถึงกรณีระบบคอมพิวเตอร์โรงพยาบาลศูนย์อุดรธานี ถูกแฮ็กข้อมูล ว่า ระบบคอมพิวเตอร์ของโรงพยาบาลอุดรธานีมีปัญหามา 2-3 วันแล้ว โดยถูกโจมตีทางระบบคอมพิวเตอร์จาก Ransome ware ขณะนี้แก้ปัญหาในเชิงระบบได้แล้ว สามารถปรับระบบแนวทางการดูแลผู้ป่วยได้ โดยกรณีส่งต่อผู้ป่วยมารักษาที่ รพ.อุดรธานีจะใช้ระบบการทำงานด้วยการเขียนมือและทำการเชื่อมระบบที่ใช้ในการจัดเก็บรูปภาพทางการแพทย์ (PAC) โดยแจ้ง รพ.ทุกแห่งที่เกี่ยวข้องในการส่งต่อผู้ป่วยให้พิมพ์ผลทางห้องปฏิบัติการ (Lab) ", news_date: "วว/ดด/ปป" },
]
export default News;