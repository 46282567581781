import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Stack, Container } from "@mui/material";

import { ItemHomepage } from "../../Theme";
import { useNavigate } from "react-router-dom";
import { menuSideBar } from "../../untils/static";
import { ClaimIcon, Dashboard, StockPage } from "../../untils/icons";
import Modal from "@mui/material/Modal";
import ModalStepper from "../../components/ModalStepper";
import dayjs from "dayjs";

function Homepage() {
     const navigate = useNavigate();
     const screen = JSON.parse(localStorage.getItem("screen"));
     let intersections_stock = menuSideBar.menu_stock.filter((e) => screen.some((el) => el.screen_path === e.link));
     let intersections_claim = menuSideBar.menu_claim.filter((e) => screen.some((el) => el.screen_path === e.link));
     let intersections_dashboard = menuSideBar.menu_dashboard.filter((e) => screen.some((el) => el.screen_path === e.link));

     // console.log("intersections_stock", intersections_stock);
     // console.log("intersections_claim", intersections_claim);
     // console.log("intersections_dashboard", intersections_dashboard);

     const handleRoute = (path, menu, system) => {
          window.localStorage.removeItem("menu");
          window.localStorage.setItem("menu", JSON.stringify(menu));
          window.localStorage.removeItem("system");
          window.localStorage.setItem("system", system);

          navigate(path);
     };
     const popup = window.localStorage.getItem("popup");
     const [open, setopen] = useState(popup ? dayjs(new Date()).format("YYYY-MM-DD") >= popup : true);
     useEffect(() => {
          window.localStorage.removeItem("menu");
          window.localStorage.setItem("menu", JSON.stringify(menuSideBar.menu_home));
          window.localStorage.removeItem("system");
          window.localStorage.setItem("system", "หน้าหลัก");
     }, []);

     return (
          <>
               <Container maxWidth="lg" sx={{ position: "relative" }}>
                    <Modal open={open} onClose={() => setopen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                         <Box
                              sx={{
                                   position: "absolute",
                                   top: "50%",
                                   left: "50%",
                                   transform: "translate(-50%, -50%)",
                                   minWidth: 350,
                                   maxWidth: 1200,
                                   bgcolor: "background.paper",
                                   borderRadius: "5px",
                                   boxShadow: 24,
                                   p: 4,
                              }}
                         >
                              <ModalStepper handleClose={() => setopen(false)} />
                         </Box>
                    </Modal>
                    <Box className="flex-center" height="80vh">
                         <Grid container columns={12} className="flex-center">
                              <Grid item xs={12}>
                                   <Stack className="flex-center" spacing={3} direction={{ xs: "column", sm: "row", md: "row" }}>
                                        {intersections_stock.length > 0 && (
                                             <ItemHomepage
                                                  elevation={3}
                                                  className="flex-center"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                       handleRoute(`${intersections_stock[0].link}`, menuSideBar.menu_stock, "ระบบคลังยา");
                                                  }}
                                             >
                                                  <Stack direction="row" spacing={1} className="flex-start">
                                                       <StockPage />
                                                       <Typography className="menu-homepage">ระบบคลังยา</Typography>
                                                  </Stack>
                                             </ItemHomepage>
                                        )}
                                        {intersections_claim.length > 0 && (
                                             <ItemHomepage
                                                  elevation={3}
                                                  className="flex-center"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                       handleRoute(`${intersections_claim[0].link}`, menuSideBar.menu_claim, "ระบบสนับสนุนการเคลม");
                                                  }}
                                             >
                                                  <Stack direction="row" spacing={1} className="flex-start">
                                                       <ClaimIcon />
                                                       <Typography className="menu-homepage">ระบบสนับสนุนการเคลม</Typography>
                                                  </Stack>
                                             </ItemHomepage>
                                        )}
                                        {intersections_dashboard.length > 0 && (
                                             <ItemHomepage
                                                  elevation={3}
                                                  className="flex-center"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                       handleRoute(
                                                            `${intersections_dashboard[0].link}`,
                                                            menuSideBar.menu_dashboard,
                                                            "ระบบ Dashboard"
                                                       );
                                                  }}
                                             >
                                                  <Stack direction="row" spacing={1} className="flex-start">
                                                       <Dashboard />
                                                       <Typography className="menu-homepage">ระบบ Dashboard</Typography>
                                                  </Stack>
                                             </ItemHomepage>
                                        )}
                                   </Stack>
                              </Grid>
                         </Grid>
                    </Box>
               </Container>
               <footer>
                    <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
                         <path
                              transform="translate(0, -20)"
                              d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700"
                              fill="var(--teal-100)"
                              opacity="0.8"
                         />
                         <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="var(--teal-A700)" />
                    </svg>
               </footer>
          </>
     );
}

export default Homepage;
