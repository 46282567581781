import React, { useState } from 'react';

import {
    Box,
    Grid,
    Paper,
    Stack
} from "@mui/material";
import BarChart from './Dashboard43/BarChart';
import BarChartVertical from './Dashboard43/BarChartVertical';
import DoughnutChart from './Dashboard43/DoughnutChart';
import GridSearch from './Dashboard43/GridSearch';
import thai_amphures from './Dashboard43/thai_amphures.json';
import thai_provinces from './Dashboard43/thai_provinces.json';
import thai_tambons from './Dashboard43/thai_tambons.json';
import GridTable from './Dashboard43/GridTable';
import { headerTable } from '../../untils/static';
import HorizonChart from './Dashboard43/HorizonChart';

function Dashboard43() {
    // const [data, setData] = useState([]);
    const [year, setYear] = useState('')
    const [province, setProvince] = useState('')
    const [amphure, setAmphure] = useState('')
    const [tambon, setTambon] = useState('')
    const [khet, setKhet] = useState('')
    const [dataAmphure, setDataAmphure] = useState([]);
    const [dataTambon, setDataTambon] = useState([]);

    const selectProvince = (e) => {
        let value = e.target.value;
        let index = thai_provinces.filter((x) => x.name_th === value)
        let id = index[0].id;
        setAmphure('')
        setTambon('')
        setDataAmphure(thai_amphures.filter((e) => id === e.province_id))
        return value;
    };

    const selectAmphure = (e) => {
        setTambon('')
        let value = e.target.value;
        let index = thai_amphures.filter((x) => x.name_th === value)
        let id = index[0].id;
        setDataTambon(thai_tambons.filter((e) => id === e.amphure_id))
        return value;
    };

    const yearsData = Array.from(
        { length: 50 }, (_, index) => {
            return { value: new Date().getFullYear() - index }
        }
    );

    return (
        // <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-center">
                        <GridSearch
                            selectProvince={selectProvince}
                            selectAmphure={selectAmphure}
                            yearsData={yearsData}
                            year={year}
                            province={province}
                            setYear={setYear}
                            setProvince={setProvince}
                            amphure={amphure}
                            setAmphure={setAmphure}
                            dataAmphure={dataAmphure}
                            dataTambon={dataTambon}
                            tambon={tambon}
                            setTambon={setTambon}
                            setKhet={setKhet}
                            khet={khet}
                        />
                    </Grid>
                    <Grid item xs={12} my={3}>
                        <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
                            <HorizonChart />
                            <BarChart />
                            <DoughnutChart />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} my={3}>
                        <BarChartVertical />
                    </Grid>
                    <Grid item xs={12} my={3} className='flex-center'>
                        <GridTable header={headerTable.dashboardAll43_header} data={data} />
                    </Grid>
                </Grid>
            </Paper >
        </Box >
        // </Container>
    )
}

const data = [
    { all: "ไม่มีผลประเมิน", CC: 3, KPI: 4.53, KPI_CC: 4.53 },
    { all: "ค่อนข้างดี", CC: 3, KPI: 4.53, KPI_CC: 4.53 },
    { all: "ดี", CC: 3, KPI: 4.53, KPI_CC: 4.53 },
    { all: "ดีมาก", CC: 3, KPI: 4.53, KPI_CC: 4.53 },
    { all: "พอใช้", CC: 3, KPI: 4.53, KPI_CC: 4.53 },

]

export default Dashboard43;