import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from 'react';

import { StyledTblCellDashboard, StyledTblCellSum, StyledTblRowDashboard } from "../../../Theme";
import { tofieds } from '../../../untils/shortcut';

export default function GridTable({ header, data }) {
    return (
        <Paper sx={{ overflow: 'hidden' }} className="census-info-tbl">
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTblCellDashboard
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTblCellDashboard>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .map((row, index) => {
                                return (
                                    <StyledTblRowDashboard key={index + 1}>
                                        <StyledTblCellDashboard align="left">{row.all}</StyledTblCellDashboard>
                                        <StyledTblCellDashboard align="right">{tofieds(row.CC)}</StyledTblCellDashboard>
                                        <StyledTblCellDashboard align="right">{tofieds(row.KPI)}</StyledTblCellDashboard>
                                        <StyledTblCellDashboard align="right">{tofieds(row.KPI_CC)}</StyledTblCellDashboard>
                                    </StyledTblRowDashboard>
                                );
                            })}

                        <TableRow style={{ backgroundColor: "#E6E6E6", border: 0 }}>
                            <StyledTblCellSum align="left" style={{ fontWeight: "bold" }}>รวม</StyledTblCellSum>
                            <StyledTblCellSum align="right" style={{ fontWeight: "bold" }}>{tofieds(data.reduce((accumulator, currentValue) => accumulator + currentValue.CC, 0))}</StyledTblCellSum>
                            <StyledTblCellSum align="right" style={{ fontWeight: "bold" }}>{tofieds(data.reduce((accumulator, currentValue) => accumulator + currentValue.KPI, 0))}</StyledTblCellSum>
                            <StyledTblCellSum align="right" style={{ fontWeight: "bold" }}>{tofieds(data.reduce((accumulator, currentValue) => accumulator + currentValue.KPI_CC, 0))}</StyledTblCellSum>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 &&
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
            }
        </Paper>
    );
}

