import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField,
} from "@mui/material";

import GridTableList from './GridTableList';
import { AddItemIcon } from '../../../../untils/icons';
import { ButtonTeal } from '../../../../Theme';

function GridCard({
    header,
    data,
    search,
    setSearch,
    page,
    rowsPerPage,
    // count,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClickOpenAdd,
    handleClickOpenEdit,
    handleDelete
}) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            {/* <ManageAccountsDrawerIcon fontSize='medium' /> */}
                            <Typography className="main-header">
                                ยาคุมกำเนิด
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-end" >
                        <ButtonTeal onClick={handleClickOpenAdd}>
                            <AddItemIcon fontSize='small' />&nbsp;
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                เพิ่มยาคุมกำเนิด
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size='medium'
                            value={search}
                            onChange={(e) => {
                                // console.log({ e });
                                setSearch(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList
                            header={header}
                            data={data}
                            page={page}
                            // count={count}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleClickOpenEdit={handleClickOpenEdit}
                            handleDelete={handleDelete}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default GridCard;

