import React, { Component } from 'react'

import {
    Container,
} from "@mui/material";
import { headerTable } from '../../untils/static';
import ListRequisitionHospital from './components/ListRequisitionHospital';

class RequisitionHospital extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <ListRequisitionHospital
                    header={headerTable.requisition_header}
                    data={rows}
                    state={this.state}
                />
            </Container>
        );
    }
}

export default RequisitionHospital;

function createData(id, date, type, destination) {
    return { id, date, type, destination };
}

const rows = [
    createData(1, '11/11/2023', 'เบิกปกติ (เบิกไปห้องจ่ายยา)', 'ห้องยา'),
    createData(2, '11/11/2023', 'เบิกปกติ (เบิกไปห้องจ่ายยา)', 'ห้องยา'),
    createData(3, '11/11/2023', 'เบิกปกติ (เบิกไปห้องจ่ายยา)', 'ห้องยา'),
];