import React, { useEffect, useMemo, useState } from 'react';

import {
    Autocomplete,
    Box,
    Container,
    FormControl,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
    debounce
} from "@mui/material";

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { GETOPD, POST, SEARCHLOCATION } from '../../service';
import { Dashboard } from '../../untils/icons';
import BarChartVertical from './DashboardOPD/BarChartVertical';
import DoughnutChart from './DashboardOPD/DoughnutChart';

function DashboardOPD() {

    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf('year'));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    const [dataLocation, setDataLocation] = useState([]);
    const [location, setLocation] = useState('');
    const [cleared, setCleared] = useState(false);
    const [searchLoca, setSearchLoca] = useState('');
    const [inputLoca, setInputLoca] = useState('');
    const [data, setData] = useState([]);

    const getOPD = async () => {
        try {
            let res = await POST(GETOPD, { hcode: location, date_start: dateStart, date_end: dateEnd });
            if (res.success) {
                // console.log(res.result);
                setData(res.result);
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    const fetchLocation = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    let res = await POST(SEARCHLOCATION, { search: request });
                    if (res.success) {
                        setDataLocation(res.result.data)
                    } else {
                        Swal.fire({
                            icon: 'warning',
                            text: `ค้นหาไม่สำเร็จ`,
                            confirmButtonText: 'ตกลง',
                        })
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'warning',
                        text: `${error}`,
                        confirmButtonText: 'ตกลง',
                    })
                }
            }, 400),
        [],
    );

    useEffect(() => {
        if (inputLoca.length > 3) {
            fetchLocation(inputLoca);
        } else if (!inputLoca) {
            fetchLocation(inputLoca);
            return undefined;
        }
    }, [searchLoca, inputLoca, fetchLocation]);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    useEffect(() => {
        getOPD();
    }, [dateStart, dateEnd, location]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={8} sm={6} md={6} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize='medium' />
                                <Typography className="main-header">
                                    Dashboard OPD
                                </Typography>
                            </Stack>
                        </Grid>

                        {/* Select Data */}
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={12} sm={12} md={4}>
                                    <Autocomplete
                                        fullWidth
                                        getOptionLabel={(option) =>
                                            typeof option === 'string' ? option : `${option.location_code} ${option.location_name}`
                                        }
                                        filterOptions={(x) => x}
                                        options={dataLocation}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={searchLoca}
                                        noOptionsText="No locations"
                                        onChange={(event, newValue) => {
                                            // console.log("newValue", newValue);
                                            setSearchLoca(newValue);
                                            if (!newValue) {
                                                return;
                                            }
                                            if (newValue) {
                                                setLocation(newValue.location_code);
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputLoca(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                value={dateStart}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                onChange={(e) => setDateStart(e)}
                                                views={['year', 'month']}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateEnd}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                onChange={(e) => setDateEnd(e)}
                                                views={['year', 'month']}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Chart */}
                        <Grid item xs={12}>
                            <DoughnutChart information={data} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <BarChartVertical />
                        </Grid> */}
                    </Grid>
                </Paper>
            </Box >
        </Container>
    )
}

export default DashboardOPD;