import React, { useEffect, useMemo, useState } from 'react';

import {
    Container, debounce,
} from "@mui/material";

import ListHospital from './ListHospital/ListHospital';
import {
    // GET,
    // GETHOSPITAL,
    POST,
    SEARCHHOSPITAL
} from '../../service';
import Swal from 'sweetalert2';
import BackdropLoad from '../../components/Backdrop/BackdropLoad';
import dayjs from 'dayjs';

function HospitalStatus() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState(dayjs());

    // const getHospital = async () => {
    //     try {
    //         setLoading(true)
    //         let res = await GET(GETHOSPITAL)
    //         if (res.success) {
    //             setData(res.result)
    //             setLoading(false)
    //         } else {
    //             setLoading(false)
    //             Swal.fire({
    //                 icon: 'warning',
    //                 text: `${res.message}`,
    //                 showConfirmButton: true,
    //             })            }
    //     } catch (error) {
    //         Swal.fire({
    //             icon: 'warning',
    //             text: `${error}`,
    //             confirmButtonText: 'ตกลง',
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 setLoading(false);
    //             }
    //         })
    //     }
    // }

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    setLoading(true);
                    const payload = {
                        search: request
                    }
                    let res = await POST(SEARCHHOSPITAL, payload)
                    if (res.success) {
                        setData(res.result)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        Swal.fire({
                            icon: 'warning',
                            text: `${res.message}`,
                            showConfirmButton: true,
                        })
                    }
                } catch (error) {
                    setLoading(false)
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 400),
        [],
    );

    useEffect(() => {
        // console.log({ search, s: search.length });
        //let active = true;

        if (search.length < 2) {
            // getHospital()
            return;
        }
        else if (!search) {
            // getHospital()
            return;
        }

        fetch(search);

        // return () => {
        //     active = false;
        // };
    }, [search, fetch]);

    useEffect(() => {
        if (dateFilter) {
            // console.log({dateFilter,row});
            setData((prev) => prev.filter(e => dayjs(e.date).isSame(dayjs(dateFilter, 'day'))))
        }
    }, [dateFilter]);

    useEffect(() => {
        // getHospital()
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListHospital data={data} setData={setData} search={search} setSearch={setSearch} dateFilter={dateFilter} setDateFilter={setDateFilter} />
        </Container>
    )
}

export default HospitalStatus;