import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Chip } from "@mui/material";
import React from "react";

import dayjs from "dayjs";
import { StyledTableCell } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

// import { buddhistEra } from 'dayjs/plugin/buddhistEra'
var buddhistEra = require("dayjs/plugin/buddhistEra");
dayjs.extend(buddhistEra);
export default function GridTableDetails({ header, data }) {
     // const [page, setPage] = useState(0);
     // const [rowsPerPage, setRowsPerPage] = useState(10);

     // const handleChangePage = (event, newPage) => {
     //      setPage(newPage);
     // };

     // const handleChangeRowsPerPage = (event) => {
     //      setRowsPerPage(+event.target.value);
     //      setPage(0);
     // };

     return (
          <Paper sx={{ width: "100%" }}>
               <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                         <TableHead>
                              <TableRow>
                                   {header.map((column) => (
                                        <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                             {column.label}
                                        </StyledTableCell>
                                   ))}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data
                                   // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                   .map((row, index) => {
                                        return (
                                             <TableRow key={index + 1}>
                                                  <StyledTableCell>{row.hn}</StyledTableCell>
                                                  <StyledTableCell>{row.namepat}</StyledTableCell>
                                                  <StyledTableCell>{dayjs(row.dateopd).locale("th").format("DD/MM/BBBB")}</StyledTableCell>
                                                  <StyledTableCell>{row.detail}</StyledTableCell>
                                                  <StyledTableCell align="left">
                                                       {row.medicine && row.medicine.length > 0
                                                            ? row.medicine.map((el) => (
                                                                   <p style={{ marginBottom: 3, marginTop: 3 }}>
                                                                        <Chip
                                                                             icon={<MedicalServicesIcon fontSize="small" />}
                                                                             label={el.name}
                                                                             variant="outlined"
                                                                             color="primary"
                                                                        />
                                                                   </p>
                                                              ))
                                                            : null}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="right">{tofieds(row.request)}</StyledTableCell>
                                                  <StyledTableCell align="right">{tofieds(row.reponse)}</StyledTableCell>
                                             </TableRow>
                                        );
                                   })}
                              {data.length > 0 && (
                                   <TableRow style={{ backgroundColor: "#eeeeee" }}>
                                        <StyledTableCell colSpan={5} align="left" style={{ fontWeight: "bold" }}>
                                             รวม
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ fontWeight: "bold" }}>
                                             {tofieds(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.request), 0))}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ fontWeight: "bold" }}>
                                             {tofieds(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.reponse), 0))}
                                        </StyledTableCell>
                                   </TableRow>
                              )}
                         </TableBody>
                    </Table>
               </TableContainer>
               {
                    data.length === 0 && (
                         <Grid container justifyContent="center" my={3}>
                              <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                         </Grid>
                    )
                    // :
                    // <TablePagination
                    //     rowsPerPageOptions={[100, 150, 200]}
                    //     component="div"
                    //     count={data.length}
                    //     rowsPerPage={rowsPerPage}
                    //     page={page}
                    //     onPageChange={handleChangePage}
                    //     onRowsPerPageChange={handleChangeRowsPerPage}
                    //     labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    // />
               }
          </Paper>
     );
}
