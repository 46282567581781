import React, { useState, useEffect } from 'react';
import { GET, POST, GETMANAGEROLE, DELETEROLE } from "../../service";
import Swal from "sweetalert2";
import { headerTable } from '../../untils/static';
import ManageRoleCard from './components/ManageRoleCard';
import {
    Container,
} from "@mui/material";
import BackdropLoad from '../../components/Backdrop/BackdropLoad';

function ManageRole() {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);

    const getUser = async () => {
        try {
            setLoading(true)
            let res = await GET(GETMANAGEROLE)
            if (res.success) {
                setData(res.result.map((rest) => ({ ...rest, expand: 0 })))
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    };

    const deleteRole = async (role_id) => {
        try {
            let res = await POST(DELETEROLE, { role_id: role_id, data: { status: 0 } });
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    // text: `${res.result.message}`,
                    text: `ลบบทบาทผู้ใช้งานสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    // text: `${res.message}`,
                    text: `ลบบทบาทผู้ใช้งานไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            alert("error", error);
        }
    };

    const deleteAlert = (role_id) => {
        Swal.fire({
            title: 'คุณต้องการลบหรือไม่?',
            text: "ถ้าลบแล้วคุณไม่สามารถกู้คืนบทบาทผู้ใช้งานได้",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ลบ',
            confirmButtonColor: "green",
            cancelButtonText: 'ไม่ลบ',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRole(role_id);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire({
                    text: 'ยกเลิกรายการแล้ว',
                    confirmButtonText: 'ตกลง',
                })
            }
        })
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ManageRoleCard
                data={data}
                setData={setData}
                header_table={headerTable.role_header}
                deleteAlert={deleteAlert}
            />
        </Container>
    )
}

export default ManageRole;