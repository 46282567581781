import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';



import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ButtonTeal } from '../../Theme';
import { CloseIcn } from '../../untils/icons';
import { getToken } from "../../untils/shortcut.js";

export default function DialogEditProfile({
    open,
    handleClose,
    dataLocation,
    handleChangeText,
    handleUpdateUser,
    errorEmail,
    name,
    lastname,
    email,
    setName,
    setLastname,
    setLocation,
    setEmail,
    setPassword,
    setConfirmPassword,
    setSearchLoca,
    searchLoca,
    setInputLoca,
}) {

    const formRef = useRef();

    const checkValidation = () => {
        if (formRef.current.reportValidity()) {
            handleUpdateUser();
            handleClose();
        }
        // console.log(formRef.current.reportValidity());
    }

    const [cleared, setCleared] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordFirm, setShowPasswordFirm] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPasswordFirm = () => setShowPasswordFirm((show) => !show);

    const handleMouseDownPasswordFirm = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            className="bg-dialog"
        >
            <Box className='bg-gradiant'>
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">
                    แก้ไขข้อมูลส่วนตัว
                </DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={'span'}>
                            <Grid container columns={12} spacing={3}>
                                <Grid item xs={12} className="flex-center">
                                    {/* <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                                            <Typography>ชื่อ :</Typography>
                                        </Grid> */}
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="ชื่อ"
                                            name="name"
                                            placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                            variant="outlined"
                                            value={name}
                                            onChange={(e) => setName(e.currentTarget.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="flex-center">
                                    {/* <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                                            <Typography>นามสกุล :</Typography>
                                        </Grid> */}
                                    <Grid item xs={12} >
                                        <TextField
                                            required
                                            fullWidth
                                            label="นามสกุล"
                                            name="lastname"
                                            placeholder="กรุณากรอกนามสกุลผู้ใช้งาน"
                                            variant="outlined"
                                            value={lastname}
                                            onChange={(e) => setLastname(e.currentTarget.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="flex-center">
                                    {/* <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                                            <Typography>ชื่อผู้ใช้งาน :</Typography>
                                        </Grid> */}
                                    <Grid item xs={12} >
                                        <TextField
                                            required
                                            fullWidth
                                            label="ชื่อผู้ใช้งาน"
                                            name="username"
                                            placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                            variant="outlined"
                                            value={email}
                                            // error={errorEmail}
                                            // onChange={(e) => setEmail(handleChangeText(e))}
                                            onChange={(e) => setEmail(e.currentTarget.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="flex-center">
                                    {/* <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                                            <Typography>รหัสผ่าน :</Typography>
                                        </Grid> */}
                                    <Grid item xs={12} >
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                name="password"
                                                variant="outlined"
                                                onChange={(e) => setPassword(e.currentTarget.value)}
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="flex-center">
                                    {/* <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                                            <Typography>ยืนยันรหัสผ่าน :</Typography>
                                        </Grid> */}
                                    <Grid item xs={12} >
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">ยืนยันรหัสผ่าน</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                name="confirm_password"
                                                variant="outlined"
                                                onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                                type={showPasswordFirm ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPasswordFirm}
                                                            onMouseDown={handleMouseDownPasswordFirm}
                                                            edge="end"
                                                        >
                                                            {showPasswordFirm ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="flex-center">
                                    {/* <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start" >
                                            <Typography>รพ.สต. :</Typography>
                                        </Grid> */}
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            // required
                                            disabled={profile.role_id > 2 ? true : false}
                                            fullWidth
                                            getOptionLabel={(option) =>
                                                typeof option === 'string' ? option : `${option.location_code} ${option.location_name}`
                                            }
                                            filterOptions={(x) => x}
                                            options={dataLocation}
                                            autoComplete
                                            includeInputInList
                                            filterSelectedOptions
                                            value={searchLoca}
                                            noOptionsText="No locations"
                                            onChange={(event, newValue) => {
                                                // console.log("newValue", newValue);
                                                setSearchLoca(newValue);
                                                if (!newValue) {
                                                    return;
                                                }
                                                if (newValue) {
                                                    setLocation(newValue.location_id);
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputLoca(newInputValue);
                                            }}
                                            renderInput={(params) => <TextField required {...params} label="รพ.สต." />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form >
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={() => {
                            checkValidation();
                        }}
                    >
                        แก้ไขข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
