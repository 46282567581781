import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import ReportCard from "./ReportDeath/ReportCard";
import { GETMAPREPORTDEATH, POST, REPORTDEATH } from "../../service";
import Swal from "sweetalert2";
import dayjs from "dayjs";

function ReportDeath() {
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [death, setDeath] = useState([]);
    const [pDeath, setPDeath] = useState([]);
    const [yDeath, setYDeath] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState("");
    const [hname, setHName] = useState("");
    const [tam_th, setTambon] = useState("");
    // const [dateStart, setDateStart] = useState(dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    const [dateStart, setDateStart] = useState(dayjs().startOf("month"));
    const [dateEnd, setDateEnd] = useState(dayjs());

    const getReportDeath = async () => {
        try {
            setLoading(true);
            let res = await POST(REPORTDEATH, { dateStart, dateEnd });
            // console.log('death',res.result);
            if (res.success) {
                setDeath(res.result.cDeathData);
                setPDeath(res.result.pDeathData);
                setYDeath(res.result.dDeathTY);
                setDataLocation(res.result.forSelect);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getMapReportDeath = async () => {
        setSubdistrictObj([]);
        try {
            setLoading(true);
            let res = await POST(GETMAPREPORTDEATH, { hname, location, dateStart, dateEnd });
            // console.log('map',hname, location, dateStart, dateEnd);
            if (res.success) {
                setSubdistrictObj(res.result.features);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };
    useEffect(() => {
        getReportDeath();
    }, [dateStart, dateEnd]);

    useEffect(() => {
        getMapReportDeath();
    }, [hname, location, dateStart, dateEnd]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ReportCard
                pDeath={pDeath}
                death={death}
                yDeath={yDeath}
                subdistrictObj={subdistrictObj}
                location={location}
                setLocation={setLocation}
                setTambon={setTambon}
                setHName={setHName}
                dataLocation={dataLocation}
                date_start={dateStart}
                date_end={dateEnd}
                setDateStart={(e) => setDateStart(e)}
                setDateEnd={(e) => setDateEnd(e)}
            />
        </Container>
    );
}

export default ReportDeath;
