import React, { useRef } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";

import { CloseIcn } from '../../../untils/icons';
import { ButtonTeal } from '../../../Theme';
import { MANAGEURL_TYPE } from '../../../untils/static';

export default function DialogAdd({
    open,
    handleClose,
    handleAdd,
    data,
    setName,
    name,
    setPath,
    path,
    setType,
    type,
}) {

    const formRef = useRef();

    const checkValidation = () => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleAdd();
        }
        // console.log('5555555555555',formRef.current.reportValidity());
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            className="bg-dialog"
        >
            <Box className='bg-gradiant'>
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">
                    เพิ่มข้อมูล url
                </DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={'span'}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อหน้า"
                                        name="name"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                        required
                                        fullWidth
                                        label="url"
                                        name="path"
                                        variant="outlined"
                                        value={path}
                                        onChange={(e) => setPath(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl fullWidth={true}>
                                        <InputLabel>ประเภทของหน้า</InputLabel>
                                        <Select
                                            name="type"
                                            value={type}
                                            label="เลือกประเภทของหน้า"
                                            required
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            <MenuItem value={0}>
                                            หน้าหลัก
                                            </MenuItem>
                                            <MenuItem value={1}>
                                            ระบบคลังยา
                                            </MenuItem>
                                            <MenuItem value={2}>
                                            ระบบสนับสนุนการเคลม
                                            </MenuItem>
                                            <MenuItem value={3}>
                                            ระบบ Dashboard
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form >
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={checkValidation}

                    // onClick={() => {
                    //     handleClose();
                    //     handleAdd();
                    // }}
                    >
                        เพิ่มข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
