import dayjs from "dayjs";
import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutMain from "../layout/LayoutMain";
import { GET, LOGOUT } from "../service";

import Homepage from "./Homepage/Homepage";
import Approve from "./Login/Approve";
import Login from "./Login/Login";
import VerifyIdentity from "./Login/VerifyIdentity";

//รายการเวชภัณฑ์คงคลัง
import Stock from "./Stock/Stock";

//เบิกเวชภัณฑ์
import ReciveHospital from "./TransactionHospital/ReciveHospital";
import RequisitionHospital from "./TransactionHospital/RequisitionHospital";

import AddRequisition from "./TransactionLocation/AddRequisition";
import DetailRequisition from "./TransactionLocation/DetailRequisition";
import RequisitionLocation from "./TransactionLocation/RequisitionLocation";

//Approve
import DetailApprove from "./TransactionApprove/DetailApprove";
import TransactionApprove from "./TransactionApprove/TransactionApprove";

//เวชภัณฑ์
import AddDrug from "./Drug/AddDrug";
import Drug from "./Drug/Drug";
import EditDrug from "./Drug/EditDrug";

//ประวัติการเบิก-จ่ายเวชภัณฑ์
import DetailHistory from "./History/DetailHistory";
import History from "./History/History";

//โรงพยาบาลศูนย์
import Hospital from "./Hospital/Hospital";
import HospitalStatus from "./HospitalStatus/HospitalStatus";

//โรงพยาบาลส่วนตำบล
import Location from "./Location/Location";

//อุปกรณ์
import Device from "./Device/Device";

//บริษัทยา
import Vendor from "./Vendor/Vendor";

//จัดการผู้ใช้งาน
import AddUser from "./ManageUser/AddUser";
import EditUser from "./ManageUser/EditUser";
import ManageUser from "./ManageUser/ManageUser";

//จัดการสิทธิ์สำหรับผู้ใช้งาน
import AddRole from "./ManageRole/AddRole";
import EditRole from "./ManageRole/EditRole";
import ManageRole from "./ManageRole/ManageRole";

//Report
import Error401 from "./Error/401";
import DetailReportApprove from "./Report/ReportApprove/DetailReportApprove";
import ReportApprove from "./Report/ReportApprove/ReportApprove";
import ReportDetailDrug from "./Report/ReportDrug/ReportDetailDrug";
import ReportDrug from "./Report/ReportDrug/ReportDrug";
import ReportDetailLocation from "./Report/ReportLocation/ReportDetailLocation";
import ReportDetailOrder from "./Report/ReportLocation/ReportDetailOrder";
import ReportLocation from "./Report/ReportLocation/ReportLocation";

//Claim
import Claim16 from "./Claim/Claim16/Claim16";
import DetailClaim16 from "./Claim/Claim16/DetailClaim16";
import Claim43 from "./Claim/Claim43/Claim43";
import DetailClaim43 from "./Claim/Claim43/DetailClaim43";
import ClaimCode from "./Claim/ClaimCode/ClaimCode";
import ContraceptivePill from "./Claim/ContraceptivePill/ContraceptivePill";
import Herb from "./Claim/Herb/Herb";
import DetailClaimHistory from "./Claim/History/DetailClaimHistory";
import HistoryClaim from "./Claim/History/HistoryClaim";
import Pregnant from "./Claim/Pregnant/Pregnant";
import Vaccine from "./Claim/Vaccine/Vaccine";
import ManageClaim from "./ManageClaim/ManageClaim";
import ManageUrl from "./ManageUrl/ManageUrl";

//Dashboard
import ChartStatement from "./Dashboard/ChartStatement";
import Dashboard16 from "./Dashboard/Dashboard16";
import Dashboard43 from "./Dashboard/Dashboard43";
import DashboardLocation from "./Dashboard/DashboardLocation";
import DashboardOPD from "./Dashboard/DashboardOPD";
import DashboardPAO from "./Dashboard/DashboardPAO";
import DashboardPayment from "./Dashboard/DashboardPayment";
import DashboardStatement from "./Dashboard/DashboardStatement";
import StatementDetails from "./Dashboard/DashboardStatement/StatementDetails";
import ReportDiagnose from "./Dashboard/ReportDiagnose";

//OTP
import Otp from "./Login/Otp";
import SettingsOtp from "./SettingsOTP/SettingsOtp";

//News
import AddNews from "./News/AddNews";
import News from "./News/News";
import ReportDeath from "./Dashboard/ReportDeath";
import DashboardHDA from "./Dashboard/DashboardHDA";
import DashboardHDC from "./Dashboard/DashboardHDC";

function RouterMain() {
     let time_out = localStorage.getItem("timeOut");
     let path = window.location.pathname;
     //  console.log("time_out", time_out, dayjs(new Date()).diff(time_out, "m"));
     //  console.log("path", path);
     const logout = async () => {
          try {
               let res = await GET(LOGOUT);
               if (res.success) {
                    localStorage.removeItem("menu");
                    localStorage.removeItem("system");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("screen");
                    localStorage.removeItem("timeOut");
                    window.location.replace("/login");
               } else {
                    console.log(res.result);
               }
          } catch (error) { }
     };
     if (time_out) {
          if (dayjs(new Date()).diff(time_out, "h") >= 8) {
               logout();
          }
     } else {
          if (path !== "/" && path !== "/login") {
               logout();
          }
     }

     return (
          <Routes>
               <Route path="/" element={<Login />} />
               <Route path="/Login" element={<Login />} />
               <Route path="Approve" element={<Approve />} />
               <Route path="VerifyIdentity/:uid" element={<VerifyIdentity />} />
               <Route path="/401" element={<Error401 />} />
               <Route path="Otp" element={<Otp />}/>

               <Route path="/" element={<LayoutMain />}>
                    <Route path="Homepage" element={<Homepage />} />
                    <Route path="Stock" element={<Stock />} />
                    <Route path="Drug" element={<Drug />} />
                    <Route path="Drug/Add" element={<AddDrug />} />
                    <Route path="Drug/Edit" element={<EditDrug />} />

                    <Route path="RequisitionHospital" element={<RequisitionHospital />} />
                    <Route path="RequisitionHospital/Order" element={<ReciveHospital />} />

                    <Route path="ApproveOrder" element={<TransactionApprove />} />
                    <Route path="ApproveOrder/Order" element={<DetailApprove />} />

                    <Route path="RequisitionLocation" element={<RequisitionLocation />} />
                    <Route path="RequisitionLocation/Detail" element={<DetailRequisition />} />
                    <Route path="RequisitionLocation/Add" element={<AddRequisition />} />

                    <Route path="User" element={<ManageUser />} />
                    <Route path="User/Add" element={<AddUser />} />
                    <Route path="User/Edit" element={<EditUser />} />

                    <Route path="Role" element={<ManageRole />} />
                    <Route path="Role/Add" element={<AddRole />} />
                    <Route path="Role/Edit" element={<EditRole />} />

                    <Route path="History" element={<History />} />
                    <Route path="History/Detail/:order" element={<DetailHistory />} />

                    <Route path="Hospital" element={<Hospital />} />
                    <Route path="HospitalStatus" element={<HospitalStatus />} />

                    <Route path="Location" element={<Location />} />
                    <Route path="Device" element={<Device />} />
                    <Route path="Vendor" element={<Vendor />} />

                    <Route path="ReportDrug" element={<ReportDrug />} />
                    <Route path="ReportDrug/Detail/:id" element={<ReportDetailDrug />} />

                    <Route path="ReportLocation" element={<ReportLocation />} />
                    <Route path="ReportLocation/Detail/:id" element={<ReportDetailLocation />} />
                    <Route path="ReportLocation/Detail/:id/Order/:id" element={<ReportDetailOrder />} />

                    <Route path="ReportApprove" element={<ReportApprove />} />
                    <Route path="ReportApprove/Detail/:order" element={<DetailReportApprove />} />

                    <Route path="Claim16" element={<Claim16 />} />
                    {/* <Route path="Claim16/Detail" element={<DetailClaim16 />} /> */}
                    <Route path="Claim16/Detail/:detail" element={<DetailClaim16 />} />
                    <Route path="Claim43" element={<Claim43 />} />
                    <Route path="Claim43/Detail" element={<DetailClaim43 />} />
                    <Route path="ClaimCode" element={<ClaimCode />} />
                    <Route path="ClaimVaccine" element={<Vaccine />} />
                    <Route path="HistoryClaim" element={<HistoryClaim />} />
                    <Route path="HistoryClaim/Detail" element={<DetailClaimHistory />} />
                    <Route path="Herb" element={<Herb />} />
                    <Route path="ContraceptivePill" element={<ContraceptivePill />} />
                    <Route path="PregnantDrug" element={<Pregnant />} />

                    <Route path="DashboardPAO" element={<DashboardPAO />} />
                    <Route path="DashboardLocation" element={<DashboardLocation />} />
                    <Route path="Dashboard" element={<Dashboard16 />} />
                    <Route path="Dashboard2" element={<Dashboard16 />} />
                    <Route path="DashboardAll43" element={<Dashboard43 />} />
                    <Route path="Statement" element={<DashboardStatement />} />
                    <Route path="ChartStatement" element={<ChartStatement />} />
                    <Route path="Statement/Details/:detail" element={<StatementDetails />}/>
                    <Route path="DashboardOPD" element={<DashboardOPD />} />
                    <Route path="DashboardStatement" element={<DashboardStatement />} />
                    <Route path="DashboardPayment" element={<DashboardPayment />} />
                    <Route path="ReportDiagnose" element={<ReportDiagnose />} />
                    <Route path="ReportDeath" element={<ReportDeath />} />
                    <Route path="DashboardHDA" element={<DashboardHDA />} />
                    <Route path="DashboardHDC" element={<DashboardHDC />} />

                    <Route path="News" element={<News />} />
                    <Route path="AddNews" element={<AddNews />} />

                    <Route path="ManageClaim" element={<ManageClaim />} />
                    <Route path="ManageUrl" element={<ManageUrl/>} />
                    <Route path="SettingsOtp" element={<SettingsOtp/>} />

               </Route>
          </Routes>
     );
}

export default RouterMain;
