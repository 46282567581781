import React, { useEffect, useState } from 'react';

import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Typography,
} from "@mui/material";

import BarChartAge from './BarChartAge';
import BarChartTopDiagnose from './BarChartTopDiagnose';
import BarChartYear from './BarChartYear';
import LeafletMapComponent from './LeafletMapComponent';

import male from '../../../assets/images/male.png'
import female from '../../../assets/images/female.png'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function ReportCard({
    subdistrictObj,
    dataLocation,
    ageData,
    topData,
    yearData,
    sexData,
    date_start,
    date_end,
    setDateEnd,
    setDateStart,
    setSelectHname,
    selectHname,
}) {

    const count = sexData.map((e) => e.count);
    const total = parseInt(count[0]) + parseInt(count[1]);
    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={2}>
                        <Grid item xs={12} >
                            <Box className="census-info-header">
                                <Stack className='flex-center' spacing={1}>
                                    <Typography className='text-info-header'>
                                        รายงานการวินิจฉัยโรคจากข้อมูลหน่วยบริการในจังหวัด
                                    </Typography>
                                    <Typography className='text-info-sub'>
                                        หมายเหตุ : ข้อมูลจริงบนระบบทดสอบยังไม่สามารถใช้เพื่อการเผยแพร่
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} my={2}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={12} sm={12} md={6}>
                                    <Stack width="100%" direction="row" spacing={1}>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="black">
                                                ตั้งแต่:
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(date_start)}>
                                                <DatePicker
                                                    // fullWidth
                                                    value={date_start}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่เริ่มต้น"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateStart(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="black">
                                                จนถึง:
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(date_end)}>
                                                <DatePicker
                                                    fullWidth
                                                    value={date_end}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่สิ้นสุด"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateEnd(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="hname-select-label">รพ.สต.</InputLabel>
                                        <Select
                                            labelId="hname-select-label"
                                            id="hname-select"
                                            value={selectHname}
                                            label="hname"
                                            onChange={(e) => setSelectHname(e.target.value)}
                                        >
                                            <MenuItem value="" disabled>
                                                เลือก รพ.สต.
                                            </MenuItem>
                                            <MenuItem value="ทั้งหมด">
                                                ทั้งหมด
                                            </MenuItem>
                                            {dataLocation &&
                                                dataLocation.map((el, i) => (
                                                    <MenuItem key={i + 1} value={el.hname} disabled={el.disabled}>
                                                        {el.hname}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                    จำนวนประชากรที่วินิจฉัยโรค แยกตามเพศ
                                </Typography>
                            </Box>
                            <Box className="census-info-frame" >
                                <Grid sx={{ maxHeight: '400px', minHeight: '320px' }}>
                                    <Typography align='center' style={{ backgroundColor: 'pink' }}>
                                        {count[1]}
                                    </Typography>
                                    <img src={male} width={200} height={264} alt='men' />
                                    <Typography align='center' style={{ backgroundColor: 'pink' }}>
                                        ชาย
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography align='center' style={{ backgroundColor: 'lightblue' }}>
                                        {count[0]}
                                    </Typography>
                                    <img src={female} width={200} height={264} alt='women' />
                                    <Typography align='center' style={{ backgroundColor: 'lightblue' }}>
                                        หญิง
                                    </Typography>
                                </Grid>
                                <Typography style={{ border: 'solid 2px #2A7EDC', borderRadius: '75%', width: '100px', height: '100px' }} textAlign='center' mr={2}>
                                    <br />
                                    ทั้งหมด:
                                    <br />
                                    {total}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                    จำนวนประชากรที่วินิจฉัยโรค แยกตามกลุ่มอายุ
                                </Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartAge ageData={ageData} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                    10 อันดับการวินิจฉัยโรคหรือการการบาดเจ็บสูงสุด
                                </Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartTopDiagnose topData={topData} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} >
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                    จำนวนประชากรที่วินิจฉัยโรคหรืออาการบาดเจ็บแยกรายปี
                                </Typography>
                            </Box>
                            <Box className="census-info-frame" height={477}>
                                <BarChartYear yearData={yearData} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                    จำนวนประชากรที่วินิจฉัยโรคหรืออาการบาดเจ็บ
                                    ในแต่ละพื้นทรายตำบล(คน)
                                </Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <LeafletMapComponent
                                    subdistrictObj={subdistrictObj}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
        </Container>
    )
}

export default ReportCard;