import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import React from 'react';
import { Dashboard } from "../../../untils/icons";
import { ItemDashboard } from "../../../Theme";
import InpatientDashboard from "./InpatientDashboard";
import OutpatientDashboard from "./OutpatientDashboard";
import PyramidDashboard from "./PyramidDashboard";
import doctor from "../../../assets/images/doctor.png"
import bed from "../../../assets/images/bed.png"

function GridCard() {
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12} className="flex-start" >
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize='medium' />
                                <Typography className="main-header">
                                    Health Data Center (HDC)
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} >
                            <Box className="census-info-header">
                                <Stack className='flex-center' spacing={1}>
                                    <Typography className='text-info-header'>
                                    การใช้บริการสาธารณสุข
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        {/* Line Chart */} 
                            <Grid item xs={12} className="flex-start">
                            <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                            <ItemDashboard elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <img src= {doctor} style={{ width: '50px', height: '50px' }} alt="Doctor" />
                                        <Grid>
                                            <Typography className="font-dashboard">358,363</Typography>
                                            <Typography className="font-dashboard">จำนวนผู้ป่วยนอก(คน)</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard>
                                <ItemDashboard elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <img src= {doctor} style={{ width: '50px', height: '50px' }} alt="Doctor" />
                                        <Grid>
                                            <Typography className="font-dashboard">1,107,641</Typography>
                                            <Typography className="font-dashboard">จำนวนผู้ป่วยนอก(ครั้ง)</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard>
                                <ItemDashboard elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <img src= {bed} style={{ width: '50px', height: '50px' }} alt="Bed" />
                                        <Grid>
                                            <Typography className="font-dashboard">35,740</Typography>
                                            <Typography className="font-dashboard">จำนวนผู้ป่วยใน(คน)</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard>
                                <ItemDashboard elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <img src= {bed} style={{ width: '50px', height: '50px' }} alt="Bed" />
                                        <Grid>
                                            <Typography className="font-dashboard">131,945</Typography>
                                            <Typography className="font-dashboard">จำนวนผู้ป่วยใน(วัน)</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                    </Stack>
                                </ItemDashboard>
                            </Stack>
                        </Grid>

                        {/* Chart */}
                        <Grid item xs={12} >
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                การใช้บริการผู้ป่วยนอก ทุกสิทธิ รายเดือน ปี 2567
                                </Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <OutpatientDashboard />
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                การใช้บริการผู้ป่วยใน ทุกสิทธิ รายเดือน ปี 2567
                                </Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <InpatientDashboard />
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box className="census-info-frame-header">
                                <Typography className='text-info-header'>
                                Population Pyramids
                                </Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <PyramidDashboard />
                            </Box>
                        </Grid>                                 
                    </Grid>
                </Paper>
            </Box >
        </Container >
    )
}

export default GridCard;