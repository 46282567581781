import React, { useEffect, useMemo, useState } from 'react';
import {
    Container, debounce,
} from "@mui/material";
import ListStock from './ListStock/ListStock';
import { POST, GETDRUGSTOCK, GETSEARCHDRUGSTOCK } from '../../service';
import Swal from 'sweetalert2';
import BackdropLoad from '../../components/Backdrop/BackdropLoad';

function Stock() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getStock = async () => {
        try {
            setLoading(true)
            let res = await POST(GETDRUGSTOCK, { rowperpage: (page * rowsPerPage) + rowsPerPage })
            if (res.success) {
                setData(res.result.data)
                setCount(res.result.count)
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'warning',
                    text: `${res.message}`,
                    showConfirmButton: true,
                })
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    const payload = {
                        search: request
                    }
                    let res = await POST(GETSEARCHDRUGSTOCK, payload)
                    if (res.success) {
                        setData(res.result.data)
                        setCount(res.result.count)
                        setPage(0);
                        // setLoading(false)
                    } else {
                        // setLoading(false)
                        Swal.fire({
                            icon: 'warning',
                            text: `${res.message}`,
                            showConfirmButton: true,
                        })
                    }
                } catch (error) {
                    // setLoading(false)
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500), //หน่วง
        [],
    );

    useEffect(() => {

        if (search.length < 2) {
            getStock()
            return undefined;
        }
        else if (!search) {
            getStock()
            return undefined;
        }
        fetch(search);

    }, [search, fetch, page, rowsPerPage]);


    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListStock
                data={data}
                search={search}
                setSearch={setSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
            />
        </Container>
    );

}

export default Stock;