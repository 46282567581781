import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    TextField,
} from "@mui/material";

import {
    HistoryIcon,
} from '../../../untils/icons';

import { headerTable } from '../../../untils/static';
import GridTableList from './GridTableList';

function ListHistory() {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <HistoryIcon fontSize='medium' />
                            <Typography className="main-header">
                                ประวัติการเบิก-จ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <TextField
                            fullWidth
                            id="search"
                            label="ค้นหารายการ"
                            type="search"
                            variant="outlined"
                            size='medium'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList header={headerTable.history_header} data={rows} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListHistory;


function createData(date_requisition, date_recive_order, date_recive_drug, order) {
    return { date_requisition, date_recive_order, date_recive_drug, order };
}

const rows = [
    createData('11/11/2023', '11/12/2023', '11/01/2024', '00-00000000'),
];