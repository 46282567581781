import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";

import {
    Approve,
} from '../../../untils/icons';

import GridTableList from './GridTableList';

function ListTransactionApprove({ header, data }) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <Approve fontSize='medium' />
                            <Typography className="main-header">
                                รายการขออนุมัติเบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList header={header} data={data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListTransactionApprove;
