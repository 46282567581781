import React, { useEffect, useState } from 'react';

import {
    Container,
} from "@mui/material";
import { headerTable } from '../../untils/static';
import GridDetail from './components/GridDetail';
import Swal from 'sweetalert2';
import { GETTRANSACTIONBYID, POST } from '../../service';
import { useLocation } from 'react-router-dom';

function DetailRequisition() {
    // const [loading, setLoading] = useState(false)
    // const [state, setState] = useState({})
    const [data, setData] = useState([])
    // const params = useParams()
    const location = useLocation()
    const { detail } = location.state

    const getTransactionById = async () => {
        // console.log({ id: params.id });
        // setLoading(true)
        try {
            let res = await POST(GETTRANSACTIONBYID, { order_id: detail.order_id });
            if (res.success) {
                setData(res.result)
                // setLoading(false)
            } else {
                // setLoading(false)
            }

        } catch (error) {
            // setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }
    useEffect(() => {
        // console.log({ detail });
        getTransactionById();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <GridDetail
                header={headerTable.requisition_detail}
                data={data}
                detail={detail}
            />
        </Container>
    );

}

export default DetailRequisition;
