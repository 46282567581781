import React from 'react';

import {
    Box,
    Container,
    Grid,
    Paper,
    Stack,
    Typography
} from "@mui/material";

import { ButtonTeal } from '../../../Theme';
import { SaveIcn, SettingsIcn } from '../../../untils/icons';
import { headerTable } from '../../../untils/static';
import GridTable from './GridTable';

function ManageClaimCard({
    data,
    handleChangeSwitch,
    handleDragEnd,
    handleClickOpenEdit,
    updateSortManageClaim,
    handleDragAble,
    dragAble,
}) {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={8} sm={6} md={6} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <SettingsIcn fontSize='medium' />
                                <Typography className="main-header">
                                    จัดการประเภทเคลม
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={6} md={6} className="flex-end">
                                <ButtonTeal onClick={updateSortManageClaim}>
                                    <SaveIcn fontSize='small' />&nbsp;
                                    <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                        บันทึก
                                    </Typography>
                                </ButtonTeal>
                            </Grid>
                        <Grid item xs={12} className="flex-start">
                            <GridTable
                                header={headerTable.manage_claim}
                                data={data}
                                handleChangeSwitch={handleChangeSwitch}
                                handleDragEnd={handleDragEnd}
                                handleClickOpenEdit={handleClickOpenEdit}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
        </Container>
    )
}

export default ManageClaimCard;

