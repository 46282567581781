import React, { useState } from 'react'
import {
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Grid,
} from "@mui/material";

import { StyledTableCell } from "../../../Theme"

import {
    tofieds,
} from '../../../untils/shortcut';

export default function GridTableList({ header, data }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const handleRoute = (path, detail) => {
    //     return () => {
    //         navigate(path, { state: { detail: detail } });
    //     };
    // };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                            {/* <StyledTableCell /> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="left">{row.drug_name ? row.drug_name : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.drug_name_thai ? row.drug_name_thai : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.drug_type ? row.drug_type : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="right">{row.pack ? row.pack : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="right">{row.cost ? tofieds(row.cost) : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="right">{row.sell ? tofieds(row.sell) : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.unit ? row.unit : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.vendor_name ? row.vendor_name : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.vendor_country ? row.vendor_country : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.dispensing_unit ? row.dispensing_unit : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.tpu_id ? row.tpu_id : "N/A"}</StyledTableCell>
                                        <StyledTableCell align="left">{row.status ? row.status : "N/A"}</StyledTableCell>
                                        {/* <StyledTableCell align="center">
                                            <EditDataIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Edit`, row)} />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => deleteAlert(row.drug_id)} />
                                        </StyledTableCell> */}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}

