import { Container } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
// import Swal from 'sweetalert2';
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import ChartStatementList from "./ChartStatement/ChartStatementList";
import { GETCHARTSTATEMENT, POST } from "../../service";
import Swal from "sweetalert2";

function ChartStatement() {
     const [data, setData] = useState([]);
     const [loading, setLoading] = useState(false);
     const [year, setYear] = useState(dayjs().year());

     const year_length = new Date().getFullYear() - 2024;

     const yearsData = Array.from({ length: year_length + 2 }, (_, index) => {
          return { value: 2024 + index };
     });
     const GetStatement = async () => {
          try {
               setLoading(true);
               let res = await POST(GETCHARTSTATEMENT, { year });
               if (res.success) {
                    setData(res.result);
                    setLoading(false);
               } else {
                    setLoading(false);
                    Swal.fire({
                         icon: "warning",
                         text: `${res.message}`,
                         confirmButtonText: "ตกลง",
                    });
               }
          } catch (error) {
               setLoading(false);
               Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
          }
     };
     useEffect(() => {
          GetStatement();
     }, [year]);
     return (
          <Container maxWidth="xl" sx={{ position: "relative" }}>
               <BackdropLoad loading={loading} />
               <ChartStatementList data={data} yearsData={yearsData} year={year} setYear={setYear} />
          </Container>
     );
}
// const data = [
//     {},
// ]
export default ChartStatement;
