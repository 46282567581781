import React, { useEffect, useState } from "react";
import { GET, POST, MANAGEURL, ADD_MANAGEURL, UPDATE_MANAGEURL } from "../../service";
import { headerTable } from "../../untils/static";
import UrlCard from "./component/UrlCard";
import DialogAdd from "./component/DialogAdd";
import DialogEdit from "./component/DialogEdit";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import Swal from "sweetalert2";

function ManageUrl() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [urlId, setUrlId] = useState('');
    const [name, setName] = useState('');
    const [path, setPath] = useState('');
    const [type, setType] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [urlMasterId, setUrlMasterId] = useState('')

    const getUrlMaster = async () => {
        try {
            setLoading(true);
            let res = await GET(MANAGEURL);
            if (res.success) {
                setData(res.result)
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataUrlById = async (id) => {
        try {
            setLoading(true);
            let res = await GET(MANAGEURL, { screen_id: id });
            if (res.success) {
                setUrlId(res.result.screen_id)
                setName(res.result.screen_name)
                setPath(res.result.screen_path)
                setType(res.result.type)
                // setUrlMasterId(res.result.screen_master_id)
                setLoading(false)
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleAdd = async () => {
        try {
            setLoading(true);
            let res = await POST(ADD_MANAGEURL, { screen_master_id: urlMasterId, screen_name: name, screen_path: path, type: type });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleUpdate = async () => {
        try {
            setLoading(true);

            let res = await POST(UPDATE_MANAGEURL, { screen_id: urlId , screen_name: name, screen_path: path, type });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenAdd = () => {
        setName('')
        setPath('')
        setType('')
        setUrlMasterId('')
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setName('')
        setPath('')
        setType('')
        setUrlMasterId('')
        setOpenAdd(false);
    };

    const handleClickOpenEdit = (data) => {
        // console.log(data);
        // getDataUrlById(id);
        setUrlId(data.screen_id)
        setName(data.screen_name)
        setPath(data.screen_path)
        setType(data.type)
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    // const fetch = useMemo(
    //     () =>
    //         debounce(async (request) => {
    //             try {
    //                 setLoading(true)
    //                 const payload = { text: request };
    //                 let res = await GET(MANAGEURL, payload)
    //                 if (res.success) {
    //                     setData(res.result)
    //                     setLoading(false)
    //                 } else {
    //                     setLoading(false)
    //                     Swal.fire({
    //                         icon: 'warning',
    //                         text: `${res.message}`,
    //                         showConfirmButton: true,
    //                     })
    //                 }
    //             } catch (error) {
    //                 setLoading(false)
    //                 Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
    //             }
    //         }, 1500),
    //     [],
    // );

    useEffect(() => {
        getUrlMaster();
    }, []);

    return (
        <>
            <BackdropLoad loading={loading} />
            <UrlCard
                header={headerTable.manage_url}
                data={data}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleClickOpenAdd={handleClickOpenAdd}
                handleClickOpenEdit={handleClickOpenEdit}
            />
            <DialogAdd
                open={openAdd}
                handleClose={handleCloseAdd}
                handleAdd={handleAdd}
                name={name}
                setName={setName}
                data={data}
                path={path}
                setPath={setPath}
                type={type}
                setType={setType}
            />
            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                handleUpdate={handleUpdate}
                name={name}
                setName={setName}
                data={data}
                path={path}
                setPath={setPath}
                type={type}
                setType={setType}
                urlId={urlId}
            />
        </>
    );
}
export default ManageUrl;
