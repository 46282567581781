import React, { useEffect, useState } from 'react';

import {
    Box,
    Container,
    FormControl,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { DASHBOARDPAYMENT, POST } from '../../service';
import { Dashboard } from '../../untils/icons';
import LeafletMapComponent from './DashboardPayment/LeafletMapComponent';
import LineChartPayment from './DashboardPayment/LineChartPayment';
import BarChartVertical from './DashboardPayment/BarChartVertical';
import { fixAmount, tofieds } from '../../untils/shortcut';

function DashboardPayment() {

    const [dateStart, setDateStart] = useState(dayjs().month(9).subtract(1, 'year').startOf('month'));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    const [cleared, setCleared] = useState(false);
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [selectTambon, setSelectTambon] = useState('');
    const [data, setData] = useState([]);

    const getDashboardPayment = async () => {
        try {
            let res = await POST(DASHBOARDPAYMENT, { date_start: dateStart, date_end: dateEnd });
            if (res.success) {
                setSubdistrictObj(res.result.features);
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    const getDataChart = async () => {
        try {
            let res = await POST(DASHBOARDPAYMENT, { date_start: dateStart, date_end: dateEnd, tumbon: selectTambon });
            if (res.success) {
                setData(res.result);
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    useEffect(() => {
        getDashboardPayment();
    }, [dateStart, dateEnd]);

    // useEffect(() => {
    //     getDataChart();
    // }, [dateStart, dateEnd, selectTambon]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={8} sm={6} md={6} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize='medium' />
                                <Typography className="main-header">
                                    Dashboard การเงิน
                                </Typography>
                            </Stack>
                        </Grid>

                        {/* Select Data */}
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={6} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                value={dateStart}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                onChange={(e) => setDateStart(e)}
                                                views={['year', 'month']}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateEnd}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                onChange={(e) => setDateEnd(e)}
                                                views={['year', 'month']}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={3} direction="row">
                                {color_level.map((el, ind) =>
                                    <Grid key={ind + 1}>
                                        <Stack direction="row" spacing={1} className='flex-start'>
                                            <Box className="box-level" sx={{ backgroundColor: el.color }} />
                                            <Typography>{el.label !== 0 && `>`} {fixAmount(el.label)}</Typography>
                                        </Stack>
                                    </Grid>
                                )}
                            </Stack>
                        </Grid>

                        {/* MAP */}
                        <Grid item xs={12}>
                            <LeafletMapComponent subdistrictObj={subdistrictObj} setSelectTambon={setSelectTambon} />
                        </Grid>

                        {/* Chart */}
                        <Grid item xs={12}>
                            <LineChartPayment information={data} />
                        </Grid>
                        <Grid item xs={12}>
                            <BarChartVertical information={data} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
        </Container>
    )
}

const color_level = [
    { label: 5000000, color: "#1a237e" },
    { label: 999999, color: "#0d47a1" },
    { label: 99999, color: "#8bc34a" },
    { label: 9999, color: "#b71c1c" },
    { label: 1, color: "#f44336" },
    { label: 0, color: "#000" },
]

export default DashboardPayment;