import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, Grid, Typography } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BarChartDashboard({ death }) {

    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
            },
            legend: {
                display: false
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    pinch: {
                        enabled: true
                    },
                    wheel: {
                        enabled: true
                    },
                    mode: 'xy',  // Enable both x and y zooming
                }
            }
        },

    };

    const labels = death.map((e) => e.cdeath_a_th !== "" ? e.cdeath_a_th.replace("โรงพยาบาลส่งเสริมสุขภาพตำบล", 'รพ. สต.') : "ไม่ระบุ");

    const data = {
        labels,
        datasets: [
            {
                label: 'จำนวนครั้ง',
                data: death.map((e) => e.death),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <Grid container columns={12}>
            {/* <Grid item xs={12} >
                <Box className="census-info">
                    <Typography>
                        จำนวนการเข้ารับบริการแยกตามหน่วยบริการ (ครั้ง)
                    </Typography>
                </Box>
            </Grid> */}
            <Grid item xs={12} className="flex-center" style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px', width: '100%' }}>
                {death.length > 0 ?
                    <Bar data={data} options={options} />
                    :
                    <Box className="census-info-empty">
                        <Typography>
                            * ไม่มีข้อมูล *
                        </Typography>
                    </Box>
                }
            </Grid>
        </Grid>
    );
}