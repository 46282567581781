import React, { useEffect, useMemo, useState } from "react";
import { headerTable } from "../../../untils/static";
import GridCard from "./component/GridCard";
import DialogAdd from "./component/DialogAdd";
import DialogEdit from "./component/DialogEdit";
import Swal from "sweetalert2";
import { ADDBIRTHCONTROL, GETBIRTHCONTROL, GETBIRTHCONTROLBY, POST, UPDATEBIRTHCONTROL, GETBIRTHCONTROLMASTER, DELETEBIRTHCONTROL, GET, DELETECONTRACEPTIVE } from "../../../service";
import { debounce } from "@mui/material";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad";
import { getToken } from '../../../untils/shortcut';


function ContraceptivePill() {
    const profile = getToken(localStorage.getItem("profile"));

    const [data, setData] = useState([]);
    const [dataMaster, setDataMaster] = useState([]);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [birthControlId, setBirthControlId] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [birthMasterId, setBirthMasterId] = useState('')

    const getBirthControlMaster = async () => {
        try {
            setLoading(true);
            let res = await GET(GETBIRTHCONTROLMASTER);
            if (res.success) {
                setDataMaster(res.result)
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataBirthControl = async () => {
        try {
            setLoading(true);
            let res = await POST(GETBIRTHCONTROL, { text: search });
            if (res.success) {
                setData(res.result)
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getDataBirthControlById = async (id) => {
        try {
            setLoading(true);
            let res = await POST(GETBIRTHCONTROLBY, { birth_control_id: id });
            if (res.success) {
                setBirthControlId(res.result.birth_control_id)
                setCode(res.result.birth_control_code)
                setName(res.result.birth_control_name)
                setBirthMasterId(res.result.birth_control_master_id)
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleAdd = async () => {
        try {
            setLoading(true);
            let res = await POST(ADDBIRTHCONTROL, { hcode: profile.location_code, birth_control_name: name, birth_control_code: code, birth_control_master_id: birthMasterId });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `เพิ่มข้อมูลสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleUpdate = async (id) => {
        try {
            setLoading(true);

            let data = [];

            data.push({
                birth_control_name: name,
                birth_control_code: code,
                birth_control_master_id: birthMasterId,
            })

            let res = await POST(UPDATEBIRTHCONTROL, { birth_control_id: id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleDelete = ( hcode, birth_control_id ) => {
        try {
            setLoading(true);
            // console.log(res);
                setLoading(false);
                Swal.fire({
                    title: "ลบข้อมูล",
                    text: "คุณต้องการลบข้อมูลหรือไม่?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน",
                    confirmButtonColor: "green",
                    cancelButtonText: "ยกเลิก",
                    reverseButtons: true,
                }).then( async (result) => {
                    if (result.isConfirmed) {
                        let res = await POST(DELETECONTRACEPTIVE, { hcode, birth_control_id });
                        if (res.success){
                            setLoading(false);
                            getDataBirthControl();
                        } else {
                            setLoading(false);
                            Swal.fire({
                                 icon: "warning",
                                 text: `${res.message}`,
                                 showConfirmButton: true,
                            });
                       }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire({
                            text: "ยกเลิกรายการแล้ว",
                            confirmButtonText: "ตกลง",
                        });
                    }
                });
      } catch (error) {
           setLoading(false);
           Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
           });
      }
 }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpenAdd = () => {
        setName('')
        setCode('')
        setBirthMasterId('')
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setName('')
        setCode('')
        setBirthMasterId('')
        setOpenAdd(false);
    };

    const handleClickOpenEdit = (id) => {
        getDataBirthControlById(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    setLoading(true)
                    const payload = { text: request };
                    let res = await POST(GETBIRTHCONTROL, payload)
                    if (res.success) {
                        setData(res.result)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        Swal.fire({
                            icon: 'warning',
                            text: `${res.message}`,
                            showConfirmButton: true,
                        })
                    }
                } catch (error) {
                    setLoading(false)
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500),
        [],
    );

    useEffect(() => {
        getBirthControlMaster();
    }, []);

    useEffect(() => {
        if (search.length > 3) {
            fetch(search);
        } else if (!search) {
            getDataBirthControl();
            return undefined;
        }
    }, [search, fetch]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <GridCard
                header={headerTable.contraceptive_pill_tbl}
                data={data}
                search={search}
                setSearch={setSearch}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleClickOpenAdd={handleClickOpenAdd}
                handleClickOpenEdit={handleClickOpenEdit}
                handleDelete={handleDelete}
            />
            <DialogAdd
                open={openAdd}
                handleClose={handleCloseAdd}
                setName={setName}
                setCode={setCode}
                name={name}
                code={code}
                handleAdd={handleAdd}
                dataMaster={dataMaster}
                setBirthMasterId={setBirthMasterId}
                birthMasterId={birthMasterId}
            />
            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                setName={setName}
                setCode={setCode}
                name={name}
                code={code}
                handleUpdate={handleUpdate}
                birthControlId={birthControlId}
                setBirthMasterId={setBirthMasterId}
                birthMasterId={birthMasterId}
                dataMaster={dataMaster}
            />
        </>
    );
}

export default ContraceptivePill;