import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { BtnLoading, ButtonTealSubmit } from "../../../Theme.jsx";

function SettingsCard({
    handleClickShowPassword,
    handleMouseDownPassword,
    handleVerify,
    handleSubmit,
    setUsername,
    setPassword,
    setHcode,
    username,
    password,
    hcode,
    showPassword,
    loadingBtn,
    message,
    status,
}) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine" >
                                <Stack spacing={2} width="100%">
                                    <Typography className="main-header"                                        >
                                        Mohpromt
                                    </Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} >
                                            <TextField
                                                fullWidth
                                                label="ชื่อผู้ใช้งาน"
                                                name="username"
                                                type="text"
                                                value={username}
                                                variant="outlined"
                                                onChange={(e) => setUsername(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} >
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    name="password"
                                                    value={password}
                                                    variant="outlined"
                                                    onChange={(e) => setPassword(e.currentTarget.value)}
                                                    type={showPassword ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item my={2} xs={12} >
                                            <TextField
                                                fullWidth
                                                label="Hcode"
                                                name="hcode"
                                                type="text"
                                                value={hcode}
                                                variant="outlined"
                                                onChange={(e) => setHcode(e.currentTarget.value)}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2.5}>
                                            <BtnLoading
                                                // fullWidth
                                                onClick={handleVerify}
                                                loading={loadingBtn}
                                                sx={{ p: 1 }}
                                            >
                                                ตรวจสอบรหัสใช้งาน
                                            </BtnLoading>
                                        </Grid>
                                        {message && (
                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                <Typography>:</Typography>
                                            </Grid>
                                        )}
                                        <Grid item my={2} xs={7} sm={9} md={8.5}>
                                            <Typography color={status === true ? "green" : "red"}>{message}</Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item my={2} xs={12} className="flex-end">
                        <ButtonTealSubmit onClick={handleSubmit} disabled={loadingBtn}>
                            บันทึกข้อมูล
                        </ButtonTealSubmit>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default SettingsCard;
