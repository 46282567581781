import React, { useState } from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@mui/material";

import {
    ButtonTeal,
} from '../../../../Theme'

import {
    ListIcon,
} from '../../../../untils/icons';

import GridTableList from './GridTableList';
import { headerTable } from '../../../../untils/static';

function ListReportLocation() {

    const [state, setState] = useState({
        location: '',
    });

    const handleChangeLocation = (event) => {
        setState({
            ...state,
            location: event.target.value,
        });
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <ListIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายงานสรุปรายการเวชภัณฑ์ (แยกตาม รพ.สต.)
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-start" mt={3} >
                        <Grid container columns={12} spacing={2} className='flex-start' width={{ xs: "100%", sm: "80%", md: "50%" }}>
                            <Grid item xs={12} sm={2} md={2} mr={-4} display={{ xs: "none", sm: "block" }}>
                                <Typography >
                                    ค้นหา :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} md={4}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel >เลือกสถานพยาบาล</InputLabel>
                                    <Select
                                        id="lacation"
                                        value={state.location}
                                        label="เลือกสถานพยาบาล"
                                        onChange={handleChangeLocation}
                                    >
                                        {list_lacation.map((el, i) =>
                                            <MenuItem key={i + 1} value={el.value}>{el.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <ButtonTeal fullWidth>
                                    ค้นหา
                                </ButtonTeal>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5} className="flex-center" >
                        <GridTableList header={headerTable.report_location} data={rows} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListReportLocation;

function createData(loca_id, location_name, amount) {
    return { loca_id, location_name, amount };
}

const rows = [
    createData(1, 'โรงพยาบาล A', 5,),
    createData(2, 'โรงพยาบาล B', 15),
];


const list_lacation = [
    {
        name: 'LacationA',
        label: 'Lacation A',
        value: 'LacationA'
    },
    {
        name: 'LacationB',
        label: 'Lacation B',
        value: 'LacationB'
    },
    {
        name: 'LacationC',
        label: 'Lacation C',
        value: 'LacationC'
    },
    {
        name: 'LacationD',
        label: 'Lacation D',
        value: 'LacationD'
    }
];