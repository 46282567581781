import { Box, Grid, Typography } from "@mui/material";
import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export default function LineChartPayment({ information }) {
     const options = {
          responsive: true,
          interaction: {
               mode: "index",
               intersect: false,
          },
          stacked: false,
          plugins: {
               datalabels: false,
               title: {
                    display: true,
               },
          },
          scales: {
               y: {
                    type: "linear",
                    display: true,
                    position: "left",
               },
          },
     };

     const labels = ["ต.ค.", "พ.ย.", "ธ.ค.", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย."];

     const data = {
          labels,
          datasets: [
               {
                    label: "จำนวนเงิน",
                    // data: information.data,
                    data: [10000000, 10000000, 10000, 1000, 10000000, 10000001, 100],
                    borderColor: "rgb(0, 140, 238)",
                    backgroundColor: "rgba(0, 140, 238, 0.5)",
               },
          ],
     };
     return (
          <Grid container columns={12}>
               <Grid item xs={12} className="census-info-chart-h">
                    {/* {information.length > 0 ?
                         <Line data={data} options={options} />
                         :
                         <Box className="census-info-empty">
                              <Typography>
                                   * ไม่มีข้อมูล *
                              </Typography>
                         </Box>
                    } */}
                    <Line data={data} options={options} />
               </Grid>
          </Grid>
     );
}
