import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import React from 'react';
import { CssSelect } from "../../../Theme";
import thai_provinces from './thai_provinces.json';

function GridSearch({
    selectProvince,
    selectAmphure,
    yearsData,
    year,
    province,
    setYear,
    setProvince,
    amphure,
    setAmphure,
    dataAmphure,
    dataTambon,
    tambon,
    setTambon,
    setKhet,
    khet
}) {

    return (
        <Box className="paper-search">
            <Grid container columns={12} spacing={2}>
                <Grid item xs={12} sm={6} md={2.4}>
                    <Box className="box-inner" >
                        <FormControl variant="standard" fullWidth >
                            <InputLabel shrink className="header-search">
                                ปีงบประมาณ
                            </InputLabel>
                            <Select
                                required
                                id="year"
                                name="year"
                                displayEmpty
                                input={<CssSelect />}
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            >
                                <MenuItem value='' disabled>
                                    กรุณาเลือกปีงบประมาณ
                                </MenuItem>
                                {yearsData.map((el) => (
                                    <MenuItem key={el.value} value={el.value}>
                                        {el.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4} >
                    <Box className="box-inner">
                        <FormControl variant="standard" fullWidth >
                            <InputLabel shrink className="header-search">
                                เขต
                            </InputLabel>
                            <Select
                                required
                                id="khet"
                                name="khet"
                                displayEmpty
                                input={<CssSelect />}
                                value={khet}
                                onChange={(e) => setKhet(e.target.value)}
                            >
                                <MenuItem value='' disabled>
                                    กรุณาเลือกเขต
                                </MenuItem>
                                {/* {yearsData.map((el) => (
                                    <MenuItem key={el.value} value={el.value}>
                                        {el.value}
                                    </MenuItem>
                                ))} */}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={2.4}>
                    <Box className="box-inner">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink className="header-search">
                                จังหวัด
                            </InputLabel>
                            <Select
                                required
                                id="province"
                                name="province"
                                displayEmpty
                                input={<CssSelect />}
                                value={province}
                                onChange={(e) => setProvince(selectProvince(e))}
                            >
                                <MenuItem value='' disabled>
                                    กรุณาเลือกจังหวัด
                                </MenuItem>
                                {thai_provinces.map((el) => (
                                    <MenuItem key={el.id} value={el.name_th}>
                                        {el.name_th}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={2.4}>
                    <Box className="box-inner">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink className="header-search">
                                อำเภอ
                            </InputLabel>
                            <Select
                                required
                                disabled={!province && true}
                                id="amphure"
                                name="amphure"
                                displayEmpty
                                input={<CssSelect />}
                                value={amphure}
                                onChange={(e) => setAmphure(selectAmphure(e))}
                            >
                                <MenuItem value='' disabled>
                                    กรุณาเลือกอำเภอ
                                </MenuItem>
                                {dataAmphure.map((el) => (
                                    <MenuItem key={el.id} value={el.name_th}>
                                        {el.name_th}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={2.4}>
                    <Box className="box-inner">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink className="header-search">
                                ตำบล
                            </InputLabel>
                            <Select
                                required
                                disabled={!amphure && true}
                                id="tambon"
                                name="tambon"
                                displayEmpty
                                input={<CssSelect />}
                                value={tambon}
                                onChange={(e) => setTambon(e.target.value)}
                            >
                                <MenuItem value='' disabled>
                                    กรุณาเลือกตำบล
                                </MenuItem>
                                {dataTambon.map((el) => (
                                    <MenuItem key={el.id} value={el.name_th}>
                                        {el.name_th}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default GridSearch;

