import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";

import {
    EditNoteDrawerIcon,
    CalendarIcon,
    LocationIcon,
    Approve,
    Confirm,
} from '../../../../untils/icons';

import GridTableDetail from './GridTableDetail';

import {
    Item,
    ButtonTeal,
} from '../../../../Theme'

function GridDetail(props) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายละเอียดรายการเบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-end" >
                        <ButtonTeal >
                            <Confirm fontSize='small' />&nbsp;
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                ยืนยันรายการ
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                    <Grid item xs={12} mt={5} className="flex-start">
                        <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>

                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <Approve size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">เลขคำสั่ง</Typography>
                                        <Typography color="var(--teal-A800)">00-00000</Typography>
                                    </Grid>
                                </Stack>
                            </Item><Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <LocationIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">สถานพยาบาล</Typography>
                                        <Typography color="var(--teal-A800)">โรงพยาบาล A</Typography>
                                    </Grid>
                                </Stack>
                            </Item>
                            <Item elevation={3}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <CalendarIcon size='larg' />
                                    <Grid>
                                        <Typography fontWeight="bold" color="var(--teal-A800)">วันที่ทำรายการ</Typography>
                                        <Typography color="var(--teal-A800)">วว/ดด/ปปปป 00:00:00</Typography>
                                    </Grid>
                                </Stack>
                            </Item>

                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} my={5}>
                        <GridTableDetail header={props.header} data={props.data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default GridDetail;

