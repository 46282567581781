import React, { useEffect, useState } from "react";

import { Grid, Box, Paper, Typography, Stack, MenuItem, Select, FormControl, InputLabel, TextField } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { ClaimIcon, SendIcn } from "../../../../untils/icons";

import { ButtonTeal, ButtonConfirm, ItemDashboard, ItemDashboard_blue, ItemDashboard_purple, ItemDashboard_green, ItemDashboard_red } from "../../../../Theme";
import GridTable from "./GridTable";
import dayjs from "dayjs";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { AccessTime, Cancel, CheckCircle, Error, Pending } from "@mui/icons-material";

function ClaimCard({
     dateStart,
     dateEnd,
     setDateEnd,
     setDateStart,
     header,
     data,
     handleChangeCheckbox,
     checkAll,
     handleSendClaim,
     setStatus,
     status,
     search,
     setSearch,
     handleStatusClaim,
}) {
     // require('dayjs/locale/th')
     const [cleared, setCleared] = useState(false);
     const len = data.filter((e) => e.status === 1);

     useEffect(() => {
          if (cleared) {
               const timeout = setTimeout(() => {
                    setCleared(false);
               }, 1500);

               return () => clearTimeout(timeout);
          }
          return () => {};
     }, [cleared]);

     return (
          <Box display="flex" justifyContent="center">
               <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                         <Grid item xs={12} mt={1} mb={3} className="flex-start">
                              <Stack direction="row" spacing={2}>
                                   <ClaimIcon fontSize="medium" />
                                   <Typography
                                        className="main-header"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://claim-nhso.moph.go.th/nhso/")}
                                   >
                                        ส่งออกข้อมูลวัคซีน
                                   </Typography>
                              </Stack>
                         </Grid>

                         <Grid item xs={12} className="flex-start">
                            <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                                   <ItemDashboard_blue elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Grid item xs={12}>
                                            <Typography className="font-dashboard-white">รอการตรวจสอบ</Typography>
                                            <Typography className="font-dashboard-white">100</Typography>

                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                        <AccessTime transform="scale(2)" style={{ color: 'white' }}/>
                                    </Stack>
                                   </ItemDashboard_blue>
                                   <ItemDashboard_purple elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Grid item xs={12} >
                                            <Typography className="font-dashboard-white">กำลังตรวจสอบ</Typography>
                                            <Typography className="font-dashboard-white">100</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countService ? dataValueService.countService : 0)}</Typography> */}
                                        </Grid>
                                             <Pending transform="scale(2)" style={{ color: 'white' }} />
                                    </Stack>
                                </ItemDashboard_purple>
                                <ItemDashboard_green elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Grid item xs={12}>
                                            <Typography className="font-dashboard-white">ผ่านการตรวจสอบ</Typography>
                                            <Typography className="font-dashboard-white">100</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                        <CheckCircle transform="scale(2)" style={{ color: 'white' }} />
                                    </Stack>
                                </ItemDashboard_green>
                                <ItemDashboard_red elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <Grid item xs={12}>
                                            <Typography className="font-dashboard-white">ไม่ผ่านการตรวจสอบ</Typography>
                                            <Typography className="font-dashboard-white">100</Typography>
                                            {/* <Typography className="font-dashboard">{fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}</Typography> */}
                                        </Grid>
                                        <Cancel transform="scale(2)" style={{ color: 'white' }} />
                                    </Stack>
                                </ItemDashboard_red>
                            </Stack>
                        </Grid>

                         <Grid item xs={12} className="flex-end">
                              {status > 2 && (
                                   <ButtonConfirm onClick={handleStatusClaim} sx={{ mr: 2 }}>
                                        <CreditScoreIcon fontSize="small" />
                                        &nbsp;
                                        <Typography sx={{ cursor: "pointer" }}>ติดตามการเคลม</Typography>
                                   </ButtonConfirm>
                              )}
                              {status <= 2 && (
                                   <>
                                        <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                                        <ButtonConfirm onClick={handleStatusClaim} sx={{ mr: 2 }}>
                                             <CreditScoreIcon fontSize="small" />
                                             &nbsp;
                                             <Typography sx={{ cursor: "pointer" }}>ติดตามการเคลม</Typography>
                                        </ButtonConfirm>
                                        <ButtonTeal onClick={handleSendClaim}>
                                             <SendIcn fontSize="small" />
                                             &nbsp;
                                             <Typography sx={{ cursor: "pointer" }}>ส่งออกข้อมูล</Typography>
                                        </ButtonTeal>
                                   </>
                              )}
                         </Grid>
                         <Grid item xs={12} sm={12} md={12}>
                              <Grid container columns={12} spacing={3} className="flex-start">
                                   <Grid item xs={6} sm={6} md={4}>
                                        <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                             <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                                  <Typography mt={2} color="var(--teal-A800)">
                                                       จนถึง :
                                                  </Typography>
                                             </Box>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                                       <DatePicker
                                                            // fullWidth
                                                            value={dateStart}
                                                            slotProps={{
                                                                 field: { clearable: true, onClear: () => setCleared(true) },
                                                            }}
                                                            label="วันที่เริ่มต้น"
                                                            format="DD/MM/YYYY"
                                                            onChange={(e) => setDateStart(e)}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={6} sm={6} md={4}>
                                        <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                             <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                                  <Typography mt={2} color="var(--teal-A800)">
                                                       จนถึง :
                                                  </Typography>
                                             </Box>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                                       <DatePicker
                                                            fullWidth
                                                            value={dateEnd}
                                                            slotProps={{
                                                                 field: { clearable: true, onClear: () => setCleared(true) },
                                                            }}
                                                            label="วันที่สิ้นสุด"
                                                            format="DD/MM/YYYY"
                                                            onChange={(e) => setDateEnd(e)}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={4} my={2}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกสถานะเคลม</InputLabel>
                                             <Select
                                                  name=""
                                                  value={status}
                                                  label="เลือกสถานะเคลม"
                                                  displayEmpty
                                                  required
                                                  onChange={(e) => setStatus(e.target.value)}
                                             >
                                                  {dataPreAudit &&
                                                       dataPreAudit.map((el, i) => (
                                                            <MenuItem key={i + 1} value={el.value} disabled={el.disabled}>
                                                                 {el.label}
                                                            </MenuItem>
                                                       ))}
                                             </Select>
                                        </FormControl>
                                   </Grid>
                              </Grid>
                         </Grid>
                         <Grid item xs={12}>
                              <TextField
                                   fullWidth
                                   id="search"
                                   label="ค้นหารายการ"
                                   type="search"
                                   variant="outlined"
                                   size="medium"
                                   onChange={(e) => setSearch(e.target.value)}
                                   value={search}
                              />
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} mb={5}>
                              <GridTable
                                   header={header}
                                   data={data}
                                   handleChangeCheckbox={handleChangeCheckbox}
                                   checkAll={checkAll}
                                   status={status}
                              />
                         </Grid>
                         {status <= 2 && (
                              <>
                                   <Grid item xs={12} className="flex-end">
                                        <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                                        <ButtonTeal onClick={handleSendClaim}>
                                             <SendIcn fontSize="small" />
                                             &nbsp;
                                             <Typography sx={{ cursor: "pointer" }}>ส่งออกข้อมูล</Typography>
                                        </ButtonTeal>
                                   </Grid>
                              </>
                         )}
                    </Grid>
               </Paper>
          </Box>
     );
}

export default ClaimCard;

// const dataClaim = [
//      { label: "เลือกประเภทสิทธิ์", value: "", disabled: true },
//      { label: "สิทธ์องค์กรการปกครองส่วนท้องถิ่น (อปท.)", value: 1 },
//      { label: "สิทธิ์บัตรทอง", value: 2 },
// ];

const dataPreAudit = [
     { label: "เลือกสถานะเคลม", value: "", disabled: true },
     { label: "รอส่ง", value: 1 },
     { label: "ส่งไม่ผ่าน", value: 2 },
     { label: "รอการตรวจสอบ", value: 3 },
     { label: "กำลังตรวจสอบ", value: 4 },
     { label: "ผ่านการตรวจสอบ", value: 5 },
     { label: "ไม่ผ่านการตรวจสอบ", value: 6 },
     // { label: "ผ่าน", value: 1 },
     // { label: "ไม่ผ่าน", value: 2 },
     // { label: "ทั้งหมด", value: 3 },
];
