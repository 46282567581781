import React, { useRef } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";

import { CloseIcn } from '../../../../untils/icons';
import { ButtonTeal } from '../../../../Theme';

export default function DialogAdd({
    open,
    handleClose,
    setCode,
    code,
    handleAdd,
    dataMaster,
    setName,
    name,
    setPregnantMasterId,
    pregnantMasterId,
}) {

    const formRef = useRef();

    const checkValidation = () => {
        if (formRef.current.reportValidity()) {
            handleClose();
            handleAdd();
        }
        // console.log(formRef.current.reportValidity());
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            className="bg-dialog"
        >
            <Box className='bg-gradiant'>
                <DialogActions className="flex-end">
                    <CloseIcn className="close-icn" onClick={handleClose} />
                </DialogActions>
                <DialogTitle className="text-header">
                    เพิ่มยาสำหรับหญิงตั้งครรภ์
                </DialogTitle>
                <DialogContent className="flex-center">
                    <form ref={formRef} className="flex-center">
                        <DialogContentText mt={2} className="container-bg" component={'span'}>
                            <Grid container columns={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="รหัสเวชภัณฑ์"
                                        name="code"
                                        variant="outlined"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true}>
                                        <InputLabel>เลือกเวชภัณฑ์</InputLabel>
                                        <Select
                                            name="เวชภัณฑ์"
                                            value={pregnantMasterId}
                                            label="เลือกเวชภัณฑ์"
                                            required
                                            onChange={(e) => setPregnantMasterId(e.target.value)}
                                        >
                                            {dataMaster &&
                                                dataMaster.map((el, i) => (
                                                    <MenuItem key={i + 1} value={el.drug_master_id}>
                                                        {el.drug_master_type}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อเวชภัณฑ์"
                                        name="name"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form >
                </DialogContent>
                <DialogActions className="flex-center">
                    <ButtonTeal
                        sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                        onClick={checkValidation}

                    // onClick={() => {
                    //     handleClose();
                    //     handleAdd();
                    // }}
                    >
                        เพิ่มข้อมูล
                    </ButtonTeal>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
