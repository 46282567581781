import React from 'react'

import {
    Container,
    Typography,
    Stack,
    CardContent,
    Card,
    Grid,
    Box,
} from "@mui/material";

function Error401() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box
                className="flex-center"
                height="90vh"
            >
                <Grid container columns={12} className="flex-center">
                    <Grid item my={5} xs={12} className="flex-center">
                        <Card sx={{ minWidth: 345 }} >
                            <CardContent sx={{ p: 5 }} className="card-bg">
                                <Stack width="100%" className="flex-center" spacing={2}>
                                    <Typography fontSize={24} className="text-subtitle" >
                                        401 - Unautorized
                                    </Typography>
                                    <Typography className="text-subtitle">
                                        You are not authorized to access this page.
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Error401;