import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useState } from "react";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function BarChartTopDiagnose({ topData }) {

    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const options = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
            },
            legend: {
                display: false
            },
        },
    };

    const labels = topData.map((e) => e.diagcode_th !== "" ? e.diagcode_th.replace("โรงพยาบาลส่งเสริมสุขภาพตำบล", 'รพ. สต.') : "ไม่ระบุ");
    const data = {
        labels,
        datasets: [
            {
                label: 'ร้อยละ',
                data: topData.map((e) => e.count),
                // backgroundColor: "#48A6BF",
                // borderColor: "#48A6BF",
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <Grid container columns={12} >
            <Box width="100%" m={2} className="flex-end">
                <Box width={{ xs: "100%", sm: "50%", md: "25%" }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">All</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={age}
                            label="Age"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Grid item xs={12} className="flex-center">
                <Bar data={data} options={options} style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '500px', width: '100%' }} />
            </Grid>
        </Grid>
    );
}
