import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function QualityDataCard() {
  return (
    <Card sx={{ minWidth: '100%' , justifyContent: 'space-between' }}>
      <CardContent>
        <Typography variant="h3" component="div" align='center'>
            78.53%
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'row', align: 'center'}}>
          <Typography variant="body2" align='left'>
            ข้อมูลทั้งหมด
            <br />
            {'14,902,279'}
          </Typography>
          <Typography variant="body2" align='center'>
            คุณภาพของข้อมูล
            <br />
            {'11,703,221'}
          </Typography>
          <Typography variant="body2" align='right'>
            คิดเป็น %
            <br />
            {'78.53'}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
