import React, { useEffect, useState } from "react";

import { Grid, Box, Paper, Typography, Stack, MenuItem, Select, FormControl, InputLabel } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { HistoryIcon } from "../../../../untils/icons";

import GridTable from "./GridTable";
import dayjs from "dayjs";

function HistoryCard({ dateStart, dateEnd, setDateEnd, setDateStart, header, data }) {
     // require('dayjs/locale/th')
     const [cleared, setCleared] = useState(false);

     useEffect(() => {
          if (cleared) {
               const timeout = setTimeout(() => {
                    setCleared(false);
               }, 1500);

               return () => clearTimeout(timeout);
          }
          return () => { };
     }, [cleared]);

     return (
          <Box display="flex" justifyContent="center">
               <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                         <Grid item xs={12} mt={1} mb={3} className="flex-start">
                              <Stack direction="row" spacing={2}>
                                   <HistoryIcon fontSize="medium" />
                                   <Typography className="main-header">ประวัติการส่งเคลม</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12}>
                              <Grid container columns={12} spacing={3} className="flex-start">
                                   <Grid item xs={6} sm={6} md={4}>
                                        <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                             <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                                  <Typography mt={2} color="var(--teal-A800)">
                                                       จนถึง :
                                                  </Typography>
                                             </Box>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                                       <DatePicker
                                                            // fullWidth
                                                            value={dateStart}
                                                            slotProps={{
                                                                 field: { clearable: true, onClear: () => setCleared(true) },
                                                            }}
                                                            label="วันที่เริ่มต้น"
                                                            format="DD/MM/YYYY"
                                                            onChange={(e) => setDateStart(e)}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={6} sm={6} md={4}>
                                        <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                             <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                                  <Typography mt={2} color="var(--teal-A800)">
                                                       จนถึง :
                                                  </Typography>
                                             </Box>
                                             <FormControl fullWidth>
                                                  <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                                       <DatePicker
                                                            fullWidth
                                                            value={dateEnd}
                                                            slotProps={{
                                                                 field: { clearable: true, onClear: () => setCleared(true) },
                                                            }}
                                                            label="วันที่สิ้นสุด"
                                                            format="DD/MM/YYYY"
                                                            onChange={(e) => setDateEnd(e)}
                                                       />
                                                  </LocalizationProvider>
                                             </FormControl>
                                        </Stack>
                                   </Grid>
                                   <Grid item xs={12} sm={12} md={4}>
                                        <FormControl fullWidth>
                                             <InputLabel>เลือกสถานะเคลม</InputLabel>
                                             <Select
                                                  name=""
                                                  // value={}
                                                  label="เลือกสถานะเคลม"
                                                  displayEmpty
                                                  required
                                             >
                                                  {dataPreAudit &&
                                                       dataPreAudit.map((el, i) => (
                                                            <MenuItem key={i + 1} value={el.value} disabled={el.disabled}>
                                                                 {el.label}
                                                            </MenuItem>
                                                       ))}
                                             </Select>
                                        </FormControl>
                                   </Grid>
                              </Grid>
                         </Grid>
                         <Grid item xs={12} sm={12} md={12} my={5}>
                              <GridTable header={header} data={data} />
                         </Grid>
                    </Grid>
               </Paper>
          </Box>
     );
}

export default HistoryCard;

// const dataClaim = [
//      { label: "เลือกประเภทสิทธิ์", value: "", disabled: true },
//      { label: "สิทธ์องค์กรการปกครองส่วนท้องถิ่น (อปท.)", value: 1 },
//      { label: "สิทธิ์บัตรทอง", value: 2 },
// ];

const dataPreAudit = [
     { label: "เลือกสถานะเคลม", value: "", disabled: true },
     { label: "ผ่าน", value: 1 },
     { label: "ไม่ผ่าน", value: 2 },
     { label: "ทั้งหมด", value: 3 },
];
