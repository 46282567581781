import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from "react";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function BarChartVertical({ information }) {

    const options = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        // plugins: [ChartDataLabels],
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                // backgroundColor: function (context) {
                //     return context.dataset.backgroundColor;
                // },
                borderRadius: 4,
                // font: {
                //     weight: 'bold'
                // },
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "rgb(248,247,247,0.5)",
            }
        },
        legend: {
            display: false
        }
    };

    const labels = ["1 สัปดาห์", "2 สัปดาห์", "3 สัปดาห์", "4 สัปดาห์", "5 สัปดาห์", "6 สัปดาห์", "7 สัปดาห์"
        , "8 สัปดาห์", "9 สัปดาห์", "10 สัปดาห์", "11 สัปดาห์", "12 สัปดาห์"];

    const data = {
        labels,
        datasets: [
            {
                label: 'ร้อยละ',
                data: [4.63, 4.62, 4.61, 4.56, 4.53, 4.51, 4.47, 4.39],
                borderColor: '#008CEE',
                backgroundColor: '#008CEE',
            },
        ],
    };
    return (
        // <Grid container columns={12} spacing={2}>
        //     <Grid item xs={12} >
        //         <Box className="census-info-dashboard">
        //             <Typography>
        //                 ร้อยละหญิงตั้งครรภ์ได้รับการฝากครรภ์ครั้งแรกก่อนหรือเท่ากับ 12 สัปดาห์ ปีงบประมาณ 2567
        //             </Typography>
        //         </Box>
        //     </Grid>
        //     <Grid item xs={12} sx={{ height: 500, display: "flex", justifyContent: "center", alignItems: "center" }}>
        //         <Bar data={data} options={options} />
        //     </Grid>
        // </Grid>
        <Stack >
            <Box className="census-info-dashboard">
                <Typography>
                    ร้อยละหญิงตั้งครรภ์ได้รับการฝากครรภ์ครั้งแรกก่อนหรือเท่ากับ 12 สัปดาห์ ปีงบประมาณ 2567
                </Typography>
            </Box>
            <Grid className="census-info-chart-h" >
                <Bar data={data} options={options} className="census-info-chart" />
            </Grid>
        </Stack>
    );
}
