import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid, Typography } from "@mui/material";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export default function LineChartErrorData() {
    const options = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        stacked: false,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: '%ข้อมูลที่ผิดปกติภาพรวมรายแฟ้ม',
            },
        },
        scales: {
            y: {
                type: "linear",
                display: true,
                position: "left",
            },
        },
    };

    const labels = ["PROCEDURE_IPD", "CHARGE_IPD", "DRUG_IPD", "DIAGNOSIS_IPD", "NCDSCREEN", "ACCIDENT", "PRENATAL", "DRUG_OPD", "VILLAGE", "CHRONICFU", "HOME", "FP", "APPOINTMENT", "EPI", "SURVEILLANCE", "DISABILITY", "DEATH", "COMMUNITY_SERVICE", "LABOR", "ADDRESS", "ANC", "ADMISSION", "CHRONIC", "NEWBORN", "SPECIALPP", "PERSON", "CARD", "ICF", "PROCEDURE_OPD", "SERVICE", "DIAGNOSIS_OPD", "FUNCTIONAL", "NUTRITION", "DENTAL", "WOMEN", "DRUGALLERGY", "LABFU", "NEWBORNCARE", "PROVIDER", "REHABILITATION", "POSTNATAL", "COMMUNITY_ACTIVITY"];

    const data = {
        labels,
        datasets: [
            {
                label: "ปีงบ 2567",
                data: ["99.92", "99.73", "99.63", "99.5", "72.66", "70.15", "60.52", "53.92", "47.11", "37.02", "34.93", "31.25", "28.24", "27.5", "26.73", "24.95", "23.78", "19.96", "17.77", "16.08", "14.99", "14.69", "13.1", "13", "9.15", "4.85", "4.1", "3.89", "3.07", "3.07", "2.91", "2.33", "2.31", "1.76", "1.6", "1.58", "1.33", "1.28", "1.16", "0.65", "0.53", "0"],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };

    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12}>
                <Typography variant="h6" component="div" gutterBottom>
                %ข้อมูลที่ผิดปกติภาพรวมรายแฟ้ม
                </Typography>
            </Grid> */}
            <Grid item xs={12} style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <Line data={data} options={options} />
            </Grid>
        </Grid>
    );
}
