import React from 'react'
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";
import { useParams } from 'react-router-dom';

import {
    EditNoteDrawerIcon,
    CalendarIcon,
} from '../../../untils/icons';

import { headerTable } from "../../../untils/static"
import GridTableDetail from './GridTableDetail';

import {
    Item,
} from '../../../Theme'

function GridDetail({ banner }) {

    const params = useParams();

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} mt={1} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header">
                                รายละเอียดเลขคำสั่ง {params.order}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={5} className="flex-start">
                        <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                            {banner.map((e, i) =>
                                <Item elevation={3} key={i + 1}>
                                    <Stack direction="row" className="flex-start" spacing={3}>
                                        <CalendarIcon size='larg' />
                                        <Grid>
                                            <Typography fontWeight="bold" color="var(--teal-A800)">{e.label}</Typography>
                                            <Typography color="var(--teal-A800)">{e.detail}</Typography>
                                        </Grid>
                                    </Stack>
                                </Item>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={5}>
                        <GridTableDetail header={headerTable.history_detail} data={rows} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default GridDetail;

function createData(id, drug_name, type, pack, remain, lot_no, origin, destination, unit) {
    return { id, drug_name, type, pack, remain, lot_no, origin, destination, unit };
}

const rows = [
    createData(1, 'Amitriptyline 10 mg', 'เวชภัณฑ์ยา', 5, 3, '00-0000', 'โรงพยาบาลศูนย์', 'โรงพยาบาลส่วนตำบล', 'กล่อง'),
    createData(2, 'Alcohol 70% 450 ml', 'เวชภัณฑ์ยา', 15, 30, '00-0001', 'โรงพยาบาลศูนย์', 'โรงพยาบาลส่วนตำบล', 'ขวด'),
    createData(3, 'ถุงดำ', 'อื่นๆ', 50, 30, '00-0002', 'โรงพยาบาลศูนย์', 'โรงพยาบาลส่วนตำบล', 'ถุง'),

];