import { Box, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { BtnMap } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";
const latlong = window.location.origin.includes("kk")
     ? [16.43192755724492, 102.82856609781523]
     : window.location.origin.includes("mdh")
     ? [16.59738946623057, 104.51104253757008]
     : window.location.origin.includes("spb")
     ? [14.651579065119135, 99.93989999520554]
     : [16.43192755724492, 102.82856609781523]
function LeafletMapComponent({ subdistrictObj, setSelectTambon }) {
     // console.log("subdistrictObj", subdistrictObj);
     const [map, setMap] = useState(null);
     const [onselect, setOnselect] = useState({});

     const highlightFeature = (e) => {
          // console.log(e.target.getBounds().getCenter());
          let layer = e.target;
          const { tam_th, statement, hname } = e.target.feature.properties;

          setOnselect({
               Tumbon: tam_th,
               Statement: statement,
               Hname: hname,
          });

          if (statement > 0) {
               setSelectTambon(tam_th);
          }

          layer.setStyle({
               weight: 5,
               color: "#666",
               dashArray: "",
               fillOpacity: 0.7,
          });
          layer.bringToFront();
          // map.fitBounds(e.target.getBounds());
     };

     const popupFeature = (e) => {
          // console.log(e.target.getBounds().getCenter());
          let layer = e.target;
          const { statement, hname } = e.target.feature.properties;

          // const popupOptions = {
          //      minWidth: 100,
          //      maxWidth: 250,
          //      className: "popup-classname"
          // };

          // layer.bindPopup(() => {
          //      return `<b>${tofieds(statement)}</b>`;
          // }, popupOptions);

          if (statement > 0) {
               const popupOptions = {
                    minWidth: 100,
                    maxWidth: 250,
                    className: "popup-classname",
               };

               layer.bindTooltip(() => {
                    return `<b>${hname} <br> ${tofieds(statement)}</b>`;
               }, popupOptions).openTooltip();
          }
     };

     const handleAll = () => {
          setSelectTambon("");
          setOnselect({
               Tumbon: null,
               Statement: null,
          });
     };

     const onEachFeature = (feature, layer) => {
          layer.on({
               mouseover: popupFeature,
               click: highlightFeature,
          });
     };

     const mapPolygonColorToDensity = (d) => {
          return d > 5000000 ? "#1a237e" : d > 999999 ? "#0d47a1" : d > 99999 ? "#8bc34a" : d > 9999 ? "#b71c1c" : d > 1 ? "#f44336" : "#000";
     };

     const style = (feature) => {
          return {
               fillColor: mapPolygonColorToDensity(feature.properties.statement),
               weight: 1,
               opacity: 1,
               color: "white",
               dashArray: "2",
               fillOpacity: 0.5,
          };
     };

     return (
          <>
               <Grid container columns={12}>
                    <Grid item xs={12}>
                         <Box className="census-info" width="100%">
                              {onselect.Hname ? (
                                   <Stack spacing={1} className="flex-center" width="100%">
                                        <Typography>{onselect.Hname}</Typography>
                                        <Typography>จำนวน : {tofieds(onselect.Statement)} บาท</Typography>
                                   </Stack>
                              ) : (
                                   <Stack spacing={1} className="flex-center" width="100%">
                                        <Typography>กรุณาเลือกตำแหน่ง รพ.สต.</Typography>
                                        <Typography>จำนวน : 0 บาท</Typography>
                                   </Stack>
                              )}
                              <Box className="flex-end info-button">
                                   <BtnMap variant="outlined" onClick={handleAll}>
                                        ทั้งหมด
                                   </BtnMap>
                              </Box>
                         </Box>
                    </Grid>
                    <Grid item xs={12}>
                         <MapContainer
                              scrollWheelZoom={true}
                              center={latlong} // Set your initial map center coordinates
                              zoom={9} // Set your initial zoom level
                              className="mapContainer"
                              ref={setMap}
                         >
                              <TileLayer
                                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                   maxZoom={19}
                                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              />
                              {subdistrictObj.length > 0 && (
                                   <GeoJSON
                                        data={subdistrictObj}
                                        style={style}
                                        onEachFeature={onEachFeature}
                                        // onEachFeature={(feature, leafletLayer) => {
                                        //      const popupOptions = {
                                        //           minWidth: 100,
                                        //           maxWidth: 250,
                                        //           className: "popup-classname"
                                        //      };

                                        //      leafletLayer.bindTooltip(() => {
                                        //           return `<b>tooltip</b>`
                                        //      }, popupOptions).openTooltip();
                                        // }}
                                   />
                              )}
                         </MapContainer>
                    </Grid>
               </Grid>
          </>
     );
}

export default LeafletMapComponent;
