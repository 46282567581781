import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
    EditNoteDrawerIcon,
    AddItemIcon,
} from '../../../untils/icons';

import {
    ButtonTeal,
} from '../../../Theme';

import GridTableList from './GridTableList';

function ListRequisitionLocation({ header, data }) {

    const navigate = useNavigate();

    const handleRoute = (path) => {
        return () => {
            navigate(path);
        };
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={8} sm={6} md={6} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <EditNoteDrawerIcon fontSize='medium' />
                            <Typography className="main-header" >
                                เบิกจ่ายเวชภัณฑ์
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={6} className="flex-end" >
                        <ButtonTeal onClick={handleRoute(`Add`)}>
                            <AddItemIcon fontSize='small' />&nbsp;
                            <Typography sx={{ display: { xs: 'none', sm: 'block' }, cursor: "pointer" }} >
                                สร้างรายการเบิกเวชภัณฑ์ใหม่
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={5} className="flex-center" >
                        <GridTableList header={header} data={data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default ListRequisitionLocation;