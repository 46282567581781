import { Grid } from "@mui/material";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from "react";
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function BarChartTopDiagnose({ information }) {

    const options = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                display: function (context) {
                    return context.chart.isDatasetVisible(context.datasetIndex);
                },
                borderRadius: 4,
                align: "end",
                anchor: "end",
                color: "black",
                backgroundColor: "#BEBEBD",
            },
            legend: {
                display: false
            },
        },

    };

    const backgroundColors = ["#48A6BF", "#FFC400", "#FF6384", "#36A2EB", "#FF9F40", "#4BC0C0", "#9966FF"];
    const labels = ["เมืองมุกดาหาร", "นิคมคำสร้อย", "ดอนตาล", "ดงหลวง", "คำชะอี", "หว้านใหญ่", "หนองสูง"];

    const data = {
        labels,
        datasets: [
            {
                label: 'ปีงบ 2567',
                data: [31.5, 12.39, 15.09, 15.08, 11.82, 10.41, 10.38],
                backgroundColor: backgroundColors,
                borderColor: backgroundColors,
                // borderColor: 'rgb(61,90,254)',
                // backgroundColor: 'rgba(61,90,254, 0.5)',
            },
        ],
    };
    return (
        <Grid container columns={12}>
            <Grid item xs={12} className="flex-center" >
                <Bar data={data} options={options} style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '500px', width: '100%' }} />
            </Grid>
        </Grid>
    );
}