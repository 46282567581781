import drug from "../assets/images/list_alt.png";
import stock from "../assets/images/Delivery-Outline.png";
import transaction from "../assets/images/edit_note.png";
import device from "../assets/images/router.png";
import hospital from "../assets/images/domain_add.png";
import location from "../assets/images/add_home_work.png";
import vendor from "../assets/images/medical_services.png";
// import history from "../assets/images/history.png"
import user from "../assets/images/manage_accounts.png";
import report from "../assets/images/mandat-Outline.png";
// import approve from "../assets/images/pending_actions.png"
import role from "../assets/images/admin_icon.png";
import verified from "../assets/images/verified_user.png";
import dashboard from "../assets/images/dashboard.png";
import news from "../assets/images/feed.png";
import token from "../assets/images/token.png";
import medication from "../assets/images/medication.png";
import statement from "../assets/images/payments.png";
import setting from "../assets/images/settings.png"
import report_dashboard from "../assets/images/assessment.png"


export const headerTable = {
     stock_header: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "drug_code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 200,
               align: "left",
          },
          // {
          //     id: 'lot_no',
          //     label: 'Lot no.',
          //     minWidth: 100,
          //     align: 'center',
          // },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ (หน่วย)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ราคาต่อหน่วย (บาท)",
               minWidth: 100,
               align: "right",
          },
          // {
          //     id: 'date_in',
          //     label: 'วันที่เข้าระบบ',
          //     minWidth: 100,
          //     align: 'right',
          // },
          // {
          //     id: 'expire',
          //     label: 'วันหมดอายุ',
          //     minWidth: 100,
          //     align: 'right',
          // },
     ],
     requisition_header: [
          {
               id: "date",
               label: "วันที่ทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "type",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "file",
               label: "เอกสารที่เกี่ยวข้อง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียดรายการ",
               minWidth: 100,
               align: "center",
          },
     ],
     requisition_detail: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภทเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "expire",
               label: "วันหมดอายุ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวนเบิก",
               minWidth: 50,
               align: "right",
          },
     ],
     requisition_add_loca: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "remain",
               label: "จำนวนที่แนะนำ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "amount",
               label: "จำนวนที่ต้องการเบิก",
               minWidth: 100,
               align: "center",
          },
          {
               id: "difference",
               label: "ผลต่าง",
               minWidth: 50,
               align: "right",
          },
          {
               id: "delete",
               label: "ลบ",
               minWidth: 50,
               align: "center",
          },
     ],
     requisition_add_hos: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 50,
               align: "center",
          },
          {
               id: "remain",
               label: "จำนวนเวชภัณฑ์ในคงคลัง",
               minWidth: 50,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวนที่ขอเบิก",
               minWidth: 50,
               align: "center",
          },
          {
               id: "remain",
               label: "จำนวนที่สามารถให้เบิกได้",
               minWidth: 100,
               align: "center",
          },
     ],
     user_header: [
          {
               id: "date_created",
               label: " วันที่สร้าง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "username",
               label: "ชื่อผู้ใช้งาน",
               minWidth: 100,
               align: "left",
          },
          {
               id: "role",
               label: "บทบาท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "phone",
               label: "หมายเลขโทรศัพท์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "location",
               label: "สถานพยาบาล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 100,
               align: "center",
          },
          {
               id: "delete",
               label: "ลบ",
               minWidth: 100,
               align: "center",
          },
     ],
     role_header: [
          // {
          //     id: 'date_created',
          //     label: ' วันที่สร้าง',
          //     minWidth: 100,
          //     align: 'center',
          // },
          {
               id: "role",
               label: "บทบาท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "screen_permission",
               label: "หน้าที่จะแสดง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 100,
               align: "center",
          },
          {
               id: "delete",
               label: "ลบ",
               minWidth: 100,
               align: "center",
          },
     ],
     drug_header: [
          {
               id: "drug_name",
               label: " ชื่อเวชภัณฑ์ (EN)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "drug_name_thai",
               label: "ชื่อเวชภัณฑ์ (TH)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "left",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ราคาซื้อ (บาท)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "sell",
               label: "ราคาจำหน่าย (บาท)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "vendor_name",
               label: "บริษัทยา",
               minWidth: 100,
               align: "left",
          },
          {
               id: "vendor_country",
               label: "ประเทศผู้ผลิตยา",
               minWidth: 100,
               align: "left",
          },
          {
               id: "dispensing_unit",
               label: "หน่วยการจ่ายยา",
               minWidth: 100,
               align: "left",
          },
          {
               id: "tpu_id",
               label: "TPUID",
               minWidth: 100,
               align: "left",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "left",
          },
          // {
          //     id: 'edit',
          //     label: 'แก้ไข',
          //     minWidth: 50,
          //     align: 'center',
          // },
          // {
          //     id: 'delete',
          //     label: 'ลบ',
          //     minWidth: 50,
          //     align: 'center',
          // },
     ],
     history_header: [
          {
               id: "date_requisition",
               label: "วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date_recive_order",
               label: "วันที่รับรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date_recive_drug",
               label: "วันที่รับเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียดรายการ",
               minWidth: 100,
               align: "center",
          },
     ],
     dashboard_header: [
          {
               id: "INSCL",
               label: "INSCL",
               minWidth: 100,
               align: "left",
          },
          {
               id: "TYPE",
               label: "TYPE",
               minWidth: 100,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "DETAIL",
               minWidth: 100,
               align: "left",
          },
          {
               id: "bar",
               label: "",
               minWidth: 100,
               align: "center",
          },
     ],
     dashboardAll43_header: [
          {
               id: "all",
               label: "ระดับ ALL",
               minWidth: 100,
               align: "left",
          },
          {
               id: "CC",
               label: "ค่าเฉลี่ยของ ค่าเฉลี่ย CC",
               minWidth: 100,
               align: "right",
          },
          {
               id: "KPI",
               label: "ค่าเฉลี่ยของ ค่าเฉลี่ย KPI",
               minWidth: 100,
               align: "right",
          },
          {
               id: "KPI_CC",
               label: "ค่าเฉลี่ยของ ค่าเฉลี่ย KPI CC",
               minWidth: 100,
               align: "right",
          },
     ],
     history_detail: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
          {
               id: "origin",
               label: "ต้นทาง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "destination",
               label: "ปลายทาง",
               minWidth: 100,
               align: "center",
          },
     ],
     device: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "username",
               label: "ชื่อผู้ใช้งาน",
               minWidth: 200,
               align: "center",
          },
          {
               id: "location_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "phone",
               label: "หมายเลขโทรศัพท์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "mac_address",
               label: "รหัสเครื่อง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "action",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     approved: [
          {
               id: "date",
               label: "วันที่ทำรายการ",
               minWidth: 50,
               align: "center",
          },
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 200,
               align: "center",
          },
          {
               id: "remain",
               label: "จำนวนเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียดรายการ",
               minWidth: 100,
               align: "center",
          },
          // {
          //     id: 'action',
          //     label: 'การดำเนินการ',
          //     minWidth: 100,
          //     align: 'center',
          // },
     ],
     approved_detail: [
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "pack",
               label: "ขนาดเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภทเวชภัณฑ์",
               minWidth: 100,
               align: "left",
          },
          {
               id: "amount",
               label: "จำนวนเบิก",
               minWidth: 50,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วยนับ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "lot_no",
               label: "Lot No.",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount_confirm",
               label: "จำนวนที่เบิกได้",
               minWidth: 50,
               align: "center",
          },
          {
               id: "expire",
               label: "วันหมดอายุ",
               minWidth: 100,
               align: "center",
          },
     ],
     hospital: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "hoscode",
               label: "รหัสสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "hospital_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
     ],
     hospital_status: [
          // {
          //     id: 'number',
          //     label: 'ลำดับที่',
          //     minWidth: 50,
          //     align: 'center',
          // },
          // {
          //     id: 'hoscode',
          //     label: 'รหัสสถานพยาบาล',
          //     minWidth: 100,
          //     align: 'center',
          // },
          {
               id: "hospital_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "date",
               label: "วันที่ดึงข้อมูล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
     ],
     location: [
          // {
          //     id: 'number',
          //     label: 'ลำดับที่',
          //     minWidth: 50,
          //     align: 'center',
          // },
          {
               id: "loca_code",
               label: "รหัสสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "location_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "option",
               label: "รูปแบบการเคลม",
               minWidth: 100,
               align: "center",
          },
          {
               id: "action",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     vendor: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "vendor_name",
               label: "ชื่อบริษัท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "vendor_location",
               label: "สถานที่ตั้ง",
               minWidth: 200,
               align: "center",
          },
     ],
     dashboard_chart: [
          {
               id: "location",
               label: "สถานพยาบาล",
               minWidth: 150,
               align: "left",
          },
          {
               id: "tambon",
               label: "ตำบล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "money",
               label: "เงินโอนเข้าบัญชี",
               minWidth: 100,
               align: "right",
          },
     ],
     report_drug: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: " ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวน",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     report_drug_detail: [
          {
               id: "location_name",
               label: "ชื่อสถานพยาบาล",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date",
               label: " วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date_recive",
               label: " วันที่รับเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
     ],
     report_location: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "location_name",
               label: " ชื่อสถานพยาบาล",
               minWidth: 150,
               align: "center",
          },
          {
               id: "amount",
               label: "จำนวนเลขคำสั่ง",
               minWidth: 50,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     report_location_detail: [
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "amount",
               label: " จำนวน",
               minWidth: 50,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },

          {
               id: "date_recive",
               label: "วันที่รับเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 50,
               align: "center",
          },
     ],
     report_location_order: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
     ],
     report_approve: [
          {
               id: "location_name",
               label: " ชื่อสถานพยาบาล",
               minWidth: 150,
               align: "center",
          },
          {
               id: "order",
               label: "เลขคำสั่ง",
               minWidth: 100,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่ขอทำรายการ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     report_approve_detail: [
          {
               id: "number",
               label: "ลำดับที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "drug_name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 200,
               align: "center",
          },
          {
               id: "type",
               label: "ประเภท",
               minWidth: 100,
               align: "center",
          },
          {
               id: "pack",
               label: "ขนาดบรรจุ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "remain",
               label: "จำนวนคงเหลือ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "unit",
               label: "หน่วย",
               minWidth: 100,
               align: "center",
          },
          {
               id: "lot_no",
               label: "Lot no.",
               minWidth: 100,
               align: "center",
          },
     ],
     claim: [
          // {
          //     id: 'CID',
          //     label: 'CID',
          //     minWidth: 100,
          //     align: 'center',
          // },
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          // {
          //     id: "TITLE",
          //     label: "คำนำหน้าชื่อ",
          //     minWidth: 100,
          //     align: "left",
          // },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          // {
          //      id: "PERMITNO",
          //      label: "ตรวจสอบเลขยืนยันตัวตน",
          //      minWidth: 180,
          //      align: "left",
          // },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "status_claim",
               label: "สถานะการส่ง",
               minWidth: 150,
               align: "center",
          },

          // {
          //     id: 'lastname',
          //     label: 'นามสกุล',
          //     minWidth: 200,
          //     align: 'left',
          // },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          // {
          //      id: "HCODE",
          //      label: "HCODE",
          //      minWidth: 150,
          //      align: "center",
          // },
          // {
          //     id: 'DIAG',
          //     label: 'รหัสวินิจฉัยโรค (ICD)',
          //     minWidth: 150,
          //     align: 'center',
          // },
          // {
          //     id: 'odx_name',
          //     label: 'ชนิดของโรค',
          //     minWidth: 200,
          //     align: 'left',
          // },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
          // {
          //     id: 'LABTEST',
          //     label: 'ชื่อแลป',
          //     minWidth: 100,
          //     align: 'right',
          // },
          // {
          //     id: 'LABRESULT',
          //     label: 'ผลแลป',
          //     minWidth: 100,
          //     align: 'right',
          // },
          // {
          //     id: 'service',
          //     label: 'ค่าบริการ',
          //     minWidth: 100,
          //     align: 'right',
          // },
          //   {
          //        id: "detail",
          //        label: "รายละเอียด",
          //        minWidth: 100,
          //        align: "center",
          //   },
     ],
     claim_not: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "massage",
               label: "สปสช. ตอบกลับ",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          // {
          //      id: "status_claim",
          //      label: "สถานะการส่ง",
          //      minWidth: 150,
          //      align: "center",
          // },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_all: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "DETAIL",
               label: "อาการวินิจฉัย",
               minWidth: 350,
               align: "center",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 150,
               align: "left",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "massage",
               label: "สปสช. ตอบกลับ",
               minWidth: 150,
               align: "left",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "status_claim",
               label: "สถานะการส่ง",
               minWidth: 150,
               align: "center",
          },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_vaccine: [
          {
               id: "name",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "left",
          },
          {
               id: "birth",
               label: "อายุ (ปี)",
               minWidth: 80,
               align: "right",
          },
          {
               id: "status_c",
               label: "สถานะการเคลม",
               minWidth: 150,
               align: "center",
          },
          {
               id: "vaccinecode",
               label: "Vaccine Code",
               minWidth: 150,
               align: "center",
          },
          {
               id: "code_claim",
               label: "Code Claim",
               minWidth: 200,
               align: "center",
          },

          // {
          //      id: "DATEDX",
          //      label: "วันที่รับบริการ",
          //      minWidth: 150,
          //      align: "center",
          // },
          {
               id: "dateepi",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "datevacineexpire",
               label: "Vacine expire",
               minWidth: 150,
               align: "center",
          },
          {
               id: "visitno",
               label: "ลำดับเข้ารับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "lotno",
               label: "Lot No.",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_history: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "center",
          },
          {
               id: "TITLE",
               label: "คำนำหน้าชื่อ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "NAMEPAT",
               label: "ชื่อ - นามสกุล",
               minWidth: 200,
               align: "center",
          },
          {
               id: "DOB",
               label: "อายุ (ปี)",
               minWidth: 100,
               align: "right",
          },
          {
               id: "DATEDX",
               label: "วันที่รับบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "SEQ",
               label: "รหัสการบริการ",
               minWidth: 150,
               align: "center",
          },
          {
               id: "detail",
               label: "รายละเอียด",
               minWidth: 100,
               align: "center",
          },
     ],
     claim_detail: [
          {
               id: "type",
               label: "ประเภท",
               minWidth: 50,
               align: "center",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "รหัส",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "รายการ",
               minWidth: 100,
               align: "center",
          },
          //   {
          //        id: "ICD10",
          //        label: "รหัสวินิจฉัยโรค (ICD 10)",
          //        minWidth: 150,
          //        align: "center",
          //   },
          //   {
          //        id: "DIAG",
          //        label: "รหัสวินิจฉัยโรค (ICD 9 CM)",
          //        minWidth: 150,
          //        align: "center",
          //   },
          //   {
          //        id: "odx_name",
          //        label: "ชนิดของโรค",
          //        minWidth: 200,
          //        align: "left",
          //   },
          //   {
          //        id: "LABTEST",
          //        label: "ชื่อแลป",
          //        minWidth: 100,
          //        align: "right",
          //   },
          //   {
          //        id: "LABRESULT",
          //        label: "ผลแลป",
          //        minWidth: 100,
          //        align: "right",
          //   },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          //   {
          //        id: "rights",
          //        label: "การใช้สิทธิ",
          //        minWidth: 150,
          //        align: "left",
          //   },
          // {
          //      id: "preaudit",
          //      label: "Pre Audit",
          //      minWidth: 150,
          //      align: "left",
          // },
          // {
          //      id: "status_claim",
          //      label: "สถานะ",
          //      minWidth: 150,
          //      align: "center",
          // },
     ],
     headerOdx: [
          {
               id: "date",
               label: "วันที่",
               minWidth: 50,
               align: "center",
          },
          {
               id: "diag",
               label: "DIAG",
               minWidth: 50,
               align: "center",
          },
          {
               id: "dxtype",
               label: "DX TYPE",
               minWidth: 20,
               align: "center",
          },
          {
               id: "drdx",
               label: "DRDX",
               minWidth: 100,
               align: "center",
          },
     ],
     claim_detail_vaccine: [
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "CODE",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "ประเภทการเคลม",
               minWidth: 100,
               align: "center",
          },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "status_claim",
               label: "สถานะ",
               minWidth: 150,
               align: "center",
          },
     ],
     claim_detail_history: [
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "CODE",
               label: "CODE",
               minWidth: 100,
               align: "center",
          },
          {
               id: "adp_type_name",
               label: "ประเภทการเคลม",
               minWidth: 100,
               align: "center",
          },
          {
               id: "QTY",
               label: "จำนวน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "cost",
               label: "ค่าบริการ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "preaudit",
               label: "Pre Audit",
               minWidth: 150,
               align: "left",
          },
          {
               id: "status_claim",
               label: "สถานะ",
               minWidth: 150,
               align: "center",
          },
     ],
     news_header: [
          {
               id: "news_list",
               label: "รายการข่าวสาร",
               minWidth: 200,
               align: "center",
          },
          {
               id: "news_date",
               label: "วันที่",
               minWidth: 100,
               align: "center",
          },
          {
               id: "img",
               label: "รูปภาพ",
               minWidth: 100,
               align: "center",
          },
     ],
     statement_header: [
          {
               id: "date",
               label: "วันที่โอน",
               minWidth: 100,
               align: "center",
          },
          {
               id: "filename",
               label: "รายการ",
               minWidth: 100,
               align: "left",
          },
          {
               id: "sub",
               label: "กองทุน",
               minWidth: 100,
               align: "left",
          },
          {
               id: "amount",
               label: "จำนวนเงิน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "delay",
               label: "รอโอนเงิน",
               minWidth: 100,
               align: "right",
          },
          {
               id: "bookbank",
               label: "เงินโอนเข้าบัญชี",
               minWidth: 100,
               align: "right",
          },
     ],
     statement_detail_header: [
          {
               id: "HN",
               label: "HN",
               minWidth: 100,
               align: "left",
          },
          {
               id: "namepat",
               label: "ชื่อ-สกุล",
               minWidth: 100,
               align: "left",
          },
          {
               id: "date",
               label: "วันที่",
               minWidth: 100,
               align: "left",
          },
          {
               id: "detail",
               label: "อาการวินิจฉัย",
               minWidth: 150,
               align: "left",
          },
          {
               id: "medicine",
               label: "รหัสยา (ราคา)",
               minWidth: 100,
               align: "left",
          },
          {
               id: "request",
               label: "ขอเบิก",
               minWidth: 100,
               align: "right",
          },
          {
               id: "reponse",
               label: "ยอดชดเชยทั้งสิ้น",
               minWidth: 100,
               align: "right",
          },
     ],
     herb_tbl: [
          {
               id: "code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "left",
          },
          {
               id: "edit",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     pregnant_tbl: [
          {
               id: "code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "left",
          },
          {
               id: "edit",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     contraceptive_pill_tbl: [
          {
               id: "code",
               label: "รหัสเวชภัณฑ์",
               minWidth: 100,
               align: "center",
          },
          {
               id: "name",
               label: "ชื่อเวชภัณฑ์",
               minWidth: 150,
               align: "left",
          },
          {
               id: "edit",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     manage_claim: [
          {
               id: "type_claim",
               label: "ประเภทการเคลม",
               minWidth: 100,
               align: "left",
          },
          {
               id: "status",
               label: "สถานะ",
               minWidth: 100,
               align: "center",
          },
          {
               id: "no",
               label: "ลำดับ",
               minWidth: 100,
               align: "right",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 100,
               align: "center",
          },
          {
               id: "action",
               label: "การดำเนินการ",
               minWidth: 100,
               align: "center",
          },
     ],
     manage_url: [
          {
               id: "name",
               label: "ชื่อหน้า",
               minWidth: 100,
               align: "left",
          },
          {
               id: "url",
               label: "url",
               minWidth: 100,
               align: "left",
          },
          {
               id: "type",
               label: "ประเภทของหน้า",
               minWidth: 100,
               align: "center",
          },
          {
               id: "edit",
               label: "แก้ไข",
               minWidth: 150,
               align: "center",
          },
     ]
};

export const textFiled = {
     register: [
          {
               name: "name",
               label: "ชื่อ :",
               placeholder: "ชื่อ",
               type: "text",
          },
          {
               name: "lastname",
               label: "นามสกุล :",
               placeholder: "นามสกุล",
               type: "text",
          },
          {
               name: "list_lacation",
               label: "โรงพยาบาลส่งเสริมสุขภาพตำบล :",
               type: "select",
               data_select: "lacation",
          },
          {
               name: "id_card",
               label: "เลขบัตรประจำตัวประชาชน :",
               placeholder: "x xxxx xxxxx xx x",
               type: "text",
          },
          {
               name: "role_id",
               label: "บทบาท :",
               type: "select",
               // data_select: "list_lacation",
          },
          {
               name: "hospital_id",
               label: "โรงพยาบาลศูนย์ :",
               type: "select",
               data_select: "hospital",
          },
          {
               name: "phone",
               label: "หมายเลขโทรศัพท์ :",
               placeholder: "หมายเลขโทรศัพท์",
               type: "text",
          },
          {
               name: "email",
               label: "E-mail :",
               placeholder: "example@email.com",
               type: "text",
          },
          {
               name: "birth_date",
               label: "วัน/เดือน/ปี เกิด :",
               // placeholder: "",
               type: "date",
          },
          {
               name: "password",
               label: "รหัสผ่าน :",
               placeholder: "รหัสผ่าน",
               type: "text",
          },
          {
               name: "confirm_password",
               label: "ยืนยันรหัสผ่าน :",
               placeholder: "ยืนยันรหัสผ่าน",
               type: "text",
          },
     ],
     pages_check: [
          {
               name: "stock",
               label: "เวชภัณฑ์คงคลัง",
          },
          {
               name: "transaction",
               label: "เบิกเวชภัณฑ์",
          },
          {
               name: "drug",
               label: "รายการเวชภัณฑ์",
          },
          {
               name: "hospital",
               label: "โรงพยาบาลศูนย์",
          },
          {
               name: "location",
               label: "โรงพยาบาลส่วนตำบล",
          },
          {
               name: "้history",
               label: "ประวัติการเบิก - จ่าย เวชภัณฑ์",
          },
          {
               name: "user",
               label: "จัดการผู้ใช้งาน",
          },
          {
               name: "vender",
               label: "รายการบริษัทยา",
          },
          {
               name: "device",
               label: "อุปกรณ์",
          },
     ],
     drug: [
          {
               name: "list_type",
               label: "ประเภทเวชภัณฑ์ :",
               type: "select",
               data_select: "list_type",
          },
          {
               name: "drug_code",
               label: "หมายเลขเวชภัณฑ์ :",
               placeholder: "หมายเลขเวชภัณฑ์",
               type: "text",
          },
          {
               name: "drug_name",
               label: "ชื่อเวชภัณฑ์ (EN) :",
               placeholder: "ชื่อภาษาอังกฤษ",
               type: "text",
          },
          {
               name: "drug_name_thai",
               label: "ชื่อเวชภัณฑ์ (TH) :",
               placeholder: "ชื่อภาษาไทย",
               type: "text",
          },
          {
               name: "cost",
               label: "ราคาซื้อ :",
               placeholder: "ราคาซื้อ (บาท)",
               type: "text",
          },
          {
               name: "sell",
               label: "ราคาจำหน่าย :",
               placeholder: "ราคาจำหน่าย (บาท)",
               type: "text",
          },
          {
               name: "unit",
               label: "หน่วยนับ :",
               placeholder: "หน่วยนับ",
               type: "text",
          },
          {
               name: "list_vendor",
               label: "เลือกบริษัทยา",
               type: "select",
               data_select: "list_vendor",
          },
     ],
     banner_history: [
          {
               label: "วันที่ทำรายการ",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
          {
               label: "วันที่รับรายการ",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
          {
               label: "วันที่รับเวชภัณฑ์",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
     ],
     banner_order: [
          {
               label: "วันที่ทำรายการ",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
          {
               label: "วันที่รับเวชภัณฑ์",
               detail: "วว/ดด/ปปปป 00:00:00",
          },
     ],
     location: [
          {
               name: "list_location",
               label: "",
               type: "select",
               data_select: "list_lacation",
          },
     ],
};

export const menuSideBar = {
     menu_all: [
          {
               th: "เวชภัณฑ์คงคลัง",
               link: "/Stock",
               icon: stock,
          },
          {
               th: "รายการเวชภัณฑ์",
               link: "/Drug",
               icon: drug,
          },
          {
               th: "เบิกเวชภัณฑ์โรงพยาบาลส่วนตำบล",
               link: "/RequisitionLocation",
               icon: transaction,
          },
          // {
          //     th: "เบิกเวชภัณฑ์โรงพยาบาลศูนย์",
          //     link: "/RequisitionHospital",
          //     icon: transaction,
          // },
          // {
          //     th: "อนุมัติการเบิกเวชภัณฑ์",
          //     link: "/ApproveOrder",
          //     icon: approve,
          // },
          // {
          //     th: "ประวัติการเบิก-จ่ายเวชภัณฑ์",
          //     link: "/History",
          //     icon: history,
          // },
          {
               th: "โรงพยาบาลศูนย์",
               link: "/Hospital",
               icon: hospital,
          },
          {
               th: "โรงพยาบาลส่วนตำบล",
               link: "/Location",
               icon: location,
          },
          {
               th: "อุปกรณ์",
               link: "/Device",
               icon: device,
          },
          {
               th: "บริษัทยา",
               link: "/Vendor",
               icon: vendor,
          },
          {
               th: "จัดการผู้ใช้งาน",
               link: "/User",
               icon: user,
          },
          {
               th: "จัดการสิทธิ์สำหรับผู้ใช้งาน",
               link: "/Role",
               icon: role,
          },
          {
               th: "สรุปเวชภัณฑ์ (แยกตามเวชภัณฑ์)",
               link: "/ReportDrug",
               icon: report,
          },
          {
               th: "สรุปรายการขอเบิกจ่ายเวชภัณฑ์",
               link: "/ReportApprove",
               icon: report,
          },
          {
               th: "การส่งเคลม 16 แฟ้ม",
               link: "/Claim16",
               icon: verified,
          },
          {
               th: "การส่งเคลม 43 แฟ้ม",
               link: "/Claim43",
               icon: verified,
          },
          {
               th: "Dashboard รพ. สต.",
               link: "/DashboardLocation",
               icon: dashboard,
          },
          {
               th: "Dashboard อบจ.",
               link: "/DashboardPAO",
               icon: dashboard,
          },
          {
               th: "Dashboard รวม",
               link: "/Dashboard",
               icon: dashboard,
          },
          {
               th: "Dashboard OPD",
               link: "/DashboardOPD",
               icon: dashboard,
          },
          {
               th: "Dashboard การเงิน",
               link: "/DashboardStatement",
               icon: dashboard,
          },
          {
               th: "รายงานการเงิน",
               link: "/Statement",
               icon: statement,
          },
          {
               th: "กราฟการเงิน",
               link: "/ChartStatement",
               icon: dashboard,
          },
          {
               th: "สถานะการดึงข้อมูล",
               link: "/HospitalStatus",
               icon: hospital,
          },
          {
               th: "ข่าวสาร",
               link: "/News",
               icon: news,
          },
          {
               th: "จัดการประเภทเคลม",
               link: "/ManageClaim",
               icon: setting,
          },
          {
               th: "จัดการ url",
               link: "/ManageUrl",
               icon: setting,
          },
          {
               th: "ตั้งค่า OTP",
               link: "/SettingsOtp",
               icon: setting,
          },
          {
               th: "รายงานผู้เสียชีวิต",
               link: "/ReportDeath",
               icon: report_dashboard,
          },
          {
               th: "รายงานการวินิจฉัยโรค",
               link: "/ReportDiagnose",
               icon: report_dashboard,
          },
     ],
     menu_home: [
          {
               th: "จัดการผู้ใช้งาน",
               link: "/User",
               icon: user,
          },
          {
               th: "จัดการสิทธิ์สำหรับผู้ใช้งาน",
               link: "/Role",
               icon: role,
          },

          {
               th: "อุปกรณ์",
               link: "/Device",
               icon: device,
          },
          {
               th: "โรงพยาบาลศูนย์",
               link: "/Hospital",
               icon: hospital,
          },
          {
               th: "โรงพยาบาลส่วนตำบล",
               link: "/Location",
               icon: location,
          },
          {
               th: "สถานะการดึงข้อมูล",
               link: "/HospitalStatus",
               icon: hospital,
          },
          {
               th: "ข่าวสาร",
               link: "/News",
               icon: news,
          },
          {
               th: "จัดการประเภทเคลม",
               link: "/ManageClaim",
               icon: setting,
          },
          {
               th: "จัดการ url",
               link: "/ManageUrl",
               icon: setting,
          },
          {
               th: "ตั้งค่า OTP",
               link: "/SettingsOtp",
               icon: setting,
          },
     ],
     menu_stock: [
          {
               th: "เวชภัณฑ์คงคลัง",
               link: "/Stock",
               icon: stock,
          },
          {
               th: "รายการเวชภัณฑ์",
               link: "/Drug",
               icon: drug,
          },
          {
               th: "เบิกเวชภัณฑ์โรงพยาบาลส่วนตำบล",
               link: "/RequisitionLocation",
               icon: transaction,
          },
          {
               th: "บริษัทยา",
               link: "/Vendor",
               icon: vendor,
          },
          {
               th: "สรุปเวชภัณฑ์ (แยกตามเวชภัณฑ์)",
               link: "/ReportDrug",
               icon: report,
          },
          {
               th: "สรุปรายการขอเบิกจ่ายเวชภัณฑ์",
               link: "/ReportApprove",
               icon: report,
          },
     ],
     menu_dashboard: [
          {
               th: "Dashboard รพ. สต.",
               link: "/DashboardLocation",
               icon: dashboard,
          },
          {
               th: "Dashboard อบจ.",
               link: "/DashboardPAO",
               icon: dashboard,
          },
          {
               th: "Dashboard รวม",
               link: "/Dashboard",
               icon: dashboard,
          },
          {
               th: "Dashboard รวม 43 แฟ้ม",
               link: "/DashboardAll43",
               icon: dashboard,
          },
          {
               th: "รายงานการเงิน",
               link: "/Statement",
               icon: statement,
          },
          {
               th: "กราฟการเงิน",
               link: "/ChartStatement",
               icon: dashboard,
          },
          {
               th: "Dashboard OPD",
               link: "/DashboardOPD",
               icon: dashboard,
          },
          {
               th: "Dashboard การเงิน",
               link: "/DashboardStatement",
               icon: dashboard,
          },
          {
               th: "รายงานผู้เสียชีวิต",
               link: "/ReportDeath",
               icon: report_dashboard,
          },
          {
               th: "รายงานการวินิจฉัยโรค",
               link: "/ReportDiagnose",
               icon: report_dashboard,
          },
     ],
     menu_claim: [
          {
               th: "การส่งเคลม 16 แฟ้ม",
               link: "/Claim16",
               icon: verified,
          },
          {
               th: "การส่งเคลม 43 แฟ้ม",
               link: "/Claim43",
               icon: verified,
          },
          {
               th: "การส่งเคลมวัคซีน",
               link: "/ClaimVaccine",
               icon: verified,
          },
          // {
          //     th: "ประวัติการเคลม",
          //     link: "/HistoryClaim",
          //     icon: history,
          // },
          {
               th: "รหัสเคลม",
               link: "/ClaimCode",
               icon: token,
          },
          {
               th: "ยาสมุนไพร",
               link: "/Herb",
               icon: medication,
          },
          {
               th: "ยาคุมกำเนิด",
               link: "/ContraceptivePill",
               icon: medication,
          },
          {
               th: "ยาสำหรับหญิงตั้งครรภ์",
               link: "/PregnantDrug",
               icon: medication,
          },
     ],
};

export const select_data = {
     list_type: [
          {
               label: "เลือกประเภทเวชภัณฑ์ยา",
               value: "",
               disabled: true,
          },
          {
               label: "เวชภัณฑ์ยา",
               value: "เวชภัณฑ์ยา",
          },
          {
               label: "เวชภัณฑ์ไม่ใช่ยา",
               value: "เวชภัณฑ์ไม่ใช่ยา",
          },
          {
               label: "สมุนไพร",
               value: "สมุนไพร",
          },
          {
               label: "อื่นๆ",
               value: "อื่นๆ",
          },
     ],
     list_vendor: [
          {
               label: "เลือกบริษัทยา",
               value: "",
               disabled: true,
          },
          {
               label: "โรงพยาบาลศูนย์",
               value: "Hospital",
          },
          {
               label: "โรงพยาบาลส่วนตำบล",
               value: "location",
          },
     ],
     list_lacation: [
          {
               label: "เลือกสถานพยาบาล",
               value: "",
               disabled: true,
          },
          {
               label: "Lacation A",
               value: "LacationA",
          },
          {
               label: "Lacation B",
               value: "LacationB",
          },
          {
               label: "Lacation C",
               value: "LacationC",
          },
          {
               label: "Lacation D",
               value: "LacationD",
          },
     ],
     lacation: [
          {
               label: "เลือกสถานพยาบาล",
               value: "",
               disabled: true,
          },
          {
               label: "โรงพยาบาล",
               value: "1",
          },
     ],
     hospital: [
          {
               label: "เลือกสถานพยาบาล",
               value: "",
               disabled: true,
          },
          {
               label: "โรงพยาบาล",
               value: "1",
          },
     ],
};

export const transactionStatus = {
     1: { label: "รอดำเนินการ", color: "var(--yellow)" },
     2: { label: "ยอมรับ", color: "var(--dark-teal)" },
     3: { label: "ยกเลิก", color: "var(--red)" },
};

export const roleStatus = {
     0: { label: "ไม่ใช้งาน", color: "var(--red)" },
     1: { label: "ใช้งาน", color: "var(--dark-teal)" },
     // 3: { label: 'ยังไม่ระบุ', color: "var(--yellow)" }
};

export const deviceStatus = {
     0: { label: "รออนุมัติ", color: "var(--yellow)" },
     1: { label: "อนุมัติ", color: "var(--dark-teal)" },
     2: { label: "ปฏิเสธ", color: "var(--red)" },
};

export const hospitalStatus = {
     0: { label: "ดึงข้อมูลไม่สำเร็จ", color: "var(--red)" },
     1: { label: "ดึงข้อมูลสำเร็จ", color: "var(--dark-teal)" },
     // 2: { label: 'ปฏิเสธ', color: "var(--red)" }
};

export const DXTYPE_TYPE = {
     1: { label: "Primary Diagnosis" },
     2: { label: "โรคร่วม (Co morbidity)" },
     3: { label: "โรคแทรกซ้อน (Complication)" },
     4: { label: "อื่นๆ (Others)" },
     5: { label: "สาเหตุภายนอก (External Cause)" },
};

// export const level_chart = {
//      1: { label: "5000000", color: "#1a237e" },
//      2: { label: "999999", color: "#0d47a1" },
//      3: { label: "99999", color: "#8bc34a" },
//      4: { label: "9999", color: "#b71c1c" },
//      5: { label: "1", color: "#f44336" },
//      6: { label: "0", color: "#000" },
// };

export const MANAGEURL_TYPE = {
     0: { label: "หน้าหลัก", color: "var(--black)" },
     1: { label: "ระบบคลังยา", color: "var(--black)" },
     2: { label: "ระบบสนับสนุนการเคลม", color: "var(--black)" },
     3: { label: "ระบบ Dashboard", color: "var(--black)" },
};
