import React, { useEffect, useState } from 'react';

import {
    Container,
} from "@mui/material";
import BackdropLoad from '../../components/Backdrop/BackdropLoad';
import { GET, GETTRANSACTIONALL } from '../../service';
import Swal from "sweetalert2";
import { headerTable } from '../../untils/static';
import ListRequisitionLocation from './components/ListRequisitionLocation';


function RequisitionLocation() {
    const [loading, setLoading] = useState(false)
    // const [state, setState] = useState({})
    const [data, setData] = useState([])

    const getAllTransaction = async () => {
        setLoading(true)
        try {
            let res = await GET(GETTRANSACTIONALL);
            if (res.success) {
                setData(res.result)
                setLoading(false)
            } else {
                setLoading(false)
            }

        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                text: `${error}`,
                confirmButtonText: 'ตกลง',
            })
        }
    }
    useEffect(() => {
        getAllTransaction();
    }, []);


    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListRequisitionLocation
                header={headerTable.requisition_header}
                data={data}
            // state={state}
            />
        </Container>
    )

}

export default RequisitionLocation;
