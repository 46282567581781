
import React from 'react'

import {
    Container,
} from "@mui/material";

import DetailLocation from './components/DetailLocation';

function ReportDetailLocation() {

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <DetailLocation />
        </Container>
    )
}

export default ReportDetailLocation;