import React, { useState } from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Grid,
    Chip,
} from "@mui/material";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../Theme"
import {
    MoreIcon,
} from '../../../untils/icons'

import {
    fixAmount,
} from '../../../untils/shortcut'

import { transactionStatus } from '../../../untils/static';

export default function GridTableList({ header, data }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRoute = (path, detail) => {
        return () => {
            navigate(path, { state: { detail: detail } });
        };
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="center">
                                            {moment(row.date_created).format("DD/MM/YYYY HH:mm:ss")}                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.order_code}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {fixAmount(row.amount ? row.amount : 0)}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Chip label={transactionStatus[row.status].label} sx={{ background: `${transactionStatus[row.status].color}`, color: "var(--white)" }} />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <MoreIcon
                                                sx={{ cursor: "pointer" }}
                                                onClick={handleRoute(`Order`, row)}
                                            />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length === 0 ?
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap >
                        * ไม่มีข้อมูลในตาราง *
                    </Typography>
                </Grid>
                :
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            }
        </Paper>
    );
}

