import React, { useState } from "react";
import {
     Typography,
     Paper,
     Table,
     TableBody,
     TableContainer,
     TableHead,
     TablePagination,
     TableRow,
     Grid,
     Checkbox,
     FormControlLabel,
     FormControl,
     FormGroup,
     Chip,
} from "@mui/material";
import dayjs from "dayjs";
import { StyledTableCell } from "../../../../Theme";
export default function GridTable({ header, data, handleChangeCheckbox, checkAll, status }) {
     const [page, setPage] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10);

     const handleChangePage = (event, newPage) => {
          setPage(newPage);
     };

     const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
     };

     // const handleRoute = (path, detail) => {
     //      // console.log('dateStart', dateStart,dateEnd)
     //      return () => {
     //           navigate(path, { state: { ...detail } });
     //      };
     // };

     return (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 800 }}>
                    <Table stickyHeader aria-label="sticky table">
                         <TableHead>
                              <TableRow>
                                   <StyledTableCell align="left">
                                        {status <= 2 &&
                                             (data.length > 0 ? (
                                                  <FormControlLabel
                                                       label="ทั้งหมด"
                                                       control={
                                                            <Checkbox
                                                                 checked={checkAll === -1 ? true : false}
                                                                 name="ทั้งหมด"
                                                                 onChange={(e) => handleChangeCheckbox(e)}
                                                            />
                                                       }
                                                  />
                                             ) : (
                                                  <FormControlLabel
                                                       label="ทั้งหมด"
                                                       control={<Checkbox checked={false} name="ทั้งหมด" onChange={(e) => handleChangeCheckbox(e)} />}
                                                  />
                                             ))}
                                   </StyledTableCell>
                                   {header.map((column, i) => (
                                        <StyledTableCell key={i + 1} align={column.align} style={{ minWidth: column.minWidth }}>
                                             <Typography fontWeight="bold" fontSize="14px">
                                                  {column.label}
                                             </Typography>
                                        </StyledTableCell>
                                   ))}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                   let age_year = dayjs(new Date()).diff(dayjs(row.birth), "year");
                                   let age_month = dayjs(new Date()).diff(dayjs(row.birth), "month");
                                   return (
                                        <TableRow key={i + 1}>
                                             <StyledTableCell align="left">
                                                  {status <= 2 && (
                                                       <FormControl component="fieldset" variant="standard">
                                                            <FormGroup>
                                                                 <FormControlLabel
                                                                      control={
                                                                           <Checkbox
                                                                                onChange={(e) => handleChangeCheckbox(e)}
                                                                                checked={row.check}
                                                                                value={row.index}
                                                                                name={row.idcard}
                                                                           />
                                                                      }
                                                                 />
                                                            </FormGroup>
                                                       </FormControl>
                                                  )}
                                             </StyledTableCell>
                                             <StyledTableCell align="left">
                                                  {row.titlename} {row.fname} {row.lname}
                                             </StyledTableCell>
                                             <StyledTableCell align="right">
                                                  {/* {Number(dayjs().year()) - Number(dayjs(row.birth).year())} */}
                                                  {age_year > 0 ? `${age_year} ปี` : `${age_month} เดือน`}
                                             </StyledTableCell>
                                             <StyledTableCell align="left">
                                                  {row.message && (
                                                       <Chip
                                                            label={row.message}
                                                            color={row.status_c >= 3 && row.status_c <= 4 ? "success" : "error"}
                                                       />
                                                  )}
                                             </StyledTableCell>
                                             <StyledTableCell align="center">{row.vaccinecode ? row.vaccinecode : "N/A"}</StyledTableCell>
                                             <StyledTableCell align="center">{row.code_claim ? row.code_claim : "N/A"}</StyledTableCell>
                                             <StyledTableCell align="center">
                                                  {row.datetime ? dayjs(row.dateepi).format("DD/MM/YYYY") : "N/A"}
                                             </StyledTableCell>
                                             <StyledTableCell align="center">
                                                  {row.datevacineexpire ? dayjs(row.datevacineexpire).format("DD/MM/YYYY") : "N/A"}
                                             </StyledTableCell>
                                             {/* <StyledTableCell align="center">
                                                  {row.datetime ? dayjs(row.datetime).format("DD/MM/YYYY HH:MM") : "N/A"}
                                             </StyledTableCell> */}
                                             <StyledTableCell align="center">{row.visitno}</StyledTableCell>
                                             <StyledTableCell align="left">{row.lotno ? row.lotno : "N/A"}</StyledTableCell>
                                             {/* <StyledTableCell align="left">
                                                  {row.PERMITNO ? (
                                                       row.pre_audit && row.pre_audit.length === 0 ? (
                                                            <Chip label="Pass" color="success" />
                                                       ) : (
                                                            row.pre_audit.map((el, index) => <Chip key={index + 1} label={el.name} color="error" />)
                                                       )
                                                  ) : row.pre_audit && row.pre_audit.length === 0 ? null : (
                                                       row.pre_audit.map((el, index) => <Chip key={index + 1} label={el.name} color="error" />)
                                                  )}
                                             </StyledTableCell>
                                             <StyledTableCell align="center">
                                                  {row.PERMITNO ? <MoreIcon sx={{ cursor: "pointer" }} onClick={handleRoute(`Detail`, row)} /> : null}
                                             </StyledTableCell> */}
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               {data.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                         <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
               ) : (
                    <TablePagination
                         rowsPerPageOptions={[10, 25, 100]}
                         component="div"
                         count={data.length}
                         rowsPerPage={rowsPerPage}
                         page={page}
                         onPageChange={handleChangePage}
                         onRowsPerPageChange={handleChangeRowsPerPage}
                         labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
               )}
          </Paper>
     );
}
