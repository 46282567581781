import React from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Stack,
    Button,
    CardMedia,
} from "@mui/material";
import choose_img from "../../../assets/images/img_show.png";
import { Editor } from "react-draft-wysiwyg";
import {
    NewsIcn,
} from '../../../untils/icons';
import { ButtonTeal } from '../../../Theme';

function GridAdd({
    data,
    editorState,
    selectedImage,
    uploadImage,
    handleChangeEditor,
}) {

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <NewsIcn fontSize='medium' />
                            <Typography className="main-header">
                                เพิ่มรายการข่าวสาร
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="flex-center">
                        <Stack spacing={2} mt={2} className="flex-center">
                            <CardMedia
                                sx={{ height: 300, width: "auto", objectFit: "contain" }}
                                component="img"
                                alt="News"
                                src={selectedImage ? selectedImage : choose_img}
                            />
                            <label htmlFor="contained-button-file" style={{ width: 200 }}>
                                <input hidden accept="image/*" id="contained-button-file" type="file" onChange={uploadImage} />
                                <Button variant="outlined" component="span" className="btn-style" sx={{ mt: 2, maxWidth: 200 }}>
                                    เลือกไฟล์
                                </Button>
                            </label>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography my={2} fontSize="18px" className="text-subtitle">รายละเอียด</Typography>
                        <Box className="box-editor">
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={handleChangeEditor}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="flex-end" >
                        <ButtonTeal sx={{ padding: 1.5 }}>
                            <Typography sx={{ cursor: "pointer" }} >
                                เพิ่มรายการ
                            </Typography>
                        </ButtonTeal>
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    )
}

export default GridAdd;
